import {sendPostToApi} from "../../../../services/api";
import {Identifier} from "react-admin";

export const completePayment = async (checkoutId?: Identifier, checkoutDetailIds?: number[]): Promise<boolean> => {

    if (!checkoutId) return false;

    try {
        const response = await sendPostToApi('marketplace/checkouts/complete-payment', {
            checkout_id: checkoutId,
        });
        const isSuccessResponse = response.data['success'];

        if (isSuccessResponse) {
            localStorage.setItem('RaStore.v1_CheckoutDetail.datagrid.expanded', JSON.stringify(checkoutDetailIds));
            localStorage.setItem('adzz-show-after-payment-window', JSON.stringify(true));
        }
        return isSuccessResponse;
    } catch (error: unknown) {
        console.error(error);
        return false;
    }
}