import {SaveButton, SimpleForm, useRecordContext} from "react-admin";
import OfferFormFields from "./offerFormFields";
import {Stack} from "@mui/material";
import {FormAction} from "../../../type/formActions";
import type {v1_Offer} from "../../../../generated/types";
import {OFFER_STATUS} from "../../../config/statuses";

type OfferFormProps = {
    defaultValues?: any;
    action: FormAction;
};

const OfferForm = ({defaultValues, action}: OfferFormProps) => {
    const record = useRecordContext<v1_Offer>();
    const isFormDisabled = record?.Status === OFFER_STATUS.UNPUBLISHED;

    return (
        <SimpleForm defaultValues={defaultValues} toolbar={false}>
            <OfferFormFields action={action}/>
            <Stack
                mt={3}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                width="100%"
            >
                <SaveButton
                    label="SUBMIT" icon={<></>} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 18px",
                    gap: "10px",
                    width: "252px",
                    height: "40px",
                    background: "#9E9E9E",
                    flex: "none",
                    order: "0",
                    flexGrow: "0",
                }}
                    disabled={isFormDisabled}
                />
            </Stack>
        </SimpleForm>
    );
};

export default OfferForm;
