import {CartContext, CartItem} from "../../../../context/CartContext";
import React, {useContext, useState} from "react";
import {useGetList} from "react-admin";
import {CircularProgress, MenuItem, Select} from "@mui/material";

export const WordCountInput = ({languageId, record}: { languageId: number | undefined, record: CartItem }) => {

    const {setWordCount} = useContext(CartContext);
    const [currentStep, setCurrentStep] = useState(record.stepId
        ? record.stepId
        : -1)

    const {data: steps, isLoading: isStepsLoading} = useGetList(
        'v1_LanguagePriceStep',
        {
            filter: {language_id: languageId},
            sort: {field: 'step', order: 'ASC'},
        },
        {enabled: !!languageId}
    );

    if (isStepsLoading) return <CircularProgress/>

    const defaultStep = steps?.find((step) => step.price === 0)?.id || -1
    if (defaultStep !== currentStep && currentStep === -1) {
        setCurrentStep(defaultStep);
        const selectedPrice = steps?.find((step) => step.id === defaultStep).price || 0;
        setWordCount(record, defaultStep, selectedPrice);
    }

    const handleStepChange = (event: any) => {
        const selectedStep = event.target.value;
        setCurrentStep(selectedStep);
        if (selectedStep === -1) {
            setWordCount(record, null, 0);
        } else {
            const selectedPrice = steps?.find((step) => step.id === selectedStep).price || 0;
            setWordCount(record, event.target.value, selectedPrice);
        }
    };

    return (
        <Select
            value={currentStep}
            fullWidth
            size="small"
            sx={{marginTop: 2}}
            onChange={handleStepChange}
            placeholder="Select One"
        >
            {steps?.map((item: any) => (
                <MenuItem value={item.id}>{item.step} (+ ${item.price} )</MenuItem>
            ))}
        </Select>
    );
}