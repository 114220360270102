import {Box, Button, DialogActions, DialogContent, DialogTitle, Link, Typography,} from '@mui/material';
import {CsvOfferDuplicate, CsvOfferInvalid, ImportCsvSummaryProps} from './types';
import React from 'react';
import jsonExport from 'jsonexport/dist';
import {downloadCSV} from 'react-admin';
import pick from 'lodash/pick';

export function ImportCsvSummary({
                                     result,
                                     setResult,
                                     toggleDialog,
                                 }: ImportCsvSummaryProps) {
    const {createdOffers, offersDuplicates, invalidOffers, totalRows} = result;
    const closeModal = () => {
        toggleDialog();
        setResult(null);
    };

    const downloadFile = () => {
        const options = {
            headers: [
                'Media',
                'Domain',
                'Do Follow',
                'Number of Links',
                'Language',
                'Price',
                'Post Duration',
                'Restricted Niche Price (%)',
                'Publication Sample',
                'Offer Details',
                'Crypto',
                'CBD',
                'Adult',
                'Finance',
                'Dating',
                'Gambling',
                'Category',
                'Errors'],
        };

        const duplicatedCsvData = offersDuplicates.map((item: CsvOfferDuplicate) => ({
            ...pick(item.lineData, options.headers),
            Errors: 'Duplicated offer'
        }));
        const invalidCsvData = invalidOffers.map((item: CsvOfferInvalid) => ({
            ...pick(item.lineData, options.headers),
            Errors: item.errors.join('; ')
        }));

        return jsonExport([...duplicatedCsvData, ...invalidCsvData], options, (err: any, csv: string) => downloadCSV(csv, 'Offers import'));
    }

    return (
        <>
            <DialogTitle>Import CSV Summary</DialogTitle>
            <DialogContent>
                <Typography variant="body2" color="textSecondary" sx={{fontSize: 15}}>{totalRows} rows were in CSV</Typography>
                {createdOffers.length > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{fontSize: 15}}>{createdOffers.length} offers successfully
                        created</Typography>)}
                {offersDuplicates.length > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{fontSize: 15}}>{offersDuplicates.length} offers already exist in
                        system</Typography>)}
                {invalidOffers.length > 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{fontSize: 15}}>{invalidOffers.length} rows have validation
                        errors</Typography>)}
                {(offersDuplicates.length || invalidOffers.length) && (
                    <Box mt={2} display="flex" flexDirection="row" gap={2} alignItems="center">
                        <Typography sx={{fontSize: 15}}>To download CSV file with incorrect rows click </Typography>
                        <Link href="#" onClick={downloadFile}>here</Link>
                    </Box>

                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeModal}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
