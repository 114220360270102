import { Typography } from '@mui/material';
import { useGetOne, useRecordContext } from 'react-admin';
import get from 'lodash/get';
import {
    ACTIVITY_PAYOUT,
    ACTIVITY_TOP_UP,
    ActivityType,
} from './balance/types';

const getColor = (activity: ActivityType) => {
    switch (activity) {
        case ACTIVITY_TOP_UP:
            return 'secondary.main';
        case ACTIVITY_PAYOUT:
            return 'primary';
        default:
            return undefined;
    }
};

export const AmountField = (props: AmountFieldProps) => {
    const {
        source = 'amount',
        applyColor = true,
        strong = true,
        displaySign = true,
        maximumFractionDigits = 0,
        minimumFractionDigits = 0,
        activitySource = 'Activity',
    } = props;

    const record = useRecordContext();

    const activityId = get(record, activitySource);
    const { data: activity } = useGetOne('v1_BalanceActivityType', {
        id: activityId,
    });

    const amount = get(record, source);
    if (amount === null || amount === undefined) {
        return <Typography>--</Typography>;
    }

    const color = applyColor ? getColor(activity?.Name) : undefined;
    const style = strong ? { fontWeight: 600 } : undefined;

    const amountFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        signDisplay: displaySign ? 'always' : undefined,
        maximumFractionDigits,
        minimumFractionDigits,
    });

    return (
        <Typography color={color} sx={style}>
            {amountFormatter.format(
                activity?.Name === ACTIVITY_PAYOUT ? -amount : amount,
            )}
        </Typography>
    );
};

export interface AmountFieldProps {
    source?: string;
    applyColor?: boolean;
    strong?: boolean;
    displaySign?: boolean;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    label?: string;
    sortBy?: string;
    activitySource?: string;
}
