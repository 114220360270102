import {CircularProgress} from "@mui/material";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from "../../../models/role-model";
import {OfferList} from "../offer/offerList";
import {OfferList as PublisherOfferList} from "../../publisher/offer/offerList";

export const OfferListRouter = () => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const role = identity?.role;

    if (isIdentityLoading) return <CircularProgress/>;

    function offerList() {
        switch (role) {
            case ROLE_WRITER:
                return <OfferList/>;
            case ROLE_SUPPLIER:
                return <PublisherOfferList/>;
            case ROLE_ADVERTISER:
                return <OfferList/>;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        offerList()
    );
};
