import {Button, CreateButton, TopToolbar, useCreatePath} from "react-admin";
import {Box} from "@mui/material";
import * as React from "react";
import {Link} from "react-router-dom";

export const ListActions = (props: any) => {
    const createPath = useCreatePath();

    return (
        <TopToolbar sx={{display: 'flex', justifyContent: 'space-between', width: 'inherit', alignItems: 'center'}}>
            <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                <CreateButton label="Create New Offer" sx={{fontSize: '20px'}} icon={<></>}/>
                {/*<Typography sx={{fontSize: '20px'}}>or</Typography>*/}
                {/*<Button label="Add in Bulk" sx={{fontSize: '20px'}}/>*/}
            </Box>

            <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                <Button
                    component={Link}
                    to={createPath({resource: 'v1_Offer', type: 'list'})}
                    variant="contained"
                    label='See All Offers'
                />
            </Box>
        </TopToolbar>
    );
};
