import { Box, Divider, List, Stack, Typography } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useTimelineGroup } from '@react-admin/ra-audit-log';
import { ReactElement, ReactNode } from 'react';
import { RecordContextProvider, useTranslate } from 'react-admin';

export const EventTimelineGroup = ({
  children,
}: {
  children?: ReactNode;
}): ReactElement => {
  const t = useTranslate();
  const { label, records } = useTimelineGroup();

  const translatedLabel = t(label, { _: label });

  return (
    <Box>
      <Divider />
      <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
        <CalendarTodayIcon fontSize="small" />
        <Typography variant="subtitle2" role="presentation">
          <strong>{translatedLabel}</strong>
        </Typography>
      </Stack>
      <List
        component="div"
        role="list"
        aria-label={translatedLabel}
        dense
        disablePadding
      >
        {records.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            {children}
          </RecordContextProvider>
        ))}
      </List>
    </Box>
  );
};
