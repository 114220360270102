import io, {Socket} from 'socket.io-client';
import {nhost} from "../lib/nhost";

const SOCKET_URL = process.env.REACT_APP_NOTIFICATIONS_SERVICE || '';

export default class SocketController {
    private socket: Socket | null;
    private listeners: Array<any>;
    private path: string;

    constructor(path: string) {
        this.socket = null;
        this.listeners = [];
        this.path = path;
    }

    connect({url = SOCKET_URL, query = {}, path = this.path}) {
        const token = nhost.auth.getAccessToken() as string;
        this.socket = io(url, {
            transports: ['websocket'],
            query: {
                ...query,
                token,
            },
            forceNew: true,
            path,
        });
        this.listeners.forEach(({listener, method}) => this.socket?.on(method, listener));
    }

    async emit(method: string, data: Record<string, any>) {
        return new Promise((resolve) => {
            if (this?.socket) {
                this.socket?.emit(method, data, (error: any, response: any) =>
                    resolve({
                        error,
                        response,
                    })
                );
            }
        });
    }

    on(method: string, listener: (data: any) => void) {
        if (!this.socket) {
            this.listeners.push({
                method,
                listener,
            });
            return;
        }
        this.socket.on(method, listener);
    }

    disconnect() {
        if (!this.socket) {
            return;
        }
        console.log('disconnect socket', this.socket.id);
        this.listeners.forEach(({listener, method}) => this.socket?.off(method, listener));
        this.listeners = [];
        this.socket?.removeAllListeners();
        this.socket?.disconnect();
        this.socket = null;
    }

    public isConnected() {
        return !!this.socket && this.socket.connected;
    }
}

export const notificationSocketController = new SocketController('/socket.io');
