import { Chip, ChipProps } from '@mui/material';
import inflection from 'inflection';

export const StatusChip = (props: StatusChipProps) => {
  const { status, label, ...rest } = props;
  if (!status && !label) {
    if (process.env.NODE_ENV === 'development') {
      console.error('StatusChip: either status or label prop is required');
    }
    return null;
  }
  const statusLabel = label ?? inflection.humanize(status!);
  return (
    <Chip
      label={statusLabel}
      sx={{ height: '20px', width: '100%', maxWidth: '140px' }}
      {...rest}
    />
  );
};

export interface StatusChipProps extends Omit<ChipProps, 'label'> {
  /** status label that will be humanized with inflection */
  status?: string;
  /** label that will be displayed as is (not humanized nor translated) */
  label?: string;
}
