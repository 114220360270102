import {useEffect, useState} from 'react';
import {useGetIdentity} from "react-admin";
import {sendPostToApi} from "../services/api";

export const useGetUserBalance = () => {
    const [balance, setBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    useEffect(() => {
        if (identity) {
            const fetchData = async () => {
                const response = await sendPostToApi('marketplace/transactions/get-balance', {
                    user_id: identity?.id,
                });
                setBalance(response.data);
                setIsLoading(false);
            }
            void fetchData();
        }
    }, [identity?.id]);

    if (!identity?.id) return {balance: 0, isLoading: false};

    return {balance, isLoading: isLoading || isIdentityLoading};
};
