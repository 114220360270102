import {Box, Button} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {useNotify, useRecordContext, useRefresh} from 'react-admin';
import {sendPostToApi} from '../../../services/api';
import {apiErrorMessage} from '../../../utils/utils';
import {OFFER_STATUS} from '../../../config/statuses';
import type {v1_Offer} from '../../../../generated/types';

export function OfferEditActions() {
    const record = useRecordContext<v1_Offer>();
    const notify = useNotify();
    const refresh = useRefresh();
    const isUnpublishDisabled = record?.Status === OFFER_STATUS.UNPUBLISHED;
    const unpublishOffer = async () => {
        try {
            await sendPostToApi('crm/offer/unpublish', {offer_id: record.id});
            notify(`Offer ${record.id} was successfully unpublished`, {
                type: 'success',
            });
            refresh();
        } catch (error: unknown) {
            const message = apiErrorMessage(error);
            notify(message, {type: 'error'});
        }
    };

    if (!record) return null;

    return (
        <Box>
            <Button
                onClick={unpublishOffer}
                variant="contained"
                disabled={isUnpublishDisabled}
            >
                Unpublish Offer
            </Button>
            <Button
                startIcon={
                    <DeleteOutlineOutlinedIcon sx={{color: '#9E9E9E'}} />
                }
            ></Button>
        </Box>
    );
}
