import {Box, Button, Card, CardContent, Divider, Typography} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import {useListContext} from 'react-admin';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {cloneDeep, get, unset} from 'lodash';
import {FilterProps, FILTERS} from './filters/filters';
import {MarketPlaceMenuFilters} from './marketPlaceMenuFilters';
import {MarketplaceSavedQueries} from "./marketplaceSavedQueries";
import {useNavigate} from "react-router-dom";
import {stringify} from "query-string";

function getFilteredValues(values: any, filtersList: Array<FilterProps>) {
    // Get only values if find in filtersList and display is true
    const result: any = {};

    for (const key in values) {
        const [filterName] = key.split('@');
        const filter = filtersList.find((f) => f.source === filterName);
        if (filter && filter.display) {
            result[key] = values[key];
        }
    }
    return result;
}

export const MarketPlaceFilters = () => {
    const [filtersList, setFiltersList] = useState<Array<FilterProps>>(FILTERS);
    const {setFilters} = useListContext();
    const form = useForm({shouldUnregister: true});
    const [resetKey, setResetKey] = useState(1);
    const navigate = useNavigate();

    const setNewResetKey = () => {
        setResetKey(Math.floor(Math.random() * 10000) + 1);
    }

    useEffect(() => {
        const subscription = form.watch(async ({name}) => {
            // We must check whether the form is valid as watch will not check that for us.
            // We can't rely on form state as it might not be synchronized yet
            const isFormValid = await form.trigger();
            const currentValues = form.getValues();
            let result = getFilteredValues(currentValues, filtersList);
            // if result is empty, get default values
            if (Object.keys(result).length === 0) {
                result = getFilteredValues(form.getValues(), filtersList);
            }

            if (isFormValid) {
                if (get(currentValues, name!) === '') {
                    const newValues = cloneDeep(result);
                    unset(newValues, name!);
                    setFilters(newValues, []);
                } else {
                    setFilters(result, []);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [filtersList, form, setFilters]);

    useEffect(() => {
        const result = getFilteredValues(form.getValues(), filtersList);
        setFilters(result, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReset = () => {
        navigate({
            search: stringify({
                filter: JSON.stringify({}),
            }),
        });
        setNewResetKey();
        form.reset();
    };

    return (
        <Card sx={{marginBottom: 4}}>
            <CardContent>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={4}
                >
                    <Typography variant="h3">Filters</Typography>
                    <Box display="flex" gap={2}>
                        <MarketplaceSavedQueries setNewResetKey={setNewResetKey}/>
                        <Divider orientation="vertical" flexItem/>
                        <Button
                            onClick={handleReset}
                            startIcon={<CachedIcon/>}
                            sx={{
                                color: 'neutral.main',
                            }}
                        >
                            Reset
                        </Button>
                        <Divider orientation="vertical" flexItem/>
                        <MarketPlaceMenuFilters
                            filters={filtersList}
                            setFilters={setFiltersList}
                        />
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={4}>
                    {resetKey && filtersList
                        .filter((filter) => filter.display)
                        .map((filter) => {
                            const Component = filter.component;
                            return (
                                <Component
                                    key={filter.name + resetKey}
                                    {...filter}
                                />
                            );
                        })}
                </Box>
            </CardContent>
        </Card>
    );
};
