import {Card, CardContent, Stack, Typography,} from '@mui/material';
import {Loading, useGetOne,} from 'react-admin';
import React from 'react';
import {useGetIdentity} from '../../../hooks/useGetIdentity';

const amountFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export const Payouts = () => {
    const {data: identity} = useGetIdentity();
    const supplierId = identity?.id;
    const {data: supplier, isLoading: supplierLoading} = useGetOne(
        'v1_Suppliers',
        {
            id: supplierId,
        },
        {enabled: !!supplierId}
    );
    if (supplierLoading) return <Loading/>;

    return (
        <Card>
            <CardContent>
                <Stack>
                    <Typography variant="h3">Payouts</Typography>
                    <Stack direction="row" spacing={6}>
                        <Stack>
                            <Typography variant="h1" color="secondary.main">
                                {amountFormatter.format(supplier.comp_total_payouts)}
                            </Typography>
                            <Typography color="text.secondary">
                                Total
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant="h1">
                                {amountFormatter.format(supplier.comp_current_balance)}
                            </Typography>
                            <Typography color="text.secondary">
                                Current Balance
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
