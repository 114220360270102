import {Box, Button, Stack, Typography} from "@mui/material";
import {DateField, useRecordContext} from "react-admin";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PageTitle from '../../../layout/PageTitle';

const ProfileEditTitle = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <Box sx={{marginBottom: 7}} display="flex" gap={4}>
            <PageTitle>My profile</PageTitle>

            <Box display="flex" alignItems="center">
                <DateRangeIcon fontSize="small" sx={{marginRight: 1}}/>
                <Typography sx={{marginRight: 1}}>Joined:</Typography>
                <DateField
                    source="CreatedOn"
                    options={{year: "numeric", month: "long", day: "numeric"}}
                    sx={{fontWeight: "500", fontSize: "18px"}}
                    emptyText="No Join Date"
                />
            </Box>
        </Box>
    );
};
export default ProfileEditTitle;
