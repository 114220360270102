import {EditBase, EditView} from "react-admin";
import {Box} from "@mui/material";
import RecordTimeLineWrapper from "../../common/recordTimelineWrapper";
import PostForm from "./postForm";
import PostEditTitle from "./postEditTitle";

export const PostEdit = () => {
    return (
        <EditBase redirect={false}>
            <Box
                display="flex"
                alignItems="self-start"
                sx={{flexDirection: {xs: "column", md: "row"}}}
            >
                <PostEditTitle/>
            </Box>
            <EditView aside={<RecordTimeLineWrapper/>}>
                <PostForm/>
            </EditView>
        </EditBase>
    );
};
