import {Typography, Grid} from '@mui/material';
import {AddProjectButton} from './addProjectButton';
import type {HeaderProps} from './types';

export function Header({total}: HeaderProps) {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignContent="center"
            sx={{mb: '18px'}}
        >
            <Typography variant="h3">
                Projects {!!total && `- ${total}`}
            </Typography>
            {!!total && <AddProjectButton />}
        </Grid>
    );
}
