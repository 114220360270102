import {CreateButton, ExportButton, SelectColumnsButton, TopToolbar} from "react-admin";
import {Box, Typography} from "@mui/material";
import * as React from "react";
import {ImportCsv} from "./importCsv/importCsv";

export const OfferListActions = (props: any) => {
    return (
        <TopToolbar sx={{display: 'flex', justifyContent: 'space-between', width: 'inherit'}}>
            <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                <CreateButton label="Create New Offer" sx={{fontSize: '20px'}} icon={<></>}/>
                <Typography sx={{fontSize: '20px'}}>or</Typography>
                <ImportCsv/>
            </Box>

            <Box display="flex" flexDirection="row" gap={2} alignItems="center">
                <SelectColumnsButton/>
                <ExportButton/>
            </Box>
        </TopToolbar>
    );
};
