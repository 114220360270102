import { Divider } from '@mui/material';

export const MyDivider = () => (
  <Divider
    orientation="vertical"
    variant="middle"
    flexItem
    sx={{
      borderColor: 'primary.contrastText',
      opacity: 0.4,
      '&.MuiDivider-root': {
        my: 2,
      },
    }}
  />
);
