const StatusWaitingApprovalIcon = () => {
    return (
        <svg
        viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.02947 1.99935C2.92526 1.99935 2.03012 2.89449 2.03012 3.99871V15.9948C2.03012 17.099 2.92526 17.9942 4.02947 17.9942H7.59892C8.15102 17.9942 8.59859 18.4418 8.59859 18.9939C8.59859 19.546 8.15102 19.9935 7.59892 19.9935H4.02947C1.82104 19.9935 0.0307617 18.2033 0.0307617 15.9948V3.99871C0.0307617 1.79028 1.82104 0 4.02947 0H12.0269C14.2353 0 16.0256 1.79028 16.0256 3.99871V10.5188C16.0256 11.0709 15.578 11.5184 15.0259 11.5184C14.4738 11.5184 14.0262 11.0709 14.0262 10.5188V3.99871C14.0262 2.89449 13.1311 1.99935 12.0269 1.99935H4.02947Z"
                fill="currentColor"
            />
            <path
                d="M4.02942 14.9953C4.02942 14.4432 4.47699 13.9956 5.02909 13.9956H7.59886C8.15097 13.9956 8.59854 14.4432 8.59854 14.9953C8.59854 15.5474 8.15097 15.995 7.59886 15.995H5.02909C4.47699 15.995 4.02942 15.5474 4.02942 14.9953Z"
                fill="currentColor"
            />
            <path
                d="M4.02942 10.9967C4.02942 10.4446 4.47699 9.99698 5.02909 9.99698H11.0272C11.5793 9.99698 12.0268 10.4446 12.0268 10.9967C12.0268 11.5488 11.5793 11.9963 11.0272 11.9963H5.02909C4.47699 11.9963 4.02942 11.5488 4.02942 10.9967Z"
                fill="currentColor"
            />
            <path
                d="M4.02942 5.99801C4.02942 4.89379 4.92456 3.99865 6.02877 3.99865H10.0275C11.1317 3.99865 12.0268 4.89379 12.0268 5.99801C12.0268 7.10222 11.1317 7.99736 10.0275 7.99736H6.02877C4.92456 7.99736 4.02942 7.10222 4.02942 5.99801Z"
                fill="currentColor"
            />
            <path
                d="M15.8001 13.0078C15.9443 12.9128 16.1129 12.8616 16.2856 12.8604C16.5223 12.8604 16.7493 12.9545 16.9166 13.1218C17.084 13.2892 17.178 13.5162 17.178 13.7529V15.7609C17.178 15.9976 17.084 16.2246 16.9166 16.3919C16.7493 16.5593 16.5223 16.6533 16.2856 16.6533H14.3043C14.0677 16.6533 13.8407 16.5593 13.6733 16.3919C13.5059 16.2246 13.4119 15.9976 13.4119 15.7609C13.4119 15.5242 13.5059 15.2972 13.6733 15.1298C13.8407 14.9625 14.0677 14.8684 14.3043 14.8684H14.5007C14.2634 14.7372 13.9993 14.6618 13.7285 14.6479C13.4576 14.634 13.1872 14.682 12.9377 14.7882C12.6882 14.8945 12.4662 15.0562 12.2886 15.261C12.1109 15.4659 11.9822 15.7085 11.9124 15.9706C11.8425 16.2326 11.8332 16.5071 11.8853 16.7732C11.9374 17.0393 12.0494 17.2901 12.2129 17.5064C12.3764 17.7228 12.587 17.8991 12.8287 18.0219C13.0705 18.1447 13.3371 18.2107 13.6082 18.2151C14.0433 18.212 14.4622 18.0502 14.7863 17.76C14.965 17.6038 15.1984 17.5249 15.4352 17.5408C15.672 17.5567 15.8929 17.666 16.0491 17.8447C16.2053 18.0235 16.2841 18.2569 16.2682 18.4937C16.2523 18.7305 16.143 18.9513 15.9643 19.1076C15.5309 19.4897 15.0113 19.761 14.45 19.8981C13.8886 20.0352 13.3024 20.0339 12.7417 19.8945C12.181 19.7551 11.6625 19.4816 11.2307 19.0976C10.799 18.7136 10.4669 18.2306 10.263 17.6899C10.0591 17.1493 9.98946 16.5672 10.0601 15.9938C10.1308 15.4203 10.3396 14.8725 10.6686 14.3975C10.9976 13.9225 11.437 13.5345 11.949 13.2668C12.4611 12.9991 13.0304 12.8596 13.6082 12.8604C14.267 12.8665 14.9118 13.0516 15.4734 13.3959C15.5425 13.2377 15.656 13.1028 15.8001 13.0078Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default StatusWaitingApprovalIcon;
