import { gql, useMutation } from '@apollo/client';
import BarChartIcon from '@mui/icons-material/BarChart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Chip, Divider, Grid, Typography } from '@mui/material';
import { ClearButtons, FormatButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import {
    AutocompleteInput,
    Button,
    Labeled,
    maxLength,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    SaveButton,
    SaveContextProvider,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useGetIdentity,
    useRecordContext,
    useSaveContext,
} from 'react-admin';
import { CONTENT_STATUS } from '../../../config/statuses';
import { useAddRecordEvent } from '../../../hooks/useAddRecordEvent';
import { ROLE_WRITER } from '../../../models/role-model';
import TinyMce from '../TinyMce';
import UpdateButton from '../updateButton';
import OrderInformation from './OrderInformation';

const SEOTitleMaxlength = 64;
const SEODescriptionMaxlength = 160;
const validateSEOTitle = [maxLength(SEOTitleMaxlength)];
const validateSEODescription = [maxLength(SEODescriptionMaxlength)];

const WRITER_ALLOWED_FIELDS = [
    'new_SeoTitle',
    'new_PostDesciption',
    'new_Title',
    'new_TextHTMLbody',
];
const WRITER_DISABLE_FIELDS_BY_CONTENT_STATUS = [
    CONTENT_STATUS.APPROVED,
    CONTENT_STATUS.WAITING_APPROVAL,
];

enum DisplayMode {
    READ,
    WRITE,
}

interface FormFieldsProps {
    mode: DisplayMode;
    record?: any;
    label?: string;
    validate?: any;
    onChange?: any;
    idx?: number;
}

// TODO: refactor - Type "setWordCount", duplicated in tinymce
interface TextEditorProps {
    mode: DisplayMode;
    setWordCount: Dispatch<SetStateAction<number>>;
    record: any;
    wordCount: number;
}

interface PostEditToolbarProps {
    wordCount: number;
}

const TextFieldStyles = {
    minHeight: '36px',
    padding: '8.5px 14px',
    backgroundColor: '#FFF',
    color: 'text.primary',
    border: 1,
    borderColor: 'border.color',
    borderRadius: 1,
    wordBreak: 'break-word',
};

const OrderForm = () => {
    const post = useRecordContext();
    const { identity } = useGetIdentity();
    const { save: saveFromContext } = useSaveContext();
    const [wordCount, setWordCount] = useState<number>(post.new_wordcount);
    const [seoTitleCounter, setSeoTitleCounter] = useState(
        post?.new_SeoTitle?.length ?? 0,
    );
    const [seoDescriptionCounter, setSeoDescriptionCounter] = useState(
        post?.new_PostDesciption?.length ?? 0,
    );
    const [seoTitleLabel, setSeoTitleLabel] = useState('SEO Title');
    const [seoDescriptionLabel, setSeoDescriptionLabel] =
        useState('SEO Description');

    const userRole = identity?.role;
    const contentStatus = post.new_ContentOrderStatus;

    function countCharacters(e: ChangeEvent<HTMLInputElement>, fn: any) {
        fn(e.currentTarget.value.length);
    }

    function setDisplayMode(fieldName: string): DisplayMode {
        if (userRole !== ROLE_WRITER) {
            return DisplayMode.WRITE;
        }

        if (WRITER_DISABLE_FIELDS_BY_CONTENT_STATUS.includes(contentStatus)) {
            return DisplayMode.READ;
        }

        if (WRITER_ALLOWED_FIELDS.includes(fieldName)) {
            return DisplayMode.WRITE;
        }

        return DisplayMode.READ;
    }

    function save(data: any) {
        const record = { ...data, new_wordcount: wordCount };
        saveFromContext && saveFromContext(record);
    }

    useEffect(() => {
        setSeoTitleLabel(
            seoTitleCounter > 0
                ? `SEO Title (${seoTitleCounter}/${SEOTitleMaxlength})`
                : 'SEO Title',
        );
    }, [seoTitleCounter]);

    useEffect(() => {
        setSeoDescriptionLabel(
            seoDescriptionCounter > 0
                ? `SEO Description (${seoDescriptionCounter}/${SEODescriptionMaxlength})`
                : 'SEO Description',
        );
    }, [seoDescriptionCounter]);

    return (
        <SaveContextProvider value={{ save }}>
            <SimpleForm toolbar={<PostEditToolbar wordCount={wordCount}/>}>
                <OrderInformation/>
                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={12}>
                        <ArticleTones mode={setDisplayMode('ArticleTone')} record={post}/>
                    </Grid>
                </Grid>
                <Grid container flexDirection="column">
                    <Grid mb={3}>
                        <Keywords mode={setDisplayMode('new_Keywords')}/>
                    </Grid>
                    <Grid mb={3}>
                        <Instructions mode={setDisplayMode('new_WritingInstructions')}/>
                    </Grid>
                    <Grid mb={3}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={6}>
                                <TargetUrl
                                    mode={setDisplayMode('new_TargetURL1')}
                                    idx={1}
                                    record={post}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnchorText
                                    mode={setDisplayMode('new_Anchortext1')}
                                    idx={1}
                                    record={post}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={6}>
                                <TargetUrl
                                    mode={setDisplayMode('new_TargetURL2')}
                                    idx={2}
                                    record={post}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnchorText
                                    mode={setDisplayMode('new_Anchortext2')}
                                    idx={2}
                                    record={post}
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={6}>
                                <TargetUrl
                                    mode={setDisplayMode('new_TargetURL3')}
                                    idx={3}
                                    record={post}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AnchorText
                                    mode={setDisplayMode('new_Anchortext3')}
                                    idx={3}
                                    record={post}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid mb={3}>
                        <H1Title mode={setDisplayMode('new_Title')}/>
                    </Grid>
                    <Grid mb={3}>
                        <SeoTitle
                            mode={setDisplayMode('new_SeoTitle')}
                            validate={validateSEOTitle}
                            label={seoTitleLabel}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                countCharacters(e, setSeoTitleCounter);
                            }}
                        />
                    </Grid>
                    <Grid mb={3}>
                        <SeoDescription
                            mode={setDisplayMode('new_PostDesciption')}
                            validate={validateSEODescription}
                            label={seoDescriptionLabel}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                countCharacters(e, setSeoDescriptionCounter);
                            }}
                        />
                    </Grid>
                    <Grid>
                        <ContentBody
                            mode={setDisplayMode('new_TextHTMLbody')}
                            setWordCount={setWordCount}
                            wordCount={wordCount}
                            record={post}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </SaveContextProvider>
    );
};

function H1Title({ mode }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        return (
            <Labeled label="H1 Title" fullWidth>
                <TextField
                    source="new_Title"
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <>
                <div style={{ marginBottom: 26, marginTop: 12 }}>
                    <Divider/>
                </div>
                <TextInput fullWidth source="new_Title" label="H1 Title"/>
            </>
        );
    }

    return null;
}

function Instructions({ mode }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        return (
            <Labeled label="Writing Instructions" fullWidth>
                <RichTextField
                    source="new_WritingInstructions"
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }

    if (mode === DisplayMode.WRITE) {
        return (
            <RichTextInput
                label="Writing Instructions"
                source="new_WritingInstructions"
                fullWidth
                toolbar={
                    <RichTextInputToolbar>
                        <FormatButtons/>
                        <ListButtons/>
                        <ClearButtons/>
                    </RichTextInputToolbar>
                }
            />
        );
    }

    return null;
}

function Keywords({ mode }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        return (
            <Labeled label="Request Keywords" fullWidth>
                <RichTextField
                    source="new_Keywords"
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <TextInput source="new_Keywords" label="Request Keywords" fullWidth/>
        );
    }

    return null;
}

function ArticleTones({ mode, record }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        if (record?.ArticleTone === null) {
            return (
                <Labeled label="Tone/Style of the Article" fullWidth>
                    <Typography component="span" variant="body2" sx={TextFieldStyles}>
                        Not Set
                    </Typography>
                </Labeled>
            );
        } else {
            return (
                <Labeled label="Tone/Style of the Article" fullWidth>
                    <ReferenceField source="ArticleTone" reference="v1_ArticleTones">
                        <TextField source="value" sx={{ ...TextFieldStyles, display: 'inline-grid', width: '100%' }}/>
                    </ReferenceField>
                </Labeled>
            );
        }
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <ReferenceInput
                source="ArticleTone"
                reference="v1_ArticleTones"
                sort={{ field: 'value', order: 'ASC' }}
            >
                <AutocompleteInput
                    label="Tone/Style of the Article"
                    optionText="value"
                    defaultValue={null}
                    parse={(value) => (value === '' ? null : value)}
                    filterToQuery={(searchText) => ({
                        new_name: searchText,
                    })}
                    size="small"
                />
            </ReferenceInput>
        );
    }

    return null;
}

function AnchorText({ mode, idx, record }: FormFieldsProps) {
    if (mode === DisplayMode.READ && record[`new_Anchortext${idx}`]) {
        return (
            <Labeled label={`Anchor Link Text (${idx})`} fullWidth>
                <TextField
                    source={`new_Anchortext${idx}`}
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <TextInput
                source={`new_Anchortext${idx}`}
                label={`Anchor Link Text (${idx})`}
                fullWidth
            />
        );
    }

    return null;
}

function TargetUrl({ mode, idx, record }: FormFieldsProps) {
    if (mode === DisplayMode.READ && record[`new_TargetURL${idx}`]) {
        return (
            <Labeled label={`Target URL (${idx})`} fullWidth>
                <TextField
                    source={`new_TargetURL${idx}`}
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <TextInput
                source={`new_TargetURL${idx}`}
                label={`Target URL (${idx})`}
                fullWidth
            />
        );
    }

    return null;
}

function SeoTitle({ mode, validate, label, onChange }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        return (
            <Labeled label={label} fullWidth>
                <TextField
                    source="new_SeoTitle"
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <TextInput
                fullWidth
                source="new_SeoTitle"
                validate={validate}
                label={label}
                onChange={onChange}
            />
        );
    }
    return null;
}

function SeoDescription({ mode, validate, label, onChange }: FormFieldsProps) {
    if (mode === DisplayMode.READ) {
        return (
            <Labeled label={label} fullWidth>
                <TextField
                    source="new_PostDesciption"
                    emptyText="Not set"
                    sx={TextFieldStyles}
                />
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <TextInput
                source="new_PostDesciption"
                validate={validate}
                label={label}
                onChange={onChange}
                fullWidth
                multiline
                rows={3}
            />
        );
    }
    return null;
}

function ContentBody({ mode, setWordCount, record, wordCount }: TextEditorProps) {
    const [label, setLabel] = useState('Copy HTML');
    const [icon, setIcon] = useState(<ContentCopyIcon/>);

    function copyHtml(html: string) {
        void navigator.clipboard.writeText(html);
        setLabel('Copied!');
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setLabel('Copy HTML');
            setIcon(<ContentCopyIcon/>);
        }, 3000);
    }

    if (mode === DisplayMode.READ) {
        return (
            <Labeled
                label="Content"
                fullWidth
                className="tinymce-body tinymce-body--read"
            >
                <>
                    <div className="tinymce-body__information">
                        <div className="tinymce-body__wordcount">
              <span className="tinymce-body__wordcount-text">
                Word Count: {record.new_wordcount}
              </span>
                        </div>
                        <div>
                            <Button
                                label={label}
                                startIcon={icon}
                                variant="contained"
                                onClick={() => copyHtml(record.new_TextHTMLbody)}
                                className="copy-text"
                            />
                        </div>
                    </div>
                    <RichTextField
                        source="new_TextHTMLbody"
                        sx={{ ...TextFieldStyles, ...{ paddingTop: '40px' } }}
                        emptyText="Not set"
                    />
                </>
            </Labeled>
        );
    }
    if (mode === DisplayMode.WRITE) {
        return (
            <>
                <Chip
                    icon={<BarChartIcon/>}
                    label={`Words: ${wordCount}`}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <Labeled label="Content" fullWidth>
                    <TinyMce source="new_TextHTMLbody" setWordCount={setWordCount}/>
                </Labeled>
                <Chip
                    icon={<BarChartIcon/>}
                    label={`Words: ${wordCount}`}
                    variant="outlined"
                    sx={{ mt: 2 }}
                />
            </>

        );
    }
    return null;
}

const PostEditToolbar = ({ wordCount }: PostEditToolbarProps) => {
    const addRecordEvent = useAddRecordEvent();
    const record = useRecordContext();
    const isDeclined = record.new_ContentOrderStatus === CONTENT_STATUS.DECLINED;
    const isOrdered = record.new_ContentOrderStatus === CONTENT_STATUS.ORDERED;

    const CHECK_COPYSCAPE_MUTATION = gql`
    mutation checkCopyScapeMutation($text: String!, $postId: Int!) {
      checkCopyScape(text: $text, postId: $postId)
    }
  `;

    const [
        checkCopyScape,
    ] = useMutation(CHECK_COPYSCAPE_MUTATION, { ignoreResults: false });

    return (
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <SaveButton label="Save" sx={{ textTransform: 'uppercase' }}/>
            {isDeclined || isOrdered ? (
                <UpdateButton
                    id={record && record.id}
                    label="Send for Approval"
                    data={{
                        new_ContentOrderStatus: CONTENT_STATUS.WAITING_APPROVAL,
                        new_wordcount: wordCount,
                    }}
                    resource="v1_Posts"
                    name="Post"
                    onSuccess={(data: any) => {
                        const variables = {
                            variables: {
                                text: data.new_TextHTMLbody,
                                postId: data.id,
                            },
                        };
                        checkCopyScape(variables);
                        addRecordEvent('PostChangeStatus', { data });
                    }}
                />
            ) : null}
        </Toolbar>
    );
};

export default OrderForm;
