import isEqual from 'lodash/isEqual';
import kebabCase from 'lodash/kebabCase';
import {extractValidSavedQueries, SavedQuery, useListContext, useSavedQueries} from 'react-admin';
import {store} from '../data/storeWrapper';

export const useSavedQueryDatagridInfo = (): SavedQueryDatagridInfo => {
    const {filterValues, sort, perPage, displayedFilters, resource} = useListContext();

    const isStateEqualToQuery = (savedQuery: SavedQuery): boolean =>
        isEqual(savedQuery.value, {
            filter: filterValues,
            sort,
            perPage,
            displayedFilters,
        });

    const [savedQueries, setSavedQueries] = useSavedQueries(resource);
    const validSavedQueries = extractValidSavedQueries(savedQueries);

    const activeSavedQuery = validSavedQueries.find(isStateEqualToQuery);

    if (activeSavedQuery) {
        const slug = kebabCase(activeSavedQuery.label);
        const displayedQueryColumns = store.getItem(`preferences.${resource}.datagrid.${slug}.columns`, null);

        // initialize the columns settings from the current resource columns settings
        if (!displayedQueryColumns) {
            store.setItem(
                `preferences?.${resource}?.datagrid?.${slug}?.columns`,
                store.getItem(`preferences?.${resource}?.datagrid?.columns`, undefined)
            );
            store.setItem(
                `preferences?.${resource}?.datagrid?.${slug}?.omit`,
                store.getItem(`preferences?.${resource}?.datagrid?.omit`, undefined)
            );
            store.setItem(
                `preferences?.${resource}?.datagrid?.${slug}?.availableColumns`,
                store.getItem(`preferences?.${resource}?.datagrid?.availableColumns`, undefined)
            );
        }

        return {
            activeSavedQuery,
            validSavedQueries,
            activeSavedQuerySlug: slug,
            preferenceKey: `${resource}.datagrid.${slug}`,
            setSavedQueries: setSavedQueries
        };
    }

    return {
        activeSavedQuery: null,
        validSavedQueries: validSavedQueries,
        activeSavedQuerySlug: null,
        preferenceKey: `${resource}.datagrid`,
        setSavedQueries: setSavedQueries
    };
};

export type SavedQueryDatagridInfo = {
    activeSavedQuery: SavedQuery | null;
    validSavedQueries: SavedQuery[];
    activeSavedQuerySlug: string | null;
    preferenceKey: string;
    setSavedQueries: (value: SavedQuery[]) => void
};
