import {Box, Typography} from "@mui/material";
import {
    FunctionField,
    Loading,
    NumberField,
    ReferenceField,
    TextField,
    useGetOne,
    useRecordContext,
} from "react-admin";
import TranslateIcon from "@mui/icons-material/Translate";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LinkIcon from "@mui/icons-material/Link";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {v1_Posts} from "../../../../generated/types";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const PostInformation = () => {
    const post = useRecordContext<v1_Posts>();

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {id: post?.OfferId},
        {enabled: !!post?.OfferId}
    );

    if (!post || isOfferLoading) return <Loading/>;

    return (
        <Box
            display="flex"
            gap={2}
            mb={5}
            sx={{
                flexDirection: {xs: "column", lg: "row"},
                alignItems: "center",
                flexWrap: "wrap",
                ".MuiTypography-body2": {fontWeight: "600"}
            }}
        >

            <Box display="flex" alignItems="center">
                <TranslateIcon fontSize="small" sx={{marginRight: 1}}/>
                <ReferenceField
                    source="language_id"
                    link="show"
                    reference="v1_new_languagesBase"
                    emptyText="No default language"
                >
                    <TextField
                        source="new_name"
                    />
                </ReferenceField>
            </Box>

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <LinkIcon fontSize="small" sx={{marginRight: 1}}/>
                <ReferenceField
                    source="OfferId"
                    reference="v1_Offer"
                    link={false}
                    emptyText="No follow">
                    <FunctionField
                        source="do_follow"
                        render={(record: { do_follow: boolean }) =>
                            <Typography
                                sx={{fontWeight: "600"}}>
                                {record?.do_follow ? "Do follow" : "No follow"}
                            </Typography>
                        }/>
                </ReferenceField>
            </Box>

            {offer?.IsHomepageLink && [
                <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>,
                <HomeOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
            ]}

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <Typography sx={{marginRight: 1}}>Niche: </Typography>
                <ReferenceField
                    source="NicheId"
                    link="show"
                    reference="v1_PostNiche"
                    emptyText="None"
                >
                    <TextField
                        source="name"
                    />
                </ReferenceField>
            </Box>

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <ArticleOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
                <Typography sx={{marginRight: 1}}>Ordered Words:</Typography>
                <NumberField
                    source="new_RequestedMinWords"
                    sx={{fontWeight: "600"}}
                    emptyText="No min words"
                />
            </Box>

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <AttachMoneyIcon fontSize="small" sx={{marginRight: 1}}/>
                <ReferenceField
                    source="OfferId"
                    reference="v1_Offer"
                    link={false}
                    emptyText="No price">
                    <TextField
                        source="link_price"
                        emptyText="No price"/>
                </ReferenceField>
            </Box>
        </Box>
    );
};
export default PostInformation;
