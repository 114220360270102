import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import * as React from 'react';
import { useCallback } from 'react';
import {MenuItemLink, useLogout, UserMenu as RaUserMenu, useUserMenu} from "react-admin";
import { useQueryClient } from 'react-query';
import { RoleName } from '../models/role-model';
import { useGetIdentity } from '../hooks/useGetIdentity';
import SettingsIcon from '@mui/icons-material/Settings';

const Logout = React.forwardRef<any, any>((props, ref) => {
  const logout = useLogout();
  const handleClick = useCallback(
    () => logout(null, undefined, false),
    [logout]
  );
  return (
    <MenuItem
      ref={ref}
      {...props}
      onClick={handleClick}
      sx={{ color: 'text.secondary' }}
    >
      <ListItemIcon>
        <LogoutOutlinedIcon />
      </ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </MenuItem>
  );
});

const RegisterAs = React.forwardRef<{ role: RoleName }, any>((props, ref) => {
  const { onClose } = useUserMenu();
  const queryClient = useQueryClient();
  const handleClick = useCallback(() => {

    localStorage.setItem('role', props.role);
    // Invalidate the permissions and identity cache to force a refresh
    queryClient.invalidateQueries(['auth', 'getPermissions']);
    queryClient.invalidateQueries(['auth', 'getIdentity']);
    onClose();
    window.location.reload()
  }, [onClose, props.role, queryClient]);

  return (
    <MenuItem
      ref={ref}
      {...props}
      to="/"
      onClick={handleClick}
      sx={{ color: 'text.secondary' }}
    >
      <ListItemIcon>
        <AppRegistrationOutlinedIcon />
      </ListItemIcon>
      <ListItemText>Log In as {props.role}</ListItemText>
    </MenuItem>
  );
});

export const UserMenu = () => {
  const { data: identity } = useGetIdentity();
  const role = identity?.role;
  const roles = identity?.roles ?? [];

  return (
    <RaUserMenu>
      <MenuList>
        {roles.includes('Writer') && role !== 'Writer' && (
          <RegisterAs role="Writer" />
        )}
        {roles.includes('Supplier') && role !== 'Supplier' && (
          <RegisterAs role="Publisher" />
        )}
        {roles.includes('Advertiser') && role !== 'Advertiser' && (
          <RegisterAs role="Advertiser" />
        )}
        <MenuItemLink
            to="/my-profile"
            primaryText="My Profile"
            leftIcon={<SettingsIcon sx={{marginRight: '12px'}}/>}
        />
        <Logout />
      </MenuList>
    </RaUserMenu>
  );
};
