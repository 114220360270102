export const POST_STATUS = {
    IN_PROGRESS: 100000000,
    POSTED: 100000003,
    POOL: 100000001,
    PLANNED: 100000002,
};

export const CONTENT_STATUS = {
    PLANNED: 100000008,
    MERGED: 100000007,
    NO_NEED: 100000000,
    POOL: 100000001,
    ORDERED: 100000002,
    WAITING_APPROVAL: 100000003,
    DECLINED: 100000005,
    APPROVED: 100000004,
    CONTENT_REQUEST: 100000006,
    WAITING_APPROVAL_ADVERTISER: 100000011,
    CANCELLED: 100000012,
};

export const OUTREACH_STATUS = {
    CONTENT_WRITING: 100000000,
    READY_TO_PUBLISH: 100000001,
    SENT_TO_PUBLISHER: 100000002,
    PUBLISHED: 100000003,

};

export const PURCHASE_ORDER_STATUS = {
    APPROVAL_REQUIRED: 1,
    TO_PAY: 100000000,
    PAID: 2,
    CANCELLED: 100000001,
};

export const TRACKERS_ONLINE_STATUS = {
    NOT_AVAILABLE: 100000001,
    ONLINE: 100000000,
};


export const ORDER_STATUS_VALUE = {
    ORDERED: 'Ordered',
    WAITING_APPROVAL: 'Waiting Approval',
    WAITING_APPROVAL_ADVERTISER: 'Waiting Approval (From Advertiser)',
    DECLINED: 'Declined',
    APPROVED: 'Approved',
};


export const PUBLISHER_POST_STATUS = {
    APPROVE_ORDER: 1,
    DECLINED_ORDER: 2,
    CONTENT_CREATION: 3,
    TO_PUBLISH: 4,
    PUBLISHED: 5,
    CANCELLED: 6,
}

export const MARKETPLACE_STATUS = {
    PROVIDE_DETAILS: 1,
    ORDER_PLACED: 2,
    CONTENT_CREATION: 3,
    WAITING_APPROVAL: 4,
    SENT_TO_PUBLISHER: 5,
    PUBLISHED: 6,
    CANCELLED: 7,
};

export const OFFER_STATUS = {
    DECLINED: 1,
    PUBLISHED: 2,
    IN_PROGRESS: 3,
    WAITING_APPROVAL: 4,
    UNPUBLISHED: 5,
};

export const CHECKOUT_STATUS = {
    ORDER_REVIEW: 1,
    BILLING: 2,
    CONTENT_DETAILS: 3,
    COMPLETED: 4,
};

export const CHECKOUT_STATUS_VALUES = {
    [CHECKOUT_STATUS.ORDER_REVIEW]: 'Order Review',
    [CHECKOUT_STATUS.BILLING]: 'Billing',
    [CHECKOUT_STATUS.CONTENT_DETAILS]: 'Content Details',
    [CHECKOUT_STATUS.COMPLETED]: 'Completed',
};