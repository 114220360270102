import axios from 'axios';
import {nhost} from '../lib/nhost';
import {getApiHref, getNotificationApiHref} from '../utils/utils';
import {HttpMethod} from "../config/constants";

export const sendPostToApi = async (
    endpoint: string,
    data: any
): Promise<any> => {
    const headers = getHeaders();

    try {
        const response = await axios.post(getApiHref(endpoint), data, headers);
        return response;
    } catch (error) {
        throw error;
    }
};

export const sendToNotificationApi = async ({
                                                endpoint,
                                                data,
                                                method,
                                            }: {
    endpoint: string;
    data?: any;
    method: HttpMethod;
}): Promise<any> => {
    const config = getHeaders();
    try {
        const response = await axios({
            method,
            url: getNotificationApiHref(endpoint),
            data,
            headers: config.headers,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

const getHeaders = () => {
    const nhostToken = nhost.auth.getAccessToken() as string;
    return {
        headers: {
            authorization: nhostToken ? `Bearer ${nhostToken}` : '',
            'x-hasura-role': localStorage.getItem('role')
        },
    };
};
