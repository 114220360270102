import {
    Datagrid,
    DateField,
    Loading,
    NumberField,
    ReferenceField,
    TextField,
    useGetOne,
    useRecordContext,
    WrapperField
} from "react-admin";
import {StatusField} from "../../general/statusField";
import {PUBLISHER_ORDER_STATUS_CHIP_MAP} from "../../general/constants";
import React from "react";
import {v1_Posts} from "../../../../generated/types";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkIcon from "@mui/icons-material/Link";
import {Box, SxProps} from "@mui/material";
import {TruncatedTooltipField} from "../../common/truncatedTooltipField";

const SiteSection = () => {

    const post = useRecordContext<v1_Posts>();

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {id: post?.OfferId},
        {enabled: !!post?.OfferId}
    );

    if (!post || isOfferLoading) return <Loading/>

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <ReferenceField
                source="OfferId"
                reference="v1_Offer"
                link={false}

            >
                <TruncatedTooltipField
                    source="name"
                />
            </ReferenceField>
            {offer?.IsHomepageLink &&
                <HomeOutlinedIcon fontSize="small"/>
            }
            {offer?.do_follow &&
                <LinkIcon fontSize="small"/>
            }
        </Box>
    )

}
export const PostListFields = () => {

    const rowStyle = (record: any, _index: number): SxProps => {
        return {
            display: 'flex'
        }
    };

    return (
        <Datagrid
            bulkActionButtons={false}
            rowClick="edit"
        >
            <ReferenceField
                label="Order Status"
                source="PublisherStatus"
                reference="v1_PublisherPostStatus"
                link={false}
            >
                <StatusField chipMap={PUBLISHER_ORDER_STATUS_CHIP_MAP} source="Status"/>
            </ReferenceField>
            <TextField source="id" label="Order ID"/>
            <DateField
                label="Created On"
                source="CreatedOn"
                locales="en-GB"
                key="dateField"
            />
            <ReferenceField
                source="OfferId"
                reference="v1_Offer"
                link={false}
                label="Duration"
                sortBy="Offer.PostDuration.value"
            >
                <ReferenceField
                    source="postduration_id"
                    reference="v1_PostDuration"
                    link={false}
                >
                    <TextField
                        source="value"
                    />
                </ReferenceField>
            </ReferenceField>
            <WrapperField
                label="Order Amount"
                sortBy="comp_total_price_writer"
                key="comp_total_price_writer"
            >
                <ReferenceField
                    reference="v1_TransactionCurrencyBase"
                    source="currency_id"
                >
                    <TextField source="CurrencySymbol" sx={{marginInlineEnd: 1}}/>
                </ReferenceField>
                <NumberField source="comp_total_price_writer"/>
            </WrapperField>
            <WrapperField label="Site/section" sortBy="Offer.name">
                <SiteSection/>
            </WrapperField>
            <TextField
                source="new_TargetURL1"
                label="Target URL"
            />
        </Datagrid>
    );
};
