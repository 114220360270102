import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import {
  Box,
  ListItem,
  ListItemText,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { EventAvatar, EventRecord } from '@react-admin/ra-audit-log';
import { useGetIdentity, useRecordContext } from 'react-admin';
import { EventIcon } from './eventIcon';
import { EventLabel } from './eventLabel';

const Separator = () => (
  <Typography color="textSecondary" variant="subtitle2">
    -
  </Typography>
);

export const EventsAndCommentsTimelineItem = () => {
  const { identity: userIdentity } = useGetIdentity();
  const record = useRecordContext<EventRecord>();
  const isComment = record?.action === 'comment';
  const isEventAuthor = record?.author.id === userIdentity?.id;

  const commentFieldStyles: SxProps<any> = (theme: any) => ({
    backgroundColor: isEventAuthor
      ? theme.palette.background.highlighted.primary
      : theme.palette.background.highlighted.secondary,
    py: 1,
    px: 2,
    borderRadius: 1,
  });

  if (!record) {
    return null;
  }

  return (
    <ListItem dense disableGutters sx={{ py: 0 }}>
      <ListItemText>
        <Box>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ flexWrap: 'wrap' }}
          >
            <Typography color="textSecondary" variant="subtitle2">
              {new Date(record.date).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
            <Separator />

            {isComment ? (
              <SmsOutlinedIcon sx={{ width: 16, height: 16 }} />
            ) : (
              <EventIcon record={record} />
            )}

            <EventAvatar
              alt={record.author.fullName}
              src={record.author.avatar}
              fullName={record.author.fullName}
              role="presentation"
              size="small"
              sx={(theme) => ({
                [`&.RaEventAvatar-small`]: {
                  width: 16,
                  height: 16,
                },
                fontSize: 10,
                bgcolor: isEventAuthor
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              })}
            />
            {isEventAuthor ? (
              <Typography color="primary" variant="subtitle2">
                <strong>YOU</strong>
              </Typography>
            ) : (
              <Typography color="textPrimary" variant="subtitle2">
                <strong>{record.author.fullName}</strong>
              </Typography>
            )}

            {!isComment && (
              <>
                <Separator />
                <EventLabel record={record} />
              </>
            )}
          </Stack>
          {isComment && (
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={commentFieldStyles}
            >
              {record.payload?.body || ''}
            </Typography>
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
};
