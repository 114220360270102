import {CreateBase, CreateView, useGetIdentity, useNotify} from 'react-admin';
import OfferForm from "./offerForm";
import {MARKETPLACE_USER_ID, POST_DURATION} from "../../../config/constants";
import {FormAction} from "../../../type/formActions";
import {checkAndCreateWebsite} from "./checkAndCreateWebsite";
import {v1_Offer} from "../../../../generated/types";
import {Box, Typography} from "@mui/material";
import PageTitle from "../../../layout/PageTitle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {OFFER_STATUS} from "../../../config/statuses";

const OfferCreate = () => {
    const identity = useGetIdentity();
    const userId = identity?.data?.id;

    const notify = useNotify();

    const transformData = async (data: v1_Offer) => {
        const websiteId = await checkAndCreateWebsite(data?.name);
        if (!websiteId) {
            notify('Failed to create Website', {type: "warning"});
            return;
        }

        return {
            ...data,
            website_id: websiteId,
            createdby_id: MARKETPLACE_USER_ID,
            Status: OFFER_STATUS.WAITING_APPROVAL
        }
    };

    return (
        <CreateBase
            redirect={'edit'}
            transform={transformData}
            mutationOptions={{
                onError: () => {
                },
            }}
        >

            <Box sx={{marginBottom: 2}} display="flex" gap={4}>
                <PageTitle>Create Offer</PageTitle>

                <Box display="flex" alignItems="center">
                    <DateRangeIcon fontSize="small" sx={{marginRight: 1}}/>
                    <Typography sx={{marginRight: 1}}>Created On:</Typography>
                    <Typography
                        sx={{marginRight: 1, fontWeight: "600", fontSize: "18px"}}
                    >{new Date().toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}</Typography>
                </Box>
            </Box>

            <CreateView>
                <OfferForm
                    action={FormAction.Create}
                    defaultValues={{
                        supplier_id: userId,
                        do_follow: true,
                        postduration_id: POST_DURATION.PERMANENT,
                        additional_price_percentage: 0
                    }}
                />
            </CreateView>
        </CreateBase>
    );
};

export default OfferCreate;
