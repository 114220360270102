import {Box, Typography} from "@mui/material";
import {DateField, ReferenceField, useRecordContext} from "react-admin";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PageTitle from "../../../layout/PageTitle";
import {StatusField} from "../../general/statusField";
import {OFFER_STATUS_CHIP_MAP} from "../../general/constants";

const OfferEditTitle = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <Box sx={{marginBottom: 7}} display="flex" gap={4} alignItems="center">
            <PageTitle>Offer - #{record.id}</PageTitle>

            <Box display="flex" alignItems="center">
                <DateRangeIcon fontSize="small" sx={{marginRight: 1}}/>
                <Typography sx={{marginRight: 1}}>Created On:</Typography>
                <DateField
                    source="created_on"
                    options={{year: "numeric", month: "long", day: "numeric"}}
                    sx={{fontWeight: "600", fontSize: "18px"}}
                    emptyText="No Create Date"
                />
            </Box>
            <ReferenceField reference="v1_OfferStatus" source="Status">
                <StatusField chipMap={OFFER_STATUS_CHIP_MAP} source="value" />
            </ReferenceField>
        </Box>
    );
};
export default OfferEditTitle;
