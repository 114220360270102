import {ListBase, ListView, Loading, Pagination} from 'react-admin';
import {Box, Card, Tab, Tabs, Typography} from '@mui/material';
import {MarketplaceDatagrid} from './marketplaceDatagrid';
import PageTitle from '../../../layout/PageTitle';
import {MarketPlaceFilters} from './marketPlaceFilters';
import {MarketPlaceSelectColumnsButton} from './marketPlaceSelectColumnsButton';
import {useMeasure} from 'react-use';
import {OFFER_STATUS} from '../../../config/statuses';
import {STATE_CODE} from "../../../config/constants";
import {SyntheticEvent, useState} from "react";
import {useGetIdentity} from "../../../hooks/useGetIdentity";

const INNER_PADDING = 32;
export const MarketplaceList = () => {
    const [ref, {width}] = useMeasure<HTMLDivElement>();
    const [likedFilter, setLikedFilter] = useState('all');

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    if (isIdentityLoading) return <Loading/>;

    const likedFilterValue = likedFilter === 'liked'
        ? {"LikedUsers#user_id": identity?.id}
        : {}

    const handleTabChange = (_: SyntheticEvent, newValue: string) => {
        setLikedFilter(newValue);
    };

    return (
        <Box ref={ref}>
            <Box sx={{marginBottom: 7}}>
                <PageTitle>MarketPlace</PageTitle>
            </Box>
            <ListBase
                sort={{field: 'Status', order: 'DESC'}}
                filter={{
                    Status: OFFER_STATUS.PUBLISHED,
                    statecode: STATE_CODE.ENABLED,
                    ...likedFilterValue
                }}
                perPage={10}
            >
                <MarketPlaceFilters/>
                <Card
                    sx={{
                        padding: 4,
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mb={2}
                    >
                        <Box display="flex" gap={3} alignItems="center">
                            <Typography variant="h3">MarketPlace</Typography>
                            <Tabs value={likedFilter} onChange={handleTabChange} textColor="inherit">
                                <Tab label="All Offers" value="all"/>
                                <Tab label="Liked Offers" value="liked"/>
                            </Tabs>
                        </Box>
                        <Box display="flex" gap={3} alignItems="center">
                            <MarketPlaceSelectColumnsButton/>
                        </Box>
                    </Box>
                    <ListView
                        title="My Offers"
                        pagination={<Pagination rowsPerPageOptions={[10]}/>}
                        actions={false}
                        empty={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: '1rem',
                                        lineHeight: '2.5rem',
                                        color: '#007DBA',
                                    }}
                                >
                                    There are no {likedFilter === 'liked' && 'liked'} offers yet
                                </Typography>
                            </Box>
                        }
                    >
                        <MarketplaceDatagrid
                            width={width - INNER_PADDING}
                            userId={identity?.id}
                        />
                    </ListView>
                </Card>
            </ListBase>
        </Box>
    );
};
