import {useState, useContext} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {Button, useNotify, useRefresh} from 'react-admin';
import {DialogContext} from './dialogContext';
import {sendPostToApi} from '../../../../services/api';
import {apiErrorMessage} from '../../../../utils/utils';

export function DeleteDialog() {
    const notify = useNotify();
    const refresh = useRefresh();
    const [isDisabled, setIsDisabled] = useState(false);
    const {deleteDialog, setDeleteDialog} = useContext(DialogContext);
    const {isOpen, id, url} = deleteDialog;

    const closeDialog = () => {
        setDeleteDialog({isOpen: false, url: null, id: null});
    };

    const deleteProject = async () => {
        try {
            setIsDisabled(true);
            await sendPostToApi('marketplace/projects/delete', {id});
            notify(`The "${url}" project was successfully deleted`, {
                type: 'success',
            });
            setIsDisabled(false);
            closeDialog();
            refresh();
        } catch (error: unknown) {
            const message = apiErrorMessage(error);
            notify(message, {type: 'error'});
            setIsDisabled(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
            <DialogTitle>Edit Project</DialogTitle>
            <DialogContent>
                Are you sure you want to delete the "{url}" project?
            </DialogContent>
            <DialogActions>
                <Button
                    label="Delete"
                    variant="contained"
                    onClick={deleteProject}
                    color="warning"
                    disabled={isDisabled}
                />
                <Button label="Close" onClick={closeDialog} />
            </DialogActions>
        </Dialog>
    );
}
