const StatusApprovedIcon = () => {
    return (
        <svg viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.19238 2.99414C3.08781 2.99414 2.19238 3.88957 2.19238 4.99414V16.9941C2.19238 18.0987 3.08781 18.9941 4.19238 18.9941H9.19238C9.74467 18.9941 10.1924 19.4419 10.1924 19.9941C10.1924 20.5464 9.74467 20.9941 9.19238 20.9941H4.19238C1.98324 20.9941 0.192383 19.2033 0.192383 16.9941V4.99414C0.192383 2.785 1.98324 0.994141 4.19238 0.994141H12.1924C14.4015 0.994141 16.1924 2.785 16.1924 4.99414V14.3751C16.1924 14.9274 15.7447 15.3751 15.1924 15.3751C14.6401 15.3751 14.1924 14.9274 14.1924 14.3751V4.99414C14.1924 3.88957 13.297 2.99414 12.1924 2.99414H4.19238Z"
                fill="currentColor"
            />
            <rect
                x="4.19238"
                y="14.9941"
                width="6"
                height="2"
                rx="1"
                fill="currentColor"
            />
            <rect
                x="4.19238"
                y="10.9941"
                width="8"
                height="2"
                rx="1"
                fill="currentColor"
            />
            <rect
                x="4.19238"
                y="4.99414"
                width="8"
                height="4"
                rx="2"
                fill="currentColor"
            />
            <path
                d="M18.5811 15.071C18.7029 15.1218 18.8135 15.1962 18.9065 15.2899C19.0002 15.3829 19.0746 15.4935 19.1254 15.6154C19.1761 15.7372 19.2023 15.8679 19.2023 15.9999C19.2023 16.132 19.1761 16.2627 19.1254 16.3845C19.0746 16.5064 19.0002 16.617 18.9065 16.7099L14.9065 20.7099C14.8135 20.8037 14.7029 20.8781 14.5811 20.9288C14.4592 20.9796 14.3285 21.0057 14.1965 21.0057C14.0645 21.0057 13.9338 20.9796 13.8119 20.9288C13.69 20.8781 13.5794 20.8037 13.4865 20.7099L11.4865 18.7099C11.3932 18.6167 11.3193 18.506 11.2688 18.3842C11.2184 18.2624 11.1924 18.1318 11.1924 17.9999C11.1924 17.8681 11.2184 17.7375 11.2688 17.6157C11.3193 17.4939 11.3932 17.3832 11.4865 17.2899C11.5797 17.1967 11.6904 17.1227 11.8122 17.0723C11.934 17.0218 12.0646 16.9958 12.1965 16.9958C12.3283 16.9958 12.4589 17.0218 12.5807 17.0723C12.7025 17.1227 12.8132 17.1967 12.9065 17.2899L14.1965 18.5899L17.4865 15.2899C17.5794 15.1962 17.69 15.1218 17.8119 15.071C17.9338 15.0203 18.0645 14.9941 18.1965 14.9941C18.3285 14.9941 18.4592 15.0203 18.5811 15.071Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default StatusApprovedIcon;
