import {Identifier} from "react-admin";
import React, {SyntheticEvent, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Card, Tab, Typography} from "@mui/material";
import {BilingListCard} from "./billingListCard";

export const BillingList = ({userId}: { userId: Identifier }) => {

    const [tabValue, setTabValue] = useState('all');

    const handleTabChange = (_: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <TabContext value={tabValue}>

            <Card
                sx={{
                    padding: 4,
                }}
            >
                <Typography
                    fontSize={22}
                    fontWeight={'600'}
                >
                    Balance
                </Typography>
                <TabList onChange={handleTabChange} textColor="inherit" sx={{marginBottom: 3, marginTop: 3}}>
                    <Tab value="all" label="All"/>
                    <Tab value="refund" label="Refunds"/>
                    <Tab value="payment" label="Payments"/>
                    <Tab value="order" label="Orders"/>
                </TabList>
                <TabPanel value="all" sx={{padding: 0, paddingBottom: 7}}>
                    <BilingListCard
                        title="All"
                        type="all"
                        showBalance={true}
                        sort={{field: "Date", order: "DESC"}}
                    />
                </TabPanel>
                <TabPanel
                    value="refund"
                    sx={{padding: 0, paddingBottom: 7}}
                >
                    <BilingListCard
                        title="Refunds"
                        type="Refund"
                        sort={{field: "Date", order: "DESC"}}
                    />
                </TabPanel>
                <TabPanel
                    value="payment"
                    sx={{padding: 0, paddingBottom: 7}}
                >
                    <BilingListCard
                        title="Payments"
                        type="Payment"
                        sort={{field: "Date", order: "DESC"}}
                    />
                </TabPanel>
                <TabPanel
                    value="order"
                    sx={{padding: 0, paddingBottom: 7}}
                >
                    <BilingListCard
                        title="Orders"
                        type="Order"
                        sort={{field: "Date", order: "DESC"}}
                    />
                </TabPanel>
            </Card>

        </TabContext>
    )
};