import { AxiosResponse } from 'axios';
import { ROUTES } from '../config/constants';
import { sendPostToApi } from '../services/api';

export type LangData = {
    length: number;
    name: string;
    code: string;
};

export const getNumberOfWords = async (content: string): Promise<AxiosResponse> => {
    return await sendPostToApi(ROUTES.TOKENIZE, {text: content.trim()});
};
