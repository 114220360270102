import {AxiosError} from 'axios';

export const getApiHref = (path: string): string => {
    const apiBaseUrl = process.env.REACT_APP_EXPRESS_API_V1 || '';
    const endpoint = new URL(path, apiBaseUrl);
    return endpoint.href;
};

export const getNotificationApiHref = (path: string): string => {
    const apiBaseUrl = process.env.REACT_APP_NOTIFICATIONS_API_V1 || '';
    const endpoint = new URL(path, apiBaseUrl);
    return endpoint.href;
};

export const ensureHttpsSchema = (input: string): string => {
    if (!/^https?:\/\//i.test(input)) {
        return 'https://' + input;
    }
    return input;
};

export const apiErrorMessage = (error: unknown) => {
    if (!(error instanceof AxiosError)) {
        return 'Something went wrong';
    }

    if (error.code === 'ERR_NETWORK') {
        return 'Network Error: Unable to establish a connection. There may be an issue with your internet connection or the server you are trying to reach.';
    }

    if (error.code === 'ERR_BAD_REQUEST') {
        // TODO: rework error shape on the backend side
        // 403 forbidden
        if (error.response?.data.message) {
            return error.response?.data.message
        }

        return error.response?.data.error
            .map((e: any) => {
                const {msg, path, value} = e;
                return `${msg} for the ${path}: ${value}`;
            })
            .join('; ');
    }

    if (error.code === 'ERR_BAD_RESPONSE') {
        return error.response?.data.error.message;
    }
};
