import {CircularProgress} from '@mui/material';
import {useGetIdentity} from '../../../hooks/useGetIdentity';
import {PublisherDashboard} from '../../publisher/dashboard/dashboard';
import {WriterDashboard} from './writerDashboard';
import {AdvertiserDashboard} from '../../advertiser/dashboard/dashboard';
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from '../../../models/role-model';
import {RecordContextProvider, useGetOne} from 'react-admin';

const SLAVA_ID = 158;

export const Dashboard = () => {
    const {data: identity} = useGetIdentity();
    const role = identity?.role;

    const currentUser = useGetOne(
        'v1_ContactBase',
        {
            id: identity?.id,
        },
        {enabled: !!identity},
    );

    const assistant = useGetOne(
        'v1_SystemUserBase',
        {
            id: getAssistantId(),
        },
        {enabled: !!currentUser.data},
    );

    function getAssistantId() {
        switch (identity?.role) {
            case ROLE_WRITER:
                return currentUser?.data?.owner_id;
            case ROLE_SUPPLIER:
                return SLAVA_ID;
            case ROLE_ADVERTISER:
                return SLAVA_ID;
        }
    }

    if (currentUser.isLoading) return <CircularProgress/>;

    const ctx = {assistant, currentUser};

    function dashboardTpl() {
        switch (role) {
            case ROLE_WRITER:
                return <WriterDashboard/>;
            case ROLE_SUPPLIER:
                return <PublisherDashboard/>;
            case ROLE_ADVERTISER:
                return <AdvertiserDashboard/>;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        <RecordContextProvider value={ctx}>{dashboardTpl()}</RecordContextProvider>
    );
};
