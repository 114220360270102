import {useState} from 'react';
import {Card, CardContent} from '@mui/material';
import {useGetList, Loading} from 'react-admin';
import {EmptyList} from '../../../general/emptyList';
import {Header} from './header';
import {ProjectList} from './list';
import {DialogContext} from './dialogContext';
import {EditDialog} from './editDialog';
import {DeleteDialog} from './deleteDialog';
import {AddDialog} from './addDialog';
import type {v1_MarketplaceProjects} from '../../../../../generated/types';

export function Projects() {
    const {
        data,
        total,
        isLoading: projectsLoading,
    } = useGetList<v1_MarketplaceProjects>('v1_MarketplaceProjects', {});

    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [editDialog, setEditDialog] = useState({
        isOpen: false,
        url: null,
        id: null,
    });
    const [deleteDialog, setDeleteDialog] = useState({
        isOpen: false,
        url: null,
        id: null,
    });

    if (projectsLoading) return <Loading />;

    return (
        <DialogContext.Provider
            value={{
                isAddDialogOpen,
                setIsAddDialogOpen,
                editDialog,
                setEditDialog,
                deleteDialog,
                setDeleteDialog,
            }}
        >
            <Card>
                <CardContent>
                    <Header total={total} />
                    {total ? (
                        <ProjectList projects={data} />
                    ) : (
                        <EmptyList
                            titleText="Create your first project"
                            subtitleText="A project is a website that you want to direct traffic towards"
                            buttonText="CREATE PROJECT"
                            onClick={() => setIsAddDialogOpen(true)}
                        />
                    )}
                </CardContent>
            </Card>
            <AddDialog />
            <EditDialog />
            <DeleteDialog />
        </DialogContext.Provider>
    );
}
