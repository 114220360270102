import React, {useState} from "react";
import {Loading, SaveContextProvider, SimpleForm, Toolbar, useRecordContext, useSaveContext,} from "react-admin";
import {MARKETPLACE_STATUS} from "../../../config/statuses";
import PostInformation from "./postInformation";
import PostFormFields from "./postFormFields";
import {Maybe, v1_Posts} from "../../../../generated/types";
import {PostDeclineButton} from "./postDeclineButton";
import {PostApproveButton} from "./postApproveButton";


const PostForm = () => {
    const post = useRecordContext<v1_Posts>();
    const {save: saveFromContext} = useSaveContext();
    const [wordCount, setWordCount] = useState<Maybe<number> | undefined>(post.new_wordcount);

    function save(data: any) {
        const record = {...data, new_wordcount: wordCount};
        saveFromContext && saveFromContext(record);
    }

    return (
        <SaveContextProvider value={{save}}>
            <SimpleForm toolbar={<PostEditToolbar/>}>
                <PostInformation/>
                <PostFormFields/>
            </SimpleForm>
        </SaveContextProvider>
    );
};

const PostEditToolbar = () => {
    const post = useRecordContext<v1_Posts>();

    if (!post) return <Loading/>;

    const isWaitingApproval = post.marketplacestatus_id === MARKETPLACE_STATUS.WAITING_APPROVAL;

    return isWaitingApproval ? (
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <PostDeclineButton post={post}/>
                <PostApproveButton post={post}/>
            </Toolbar>)
        : false;
};

export default PostForm;
