import {Card} from '@mui/material';
import {
    Datagrid,
    DateField,
    List,
    Loading,
    Pagination,
    ReferenceField,
    TextField,
    useCreatePath,
    useGetList,
} from 'react-admin';
import {ListHeader} from '../../../general/listHeader';
import {EmptyList} from '../../../general/emptyList';
import {StatusField} from '../../../general/statusField';
import {MARKETPLACE_ORDER_STATUS_CHIP_MAP} from '../../../general/constants';
import {MARKETPLACE_STATUS} from '../../../../config/statuses';
import LinkInNewWindowField from '../../../general/linkInNewWindowField';
import type {v1_Posts} from '../../../../../generated/types';

export const LatestOrdersList = () => {
    const createPath = useCreatePath();
    const orderFilter = {
        'marketplacestatus_id@_in': [
            MARKETPLACE_STATUS.PROVIDE_DETAILS,
            MARKETPLACE_STATUS.WAITING_APPROVAL,
        ],
    };

    const {total, isLoading} = useGetList<v1_Posts>('v1_Posts', {
        filter: {...orderFilter},
    });

    if (isLoading) return <Loading/>;

    return (
        <Card
            sx={{
                padding: 7,
                height: '100%',
            }}
        >
            <ListHeader
                title="Latest Orders"
                attentionText="That Require Action"
                attentionCount={Number(total)}
                orderCount={Number(total)}
                buttonText="Show all"
                link={createPath({resource: 'v1_Posts', type: 'list'})}
            />
            <List
                disableSyncWithLocation
                actions={false}
                resource="v1_Posts"
                perPage={8}
                filter={{...orderFilter}}
                sort={{field: 'CreatedOn', order: 'DESC'}}
                pagination={<Pagination rowsPerPageOptions={[8]}/>}
                empty={
                    <EmptyList
                        titleText="You don't have any orders which require attention."
                        subtitleText="Go to the Marketplace page to purchase an order."
                        buttonText="Go to Marketplace"
                        link={createPath({
                            resource: 'v1_Offer',
                            type: 'list',
                        })}
                    />
                }
            >
                <Datagrid bulkActionButtons={false} rowClick="edit">
                    <ReferenceField
                        label="Order Status"
                        source="marketplacestatus_id"
                        reference="v1_MarketplaceStatus"
                        link={false}
                    >
                        <StatusField
                            chipMap={MARKETPLACE_ORDER_STATUS_CHIP_MAP}
                            source="name"
                        />
                    </ReferenceField>
                    <TextField source="id"/>
                    <DateField
                        source="CreatedOn"
                        label="Created On"
                        locales="en-GB"
                    />
                    <ReferenceField
                        label="Site/Section"
                        source="OfferId"
                        reference="v1_Offer"
                        link={false}
                    >
                        <LinkInNewWindowField
                            label=""
                            propertyName="name"
                            showPropertyName={true}
                        />
                    </ReferenceField>
                    <ReferenceField
                        source="MarketplaceProjectId"
                        reference="v1_MarketplaceProjects"
                        label="Project"
                    >
                        <TextField source='url'/>
                    </ReferenceField>
                </Datagrid>
            </List>
        </Card>
    );
};
