import {
    AutocompleteArrayInput,
    AutocompleteInput,
    CheckboxGroupInput,
    NumberInput,
    ReferenceInput,
    required,
    TextInput,
} from "react-admin";
import React, {useEffect} from "react";
import {Box, Grid, InputAdornment} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {useFormContext, useWatch} from "react-hook-form";
import {ReferenceManyToManyInput} from "@react-admin/ra-relationships";
import WrapperLookAlikeMUI from "./WrapperLookAlikeMUI";
import {POST_DURATION} from "../../../config/constants";
import {BooleanFormInput} from "../booleanFormInput";
import NumberInputNoWheel from "../numberInputNoWheel";

const OfferFormFields = (props: any) => {

    const postdurationId = useWatch({
        name: "postduration_id",
    });

    const {setValue} = useFormContext();

    useEffect(() => {
        setValue("IsHomepageLink", false);
    }, [setValue, postdurationId]);

    const isOneYearDuration = POST_DURATION.ONE_YEAR === postdurationId;

    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={7}>
                <TextInput
                    source="name"
                    label="Site/section"
                    placeholder="Write here"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                    validate={required()}
                    helperText={false}
                />
            </Grid>
            <Grid item xs={1}>
                <BooleanFormInput label="Do follow" source="do_follow"/>
            </Grid>
            <Grid item xs={1}>
                <NumberInput
                    fullWidth
                    label="Num of Links "
                    source="links_to_follow"
                    InputLabelProps={{shrink: true}}
                    helperText={false}
                    max={3}
                />
            </Grid>
            {isOneYearDuration && <Grid item xs={1}>
                <BooleanFormInput label="Homepage" source="IsHomepageLink"/>
            </Grid>}
            <Grid item xs={isOneYearDuration ? 2 : 3}>
                <ReferenceInput
                    source="postduration_id"
                    reference="v1_PostDuration"
                >
                    <AutocompleteInput
                        optionText="value"
                        size="small"
                        label="Post Duration"
                        validate={required()}
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={2}>
                <ReferenceInput
                    label="Language"
                    source="LanguageId"
                    reference="v1_new_languagesBase"
                    perPage={1000}
                >
                    <AutocompleteInput
                        optionText="new_name"
                        size="small"
                        placeholder="Select one"
                        filterToQuery={(searchText) => ({
                            new_name: searchText,
                        })}
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={2}>
                <TextInput
                    source="link_price"
                    label="Price"
                    placeholder="Write here"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CreditCardIcon/>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>

            <Grid item xs={8}>
                <ReferenceManyToManyInput
                    source="id"
                    reference="v1_MarketPlaceCategories"
                    through="v1_MarketPlaceCategories_Offers"
                    using="offer_id,marketplace_category_id"
                    sort={{field: "marketplace_category_id", order: "ASC"}}
                >
                    <AutocompleteArrayInput
                        label="Categories"
                        size="small"
                        optionText="CategoryName"
                        optionValue="id"
                        filterToQuery={(searchText) => ({
                            CategoryName: searchText,
                        })}
                        parse={(value) => (value === "" ? null : value)}
                        fullWidth
                        helperText={false}
                    />
                </ReferenceManyToManyInput>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    borderRadius: 1,
                    position: "relative",
                    marginBottom: '4px'
                }}>
                    <WrapperLookAlikeMUI label="Niche"/>
                    <ReferenceManyToManyInput
                        reference="v1_PostNiche"
                        filterChoices={{
                            "name@_in": ["Crypto", "CBD", "Adult", "Gambling", "Finance"],
                        }}
                        through="v1_RestrictedNichesToOffer"
                        using="offer_id,niche_id"
                    >
                        <CheckboxGroupInput
                            label={false}
                            optionText="name"
                            helperText={false}
                            fullWidth
                            sx={{
                                ".MuiFormGroup-row": {
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                }
                            }}
                        />
                    </ReferenceManyToManyInput>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <NumberInputNoWheel
                    fullWidth
                    label="Price Addition % 4 Restr. Niches"
                    source="additional_price_percentage"
                    InputLabelProps={{shrink: true}}
                    helperText={false}
                />
            </Grid>
            <Grid item xs={3}>
                <TextInput
                    label="Publication Sample"
                    source="PublicationSample"
                    multiline
                    placeholder="Paste a link here - optional"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>
            <Grid item xs={7}>
                <TextInput
                    label="Offer Details"
                    source="notes"
                    multiline
                    placeholder="Write here"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>
        </Grid>
    );
};
export default OfferFormFields;
