import {COLORS} from "../config/constants";
import {Avatar, Stack, Typography} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";

type AvatarProps = {
    imageUrl: string,
    name: string,
    jobTitle?: string
    sx?: SxProps<Theme>
}

export const AvatarCard = (
    {
        imageUrl = "https://cherryassets.s3.eu-central-1.amazonaws.com/avatars/assistantf.png",
        name,
        jobTitle,
        sx
    }: AvatarProps) => {

    return (
        <Stack direction="row" alignSelf="center" alignItems="center" spacing={2} sx={sx}>
            <Avatar
                src={imageUrl}
                sx={{width: 56, height: 56, border: "2px solid", borderColor: COLORS.WHITE}}
            />
            <Stack spacing={2}>
                <Typography
                    color="neutral.main"
                >
                    {jobTitle || "Adzz Sales Manager"}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        marginTop: 0,
                    }}
                >
                    {name}
                </Typography>
            </Stack>
        </Stack>
    );

};