import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Edit,
    FunctionField,
    Loading,
    NumberField,
    ReferenceField,
    ReferenceInput,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    useGetOne,
    useRecordContext,
    useRefresh
} from "react-admin";
import React from "react";
import {v1_Offer, v1_Posts} from "../../../../generated/types";
import {Box, Grid, Stack, Typography} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LinkIcon from "@mui/icons-material/Link";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {ReferenceManyToManyInput} from "@react-admin/ra-relationships";
import {RichTextInput} from "ra-input-rich-text";
import {COLORS} from "../../../config/constants";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {MARKETPLACE_STATUS} from "../../../config/statuses";

export const CheckoutPostDetailForm = () => {
    const refresh = useRefresh();
    const checkoutDetail = useRecordContext();

    if (!checkoutDetail) return <Loading/>

    const transformData = async (data: v1_Offer) => {
        return {...data, marketplacestatus_id: MARKETPLACE_STATUS.ORDER_PLACED}
    };

    return (
        <Edit
            resource={'v1_Posts'}
            transform={transformData}
            id={checkoutDetail.post_id}
            title=" "
            redirect={false}
            mutationMode='optimistic'
            mutationOptions={{
                onSuccess: () => {
                    refresh()
                },
            }}
        >
            <SimpleForm sx={{padding: 0}} toolbar={false}>
                <PostInformation/>
                <PostFormFields/>
                <Stack
                    mt={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    <Box display="flex" alignItems="center">
                        <WarningAmberIcon fontSize="large" sx={{color: COLORS.DARK_ORANGE}}/>
                        <Typography
                            variant="body2" px={2} pb={1} fontSize={16} fontWeight={600}
                            sx={{color: COLORS.DARK_ORANGE}}
                        >
                            Please make sure to fill in the required fields. The Target URL is the page you want to link
                            to. Feel free to fill out the other fields, which are optional.
                        </Typography>
                    </Box>
                    <SaveButton
                        label="SAVE"
                        icon={<></>} sx={{
                        padding: "0px 18px",
                        gap: "10px",
                        width: "252px",
                        height: "40px",
                        background: "#757575",
                    }}
                    />
                </Stack>
            </SimpleForm>
        </Edit>
    );
}

const PostFormFields = () => {

    return (
        <Grid container spacing={4}>
            <Grid item xs={6}>
                <TextInput
                    source="new_TargetURL1"
                    label="Target URL - 1"
                    variant="outlined"
                    fullWidth
                    placeholder="Write Here..."
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="new_Anchortext1"
                    label="Anchor Link Text"
                    variant="outlined"
                    fullWidth
                    placeholder="Write Here..."
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                    validate={required()}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="new_TargetURL2"
                    label="Target URL - 2"
                    variant="outlined"
                    fullWidth
                    placeholder="Write Here..."
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="new_Anchortext2"
                    label="Anchor Link Text (optional)"
                    variant="outlined"
                    fullWidth
                    placeholder="Write Here..."
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                />
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput
                    source="language_id"
                    reference="v1_new_languagesBase"
                    sort={{field: 'new_name', order: 'ASC'}}
                >
                    <AutocompleteInput
                        label="Language"
                        size="small"
                        optionText="new_name"
                        parse={(value) => (value === '' ? null : value)}
                        filterToQuery={(searchText) => ({new_name: searchText})}
                        validate={required()}
                        variant="outlined"
                        placeholder="Select one"
                        fullWidth
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceField
                    source="OfferId"
                    reference="v1_Offer"
                    link={false}
                >
                    <ReferenceManyToManyInput
                        source="id"
                        reference="v1_MarketPlaceCategories"
                        through="v1_MarketPlaceCategories_Offers"
                        using="offer_id,marketplace_category_id"
                    >
                        <AutocompleteArrayInput
                            label="Categories"
                            size="small"
                            optionText="CategoryName"
                            optionValue="id"
                            fullWidth
                            disabled
                            helperText={false}
                            sx={{'.MuiChip-root': {maxHeight: '18px'}}}
                        />
                    </ReferenceManyToManyInput>
                </ReferenceField>
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="new_Title"
                    label="H1 Title"
                    variant="outlined"
                    fullWidth
                    placeholder="Write Here..."
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                />
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput
                    source="ArticleTone"
                    reference="v1_ArticleTones"
                    sort={{field: 'value', order: 'ASC'}}
                >
                    <AutocompleteInput
                        label="Tone/Style of the Article"
                        size="small"
                        optionText="value"
                        parse={(value) => (value === '' ? null : value)}
                        filterToQuery={(searchText) => ({value: searchText})}
                        variant="outlined"
                        placeholder="Select one"
                        fullWidth
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <RichTextInput
                    toolbar={<></>}
                    fullWidth
                    source="new_Keywords"
                    label="Keywords"
                    variant="outlined"
                    helperText={false}
                    placeholder="Write Here..."
                    sx={{
                        wordBreak: 'break-word',
                        '&& .ProseMirror': {
                            minHeight: '85px',
                            maxHeight: '85px',
                            overflow: 'auto',
                            backgroundColor: COLORS.WHITE,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <RichTextInput
                    toolbar={<></>}
                    validate={required()}
                    fullWidth
                    source="requestDetails"
                    label="General Instructions"
                    variant="outlined"
                    helperText={false}
                    placeholder="Write Here..."
                    sx={{
                        wordBreak: 'break-word',
                        '&& .ProseMirror': {
                            minHeight: '85px',
                            maxHeight: '85px',
                            overflow: 'auto',
                            backgroundColor: COLORS.WHITE,
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}

const PostInformation = () => {
    const post = useRecordContext<v1_Posts>();

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {id: post?.OfferId},
        {enabled: !!post?.OfferId}
    );

    if (!post || isOfferLoading) return <Loading/>;

    return (
        <Box
            display="flex"
            gap={2}
            mb={5}
            sx={{
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                ".MuiTypography-body2": {fontWeight: "600"}
            }}
        >
            <Box display="flex" alignItems="center">
                <LinkIcon fontSize="small" sx={{marginRight: 1}}/>
                <ReferenceField
                    source="OfferId"
                    reference="v1_Offer"
                    link={false}
                    emptyText="No follow"
                >
                    <FunctionField
                        source="do_follow"
                        render={(record: { do_follow: boolean }) =>
                            <Typography
                                sx={{fontWeight: "600"}}
                            >
                                {record?.do_follow ? "Do follow" : "No follow"}
                            </Typography>
                        }
                    />
                </ReferenceField>
            </Box>

            {offer?.IsHomepageLink && [
                <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>,
                <HomeOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
            ]}

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <Typography sx={{marginRight: 1}}>Niche: </Typography>
                <ReferenceField
                    source="NicheId"
                    link="show"
                    reference="v1_PostNiche"
                    emptyText="None"
                >
                    <TextField
                        source="name"
                    />
                </ReferenceField>
            </Box>

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>

            <Box display="flex" alignItems="center">
                <ArticleOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
                <Typography sx={{marginRight: 1}}>Ordered Words:</Typography>
                <NumberField
                    source="new_RequestedMinWords"
                    sx={{fontWeight: "600"}}
                    emptyText="No min words"
                />
            </Box>

        </Box>
    );
};