import {Box, Button, Tooltip, Typography} from '@mui/material';
import {useCreatePath} from 'react-admin';
import ClearIcon from '@mui/icons-material/Clear';
import {notificationSocketController} from '../../services/socket';
import {useContext, useEffect, useState} from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {NotificationContext, NotificationMessage} from "../../context/notificationContext";
import {COLORS, PAGE, SOCKET_EVENT} from "../../config/constants";
import purify from 'dompurify';

const calculateTime = (date: Date) => {
    const parsedDate = new Date(date);
    const currentTime = new Date();
    const diff = currentTime.getTime() - parsedDate.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days}d`;
    } else if (hours > 0) {
        return `${hours}h`;
    } else if (minutes > 0) {
        return `${minutes}m`;
    } else {
        return 'now';
    }
};

export const NotificationCard = ({userId, notification}: { userId: string; notification: NotificationMessage }) => {
    const {
        removeNotificationFromList,
        unreadNotifications,
        handleCloseNotificationModal,
        removeUnreadNotificationFromList,
    } = useContext(NotificationContext);
    console.log({notification, unreadNotifications});
    const [isUnread, setIsUnread] = useState(unreadNotifications?.some((item) => notification._id === item._id));
    const {message, link, _id} = notification;
    const createPath = useCreatePath();
    const hasResourceLink = message.includes('<resourceLink>');
    const linkId = `link-${_id}`;

    const markNotificationAsRead = async () => {
        await notificationSocketController.emit(SOCKET_EVENT.READ, {
            notificationId: _id,
            userId: userId,
        });
        removeUnreadNotificationFromList(notification);
        setIsUnread(false);
    };


    const handleClick = async (event: any) => {
        if (event.target && event.target.id === linkId) {
            if (isUnread) {
                await markNotificationAsRead();
            }
            handleCloseNotificationModal();
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const updatedMessage =
        hasResourceLink && link
            ? message.replace(
                '<resourceLink>',
                `<a id="${linkId}"
                                  href="${createPath({
                    resource: 'v1_Posts',
                    type: PAGE.EDIT,
                    id: link?.id,
                })}">${link?.id}</a>`
            )
            : message;
    const cleanedMessage = `${purify.sanitize(updatedMessage)}`;

    return (
        <Box
            sx={{
                padding: '10px',
                display: 'flex',
                backgroundColor: isUnread ? COLORS.MATE_GRAY : COLORS.WHITE,
                alignItems: 'center',
                transition: 'transform 0.3s ease-in-out',
                gap: 1,
                ':hover': {transform: 'scale(1.01)'},
            }}
        >
            {isUnread && (
                <Tooltip title="Mark as a read">
                    <FiberManualRecordIcon
                        sx={{cursor: 'pointer', color: COLORS.ORANGE, fontSize: 'smaller'}}
                        onClick={markNotificationAsRead}
                    />
                </Tooltip>
            )}
            <Typography
                sx={{
                    color: COLORS.BLACK,
                    fontSize: '14px',
                    lineHeight: '14px',
                    width: '100%',
                }}
                component="div"
                dangerouslySetInnerHTML={{__html: cleanedMessage}}
            />
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography sx={{color: COLORS.DARK_GRAY, fontSize: '12px'}}>
                    {calculateTime(notification.date)}
                </Typography>
                <Tooltip title="Delete this notification">
                    <Button
                        sx={{minWidth: '30px', padding: 0, '.MuiButton-startIcon': {margin: '0'}}}
                        onClick={async () => {
                            await notificationSocketController.emit(SOCKET_EVENT.DISABLE, {
                                notificationId: _id,
                                userId: userId,
                            });
                            setIsUnread(false);
                            removeNotificationFromList(notification);
                        }}
                        startIcon={<ClearIcon color="warning"/>}
                    />
                </Tooltip>
            </Box>

        </Box>
    );
};
