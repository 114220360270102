import {Datagrid, FilterPayload, List, Pagination, SortPayload, useCreatePath, useGetList} from 'react-admin';
import {Link} from 'react-router-dom';
import {Box, Button, Card, SxProps, Typography, useTheme} from '@mui/material';

interface PostListCardProps {
    children: React.ReactNode;
    title: string;
    filter: FilterPayload;
    contentStatusFilterList?: string[];
    attentionContentStatusFilterList?: string[];
    showAttention?: boolean;
    showRedirectButton?: boolean;
    sort?: SortPayload;
    showOnDashboard?: boolean;
}

interface EmptyProps {
    status?: string[];
}

const Empty = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    color: '#007DBA',
                }}
            >
                You don't have any orders yet.
            </Typography>

            <Typography
                variant="h2"
                sx={{
                    fontSize: '1rem',
                    lineHeight: '2.5rem',
                    color: '#000',
                }}
            >
                Add more offers to increase your chances of selling.
            </Typography>

            <Button
                // to create offer form
                variant="contained"
                color='success'
                size='large'
                sx={{
                    padding: '10px 70px',
                }}
            >
                CREATE OFFER
            </Button>
        </Box>
    );
};

export const PostListCard = ({
                                 children,
                                 title,
                                 filter,
                                 contentStatusFilterList,
                                 attentionContentStatusFilterList,
                                 showAttention,
                                 showRedirectButton,
                                 sort,
                                 showOnDashboard,
                             }: PostListCardProps) => {
    const theme = useTheme();
    const createPath = useCreatePath();

    const filterForStatus = {
        'ContentStatus#value@_in': contentStatusFilterList,
    };

    const filterForRequiringAttentionStatus = {
        'ContentStatus#value@_in': attentionContentStatusFilterList,
    };

    const {
        total: requiringAttentionPostsNumber,
        data: requiringAttentionRecords,
    } = useGetList('v1_Posts', {
        filter: {...filter, ...filterForRequiringAttentionStatus},
    });

    const {
        total: totalPosts,
        // data: allPosts,
    } = useGetList('v1_Posts', {
        filter: {...filter},
    });

    const postRowStyle = (record: any, _index: number): SxProps => {
        const isRequiringAttentionRecord = attentionContentStatusFilterList && requiringAttentionRecords?.some(
            (requiringAttentionRecord) =>
                requiringAttentionRecord?.id === record?.id,
        );
        return isRequiringAttentionRecord
            ? {
                backgroundColor: `${theme.palette.warning.main}0d`, // add 0d to make it 5% opacity
            }
            : {};
    };

    return (
        <Card
            sx={{
                padding: 7,
                height: showOnDashboard ? '100%' : undefined,
            }}
        >
            <Box display="flex" justifyContent="space-between" mb={4.5}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="baseline"
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '1.5rem',
                            fontWeight: '600',
                            lineHeight: '1.5rem',
                        }}
                    >
                        {title}
                    </Typography>
                    {!!showAttention &&
                        !!attentionContentStatusFilterList &&
                        !!requiringAttentionPostsNumber && (
                            <Typography
                                variant="h4"
                                sx={{
                                    whiteSpace: 'pre-wrap',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.125rem',
                                    fontWeight: '600',
                                }}
                            >
                                {' - '}
                                <Typography
                                    variant="inherit"
                                    component="span"
                                    sx={{
                                        color: theme.palette.warning.main,
                                    }}
                                >
                                    {`${requiringAttentionPostsNumber} require attention`}
                                </Typography>
                            </Typography>
                        )}
                </Box>
                {showRedirectButton && !!totalPosts && (
                    <Button
                        component={Link}
                        to={createPath({resource: 'v1_Posts', type: 'list'})}
                        variant="contained"
                    >
                        View All Orders
                    </Button>
                )}
            </Box>
            <List
                disableSyncWithLocation
                actions={false}
                resource="v1_Posts"
                perPage={showOnDashboard ? 8 : 10}
                filter={{
                    ...filter,
                    ...filterForStatus,
                }}
                sort={sort}
                pagination={
                    showOnDashboard ? false : (
                        <Pagination rowsPerPageOptions={[10, 15]}/>
                    )
                }
                empty={<Empty/>}
                sx={{overflow: 'auto'}}
            >
                <Datagrid
                    bulkActionButtons={false}
                    rowClick="edit"
                    rowSx={postRowStyle}
                >
                    {children}
                </Datagrid>
            </List>
        </Card>
    );
};
