import {
    Identifier,
    SaveButton,
    useNotify,
    useRedirect,
    useRefresh,
    useUpdate,
} from "react-admin";
import { useFormContext } from "react-hook-form";

const UpdateButton = (props: UpdateButtonProps) => {
    const { resource, label, data, id, name, route, onSuccess } = props;
    const [update] = useUpdate();
    const { getValues, formState: { isValid } } = useFormContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const handleUpdate = (e: any) => {
        e.preventDefault();

        if (!isValid) {
            notify("adzz.notification.update.error", {
                messageArgs: {name},
                type: "error",
            });
            return
        }

        const formData = { ...getValues(), ...data };
        update(
            resource,
            { id, data: formData },
            {
                onSuccess: () => {
                    if (onSuccess) {
                        onSuccess(formData);
                    }
                    notify("adzz.notification.update.success", {
                        messageArgs: {name},
                        type: "success"
                    });
                    refresh();
                    if (route) {
                        redirect(`${route}`);
                    }
                },
                onError: () => {
                    notify("adzz.notification.update.error", {
                        messageArgs: {name},
                        type: "error",
                    });
                },
            }
        );
    };
    return (
        <SaveButton
            label={label}
            alwaysEnable={true}
            onClick={(e) => handleUpdate(e)}
            type="button"
            variant="contained"
            color="secondary"
            icon={<></>}
            sx={{ color: "#fafafa", ml: 4, textTransform: "uppercase" }}
        />
    );
};
export default UpdateButton;
type UpdateButtonProps = {
    resource: string;
    label: string;
    data: any;
    id: Identifier;
    name: string;
    route?: string;
    onSuccess?: (data: any) => void;
};
