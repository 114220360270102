import {Card, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Typography} from "@mui/material"
import React, {useState} from "react";
import {PaypalPayment} from "../checkout/paypal/paypalPayment";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {Button, useNotify} from "react-admin";
import {COLORS} from "../../../config/constants";

export const BillingTopUp = () => {
    const notify = useNotify();
    const [openDialog, setOpenDialog] = useState(false);
    const [topUpAmount, setTopUpAmount] = useState<number>(0);
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    if (isIdentityLoading || !identity) return <CircularProgress/>

    const handleWheel = (e: any) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
            e.target.focus()
        }, 0)
    };
    const handleClose = (_: any, reason: string) => {
        if (reason && reason === 'backdropClick') return;
        setOpenDialog(false);
    };
    return (
        <Card
            sx={{
                gap: 2,
                padding: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography
                fontSize={22}
                fontWeight={'600'}
            >
                Top Up Account
            </Typography>
            <TextField
                sx={{fontSize: 20, fontWeight: 900}}
                placeholder="set amount here.."
                size="small"
                type="number"
                value={topUpAmount}
                inputProps={{onWheel: handleWheel}}
                onChange={(event) => {
                    const value = parseInt(event.target.value);
                    if(value < 0) {
                        setTopUpAmount(0);
                        return;
                    }
                    setTopUpAmount(value);
                }}
            />
            <Button
                label="Top Up"
                sx={{
                    padding: "0px 18px",
                    width: "180px",
                    height: "40px",
                    fontSize: 14,
                    background: COLORS.LIGHT_BLUE,
                    color: COLORS.WHITE,
                    "&:hover": {
                        backgroundColor: COLORS.BLUE,
                    },
                }}
                onClick={() => {
                    if (!topUpAmount) {
                        notify('Specify the amount to top up your account!', {type: 'error'})
                        return;
                    }
                    setOpenDialog(!openDialog)
                }}
            />
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogContent>
                    <PaypalPayment
                        totalPayment={topUpAmount}
                        userId={identity.id as number}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        label="CANCEL"
                        sx={{border: "none", color: "#000000"}}
                        fullWidth
                        onClick={() => setOpenDialog(false)}
                    />
                </DialogActions>
            </Dialog>
        </Card>
    )
}