import {Card, Dialog, DialogActions, DialogContent, DialogContentText, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {
    Button,
    Datagrid,
    DateField,
    List,
    Loading,
    ReferenceField,
    TextField,
    useGetList,
    useNotify,
    useRecordContext,
    useRedirect,
    useRefresh,
    useUpdate
} from "react-admin";
import {StatusField} from "../../general/statusField";
import {MARKETPLACE_ORDER_STATUS_CHIP_MAP} from "../../general/constants";
import {CheckoutPostDetailForm} from "./checkoutPostDetailForm";
import {CHECKOUT_STATUS, MARKETPLACE_STATUS} from "../../../config/statuses";
import {safeUpdate} from "../../../utils/safeUpdate";
import {COLORS, STATE_CODE} from "../../../config/constants";

export const CheckoutContentDetailForm = () => {
    const checkout = useRecordContext();

    const {data: checkoutDetails, isLoading: isDetailsLoading} = useGetList(
        'v1_CheckoutDetail',
        {
            filter: {
                checkout_id: checkout?.id,
                "Post#marketplacestatus_id@_eq": MARKETPLACE_STATUS.PROVIDE_DETAILS
            },
        },
        {enabled: !!checkout?.id})

    if (!checkout || isDetailsLoading) return <Loading/>;

    const checkoutDetailNumber = checkoutDetails?.length;
    const checkoutId = Number(checkout?.id)
    const isShowMessage = localStorage.getItem('adzz-show-after-payment-window')?.toLowerCase() === 'true';

    return (
        <Card
            sx={{
                padding: 4,
            }}
        >
            {checkoutDetailNumber
                ? <ContentDetailList
                    checkoutId={checkoutId}
                    checkoutDetailNumber={checkoutDetailNumber}
                    isShowMessage={isShowMessage}
                />
                : <CompleteCheckout/>}
        </Card>
    )
}

const CompleteCheckout = () => {
    const checkout = useRecordContext();
    const [update] = useUpdate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleCompelete = async () => {
        safeUpdate(
            notify,
            update,
            'v1_Checkout',
            {
                id: checkout?.id,
                data: {
                    ...checkout,
                    status_id: CHECKOUT_STATUS.COMPLETED,
                    statecode: STATE_CODE.DISABLED
                },
                previousData: checkout,
            },
            {
                onSuccess: () => {
                    notify('adzz.resources.v1_Checkout.actions.complete.success', {type: "success"})
                    redirect('list', 'v1_Posts');
                    refresh();
                },
                onError: () =>
                    notify('adzz.resources.v1_Checkout.actions.complete.error', {type: "warning"}),
            }
        );
    }

    return (
        <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography
                variant="h3" mb={5}
            >
                All posts have been successfully processed, you can complete the checkout!
            </Typography>
            <Button
                label="COMPLETE CHECKOUT" sx={{
                padding: "0px 18px",
                width: "300px",
                height: "40px",
                fontSize: 14,
                background: COLORS.GREEN,
                color: COLORS.WHITE,
                "&:hover": {
                    backgroundColor: COLORS.DEEP_GREEN,
                },
            }}
                onClick={handleCompelete}
            />
        </Stack>
    );
}

const ContentDetailList = ({checkoutId, checkoutDetailNumber, isShowMessage}: {
    checkoutId: number,
    checkoutDetailNumber?: number,
    isShowMessage: boolean
}) => {

    const [showMessage, setShowMessage] = useState<boolean>(isShowMessage);

    const hidePopupMessage = () => {
        localStorage.setItem('adzz-show-after-payment-window', JSON.stringify(false));
        setShowMessage(false);
    }

    return (
        <div>
            <Typography variant="h3" mb={5}>
                Posts in This Checkout - {checkoutDetailNumber}
            </Typography>
            <Dialog
                open={showMessage} onClose={() => hidePopupMessage()} sx={{
                '.MuiPaper-root': {
                    minWidth: '25%',
                    background: 'linear-gradient(90deg, rgba(241, 241, 241, 1) 0%, rgba(254,254,254,1) 100%)'
                }
            }}
            >
                <DialogContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <DialogContentText sx={{fontSize: "18px", fontWeight: '600'}}>
                        {"Thank you for your purchase."}
                    </DialogContentText>
                    <DialogContentText sx={{fontSize: "18px", fontWeight: '600'}}>
                        {"Please fill in the content details."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'center', margin: 3}}>
                    <Button
                        variant="contained"
                        label="Dismiss"
                        color="info"
                        onClick={() => hidePopupMessage()}
                        sx={{backgroundColor: COLORS.GREY, borderRadius: 0, fontSize: "14px", paddingX: 5}}
                    />
                </DialogActions>
            </Dialog>
            <List
                resource="v1_CheckoutDetail"
                filter={{
                    checkout_id: checkoutId,
                    "Post#marketplacestatus_id@_eq": MARKETPLACE_STATUS.PROVIDE_DETAILS
                }}
                actions={false}
            >
                <Datagrid
                    bulkActionButtons={false}
                    expand={<CheckoutPostDetailForm/>}
                >
                    <TextField source="post_id" label="Post ID"/>
                    <ReferenceField
                        link={false}
                        source="post_id"
                        reference="v1_Posts"
                        label="Created On"
                    >
                        <DateField
                            source="CreatedOn"
                            locales="en-GB"
                        />
                    </ReferenceField>
                    <ReferenceField
                        link={false}
                        source="offer_id"
                        reference="v1_Offer"
                        label="Duration"
                    >
                        <ReferenceField
                            source="postduration_id"
                            reference="v1_PostDuration"
                        >
                            <TextField source="value"/>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField
                        link={false}
                        source="offer_id"
                        reference="v1_Offer"
                        label="Site/section"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        link={false}
                        source="post_id"
                        reference="v1_Posts"
                        label="Price"
                    >
                        <TextField source="advertiser_price"/>
                    </ReferenceField>
                    <ReferenceField
                        link={false}
                        source="post_id"
                        reference="v1_Posts"
                        label="Status"
                    >
                        <ReferenceField
                            label="Status"
                            source="marketplacestatus_id"
                            reference="v1_MarketplaceStatus"
                            link={false}
                        >
                            <StatusField chipMap={MARKETPLACE_ORDER_STATUS_CHIP_MAP} source="name"/>
                        </ReferenceField>
                    </ReferenceField>
                </Datagrid>
            </List>
        </div>
    )

}
