import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import {useRecordContext} from "react-admin";

export const MyOrders = () => {
  const { currentUser } = useRecordContext();
  const writer = currentUser.data;

  const amountFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <Card sx={{height: '100%'}}>
      <CardContent>
        <Stack>
          <Typography variant="h3">My Orders</Typography>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography color="text.secondary">Ordered Posts</Typography>
              <Typography variant="h1">
                {amountFormatter.format(writer.ordered.aggregate.count)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary">Waiting Approval</Typography>
              <Typography variant="h1" color="secondary.main">
                {amountFormatter.format(writer.waiting.aggregate.count)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography color="text.secondary">Declined</Typography>
              <Typography variant="h1">
                {amountFormatter.format(writer.declined.aggregate.count)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="text.secondary">Approved</Typography>
              <Typography variant="h1" color="secondary.main">
                {amountFormatter.format(writer.approved.aggregate.count)}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};
