import { ChipProps } from '@mui/material';
import { useRecordContext, useGetOne } from 'react-admin';
import { StatusChip } from '../statusChip';

const getChipProps = (status: number): Partial<Omit<ChipProps, 'label'>> => {
  switch (status) {
    case 100000003:
      return { variant: 'filled', color: 'success' };
    default:
      return { variant: 'filled', color: 'warning' };
  }
};

export const OutreachStatusField = () => {
  const post = useRecordContext();
  const { data } = useGetOne(
    'v1_PostsOutreachStatus',
    { id: post?.new_OutreachStatus },
    { enabled: !!post?.new_OutreachStatus }
  );
  const label = data?.value;
  if (!label) return null;
  return (
    <StatusChip
      {...getChipProps(post?.new_OutreachStatus)}
      label={label}
      sx={{ height: '20px' }}
    />
  );
};
