import {Box} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import {Button, Loading, useGetList, useNotify, useRedirect, useRefresh} from "react-admin";
import React, {useContext} from "react";
import {CartContext, CartItem} from "../../../../context/CartContext";
import {sendPostToApi} from "../../../../services/api";

export const ReturnToOrderReviewButton = ({checkoutId}: { checkoutId: number }) => {
    const {setNewItems} = useContext(CartContext);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const {data: checkoutDetails, isLoading: isDetailsLoading} = useGetList(
        'v1_CheckoutDetail',
        {
            filter: {checkout_id: checkoutId},
        },
        {enabled: !!checkoutId})

    if (isDetailsLoading) return <Loading/>;

    const handleClick = async () => {
        try {
            const response = await sendPostToApi('marketplace/checkouts/delete', {
                checkout_id: checkoutId,
            });
            const isSuccessResponse = response.data['success'];

            if (isSuccessResponse) {
                const newItems = checkoutDetails?.map((item): CartItem => ({
                    id: item.offer_id,
                    nicheId: item.niche_id,
                    stepId: item.step_id,
                    price: item.base_price,
                    nicheFee: item.niche_fee,
                    wordFee: item.word_fee,
                    total: item.price,
                    currency: '$',
                }));
                setNewItems(newItems || []);
                redirect('create', 'checkout');
                refresh();
            } else {
                notify('adzz.resources.v1_Checkout.actions.create.error', {type: "warning"})
            }
        } catch (error: unknown) {
            console.error(error);
            notify('adzz.resources.v1_Checkout.actions.create.error', {type: "warning"})
        }
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
            <WestIcon color="primary" fontSize="medium"/>
            <Button
                label="Back To Order Review" sx={{fontSize: 17}}
                onClick={handleClick}
            />
        </Box>
    )

}