import {AppLocationContext, ContainerLayout,} from '@react-admin/ra-navigation';
import MenuList from './MenuList';
import MyAppBar from './myAppBar';
import {UserMenu} from './userMenu';
import {useLocation} from 'react-router-dom';
import useGetPreferences from "../hooks/useGetPreferences";
import {NotificationContextProvider} from "../context/notificationContext";

const MyLayout = (props: any) => {
    const location = useLocation();
    useGetPreferences();

    return (
        <AppLocationContext>
            <NotificationContextProvider>
                <ContainerLayout
                    {...props}
                    menu={<MenuList isProfile={location.pathname.includes('/my-profile')}/>}
                    appBar={<MyAppBar/>}
                    userMenu={<UserMenu/>}
                    sx={{
                        '& > .RaContainerLayout-content': {
                            marginTop: 7,
                        },
                        '& > .MuiContainer-root': {
                            maxWidth: '95%'
                        },
                    }}
                />
            </NotificationContextProvider>
        </AppLocationContext>
    );
};

export default MyLayout;
