import {Grid} from "@mui/material";
import {Button, useNotify, useRefresh} from "react-admin";
import React, {useState} from "react";
import {sendPostToApi} from "../../../services/api";
import {MARKETPLACE_STATUS, PUBLISHER_POST_STATUS} from "../../../config/statuses";
import {useFormContext} from "react-hook-form";
import {useAddRecordEvent} from "../../../hooks/useAddRecordEvent";
import {v1_Posts} from "../../../../generated/types";

export const PostPublishButton = ({post}: { post: v1_Posts }) => {
    const {formState, getValues} = useFormContext();
    const addRecordEvent = useAddRecordEvent();
    const notify = useNotify();
    const refresh = useRefresh();
    const [proceedingPublish, setProceedingPublish] = useState(false)

    const handleReportPublished = async () => {
        const url = getValues('new_name');

        setProceedingPublish(true);

        const response = await sendPostToApi('marketplace/posts/publish', {
            post_id: post.id,
            url: url
        });
        const isSuccessResponse = response.data['success'];

        if (isSuccessResponse) {
            addRecordEvent('PublisherStatusChange', {
                id: post.id,
                PublisherStatus: PUBLISHER_POST_STATUS.PUBLISHED,
            });
            addRecordEvent('MarketplaceStatusChange', {
                id: post.id,
                marketplacestatus_id: MARKETPLACE_STATUS.PUBLISHED,
            });
            notify('adzz.resources.v1_Posts.actions.publish.success', {type: "success"})
            refresh()
        } else {
            setProceedingPublish(false);
            notify(response.data['message'], {type: "warning"})
        }
    }

    return (
        <Grid item xs={2}>
            <Button
                label="REPORT PUBLISHED"
                size="medium"
                fullWidth
                onClick={handleReportPublished}
                disabled={!formState.isValid || proceedingPublish}
                sx={{
                    background: "#4caf50",
                    color: "#ffffff",
                    "&:hover": {
                        backgroundColor: "#388e3c",
                    },
                }}
            />
        </Grid>)

}
