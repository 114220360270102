import {Filter, NullableBooleanInput} from 'react-admin';
import {Box, Typography} from "@mui/material";
import * as React from "react";
import {COLORS} from "../../../../config/constants";

interface BooleanFilterProps {
    source: string;
    label: string;
    width: number;
}

export default function NullableBooleanFilter({
                                                  source,
                                                  label,
                                                  width
                                              }: BooleanFilterProps) {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: {width},
                '.RaFilterForm-filterFormInput': {width: '100%'}
            }}
        >
            <Filter>
                <NullableBooleanInput
                    alwaysOn
                    source={source}
                    label={false}
                    InputProps={
                        {
                            startAdornment: <Typography
                                marginRight={3} style={{whiteSpace: 'nowrap', color: COLORS.GREY}}
                            >
                                {label}
                            </Typography>
                        }
                    }
                    fullWidth
                    sx={{
                        'legend': {width: 0},
                        '& input': {
                            caretColor: 'transparent',
                        },
                        margin: '0 !important'
                    }}
                />
            </Filter>
        </Box>
    )
        ;
}
