import {CartContext, CartItem} from "../../../../context/CartContext";
import React, {useContext, useState} from "react";
import {useGetList} from "react-admin";
import {CircularProgress, MenuItem, Select} from "@mui/material";

export const NicheInput = ({record, additionalPrice}: { record: CartItem, additionalPrice: number }) => {

    const {setNiche} = useContext(CartContext);
    const [currentNiche, setCurrentNiche] = useState(record.nicheId
        ? record.nicheId
        : -1)

    const {data: restrictedNiches, isLoading: isRestrictedNichesLoading} = useGetList(
        'v1_RestrictedNichesToOffer',
        {
            filter: {offer_id: record?.id},
        },
        {enabled: !!record?.id}
    );

    const {data: niches, isLoading: isNichesLoading} = useGetList(
        'v1_PostNiche',
        {
            filter: {'id@_in': restrictedNiches?.map(niche => niche.niche_id)},
        },
        {enabled: !!record?.id}
    );

    if (isRestrictedNichesLoading || isNichesLoading) return <CircularProgress/>

    const handleNicheChange = (event: any) => {
        setCurrentNiche(event.target.value);
        if (event.target.value === -1) {
            setNiche(record, null, 0);
        } else {
            setNiche(record, event.target.value, additionalPrice);
        }
    };

    return (
        <Select
            value={currentNiche}
            fullWidth
            size="small"
            onChange={handleNicheChange}
            placeholder="Select One"
        >
            <MenuItem key={-1} value={-1}>None</MenuItem>
            {niches?.map((niche: any) => (
                <MenuItem key={niche.id} value={niche.id}>{niche.name}</MenuItem>
            ))}
        </Select>
    );
}