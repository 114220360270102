import {useState, useContext} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
} from '@mui/material';
import {
    Button,
    Form,
    TextInput,
    required,
    useNotify,
    useRefresh,
} from 'react-admin';
import {DialogContext} from './dialogContext';
import {sendPostToApi} from '../../../../services/api';
import {apiErrorMessage, ensureHttpsSchema} from '../../../../utils/utils';

export function EditDialog() {
    const notify = useNotify();
    const refresh = useRefresh();
    const [isDisabled, setIsDisabled] = useState(false);
    const {editDialog, setEditDialog} = useContext(DialogContext);
    const {isOpen, id, url} = editDialog;

    const closeDialog = () => {
        setEditDialog({isOpen: false, url: null, id: null});
    };

    const editProject = async (dashboardCtx: any) => {
        try {
            setIsDisabled(true);
            const {url: newUrl} = dashboardCtx;
            const project = {
                url: ensureHttpsSchema(newUrl),
                id,
            };
            await sendPostToApi('marketplace/projects/edit', project);
            notify(`The "${newUrl}" project was successfully updated`, {
                type: 'success',
            });
            setIsDisabled(false);
            closeDialog();
            refresh();
        } catch (error: unknown) {
            const message = apiErrorMessage(error);
            notify(message, {type: 'error'});
            setIsDisabled(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={closeDialog} maxWidth="xs" fullWidth>
            <DialogTitle>Edit Project</DialogTitle>
            <DialogContent>
                <Box sx={{pt: '12px'}}>
                    <Form id="editProject" onSubmit={editProject}>
                        <TextInput
                            source="url"
                            label="Project URL"
                            validate={required()}
                            fullWidth
                            defaultValue={url}
                        />
                    </Form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    form="editProject"
                    label="Save"
                    variant="contained"
                    disabled={isDisabled}
                />
                <Button label="Close" onClick={closeDialog} />
            </DialogActions>
        </Dialog>
    );
}
