import {AutocompleteInput, ReferenceInput} from "react-admin";
import React from "react";
import {Grid} from "@mui/material";

export const ProfileFormWritterFields = (props: any) => {
    return (
        <Grid container spacing={2} rowSpacing={4} sx={{marginBottom: 5}}>
            <Grid item xs={6}>
                <ReferenceInput
                    source="language1_id"
                    reference="v1_new_languagesBase"
                    sort={{field: "new_name", order: "ASC"}}
                >
                    <AutocompleteInput
                        label="Primary Language"
                        optionText="new_name"
                        parse={(value) => (value === "" ? null : value)}
                        filterToQuery={(searchText) => ({new_name: searchText})}
                        fullWidth
                        size="small"
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceInput
                    source="language_id"
                    reference="v1_new_languagesBase"
                    sort={{field: "new_name", order: "ASC"}}
                >
                    <AutocompleteInput
                        label="Secondary Language"
                        optionText="new_name"
                        parse={(value) => (value === "" ? null : value)}
                        filterToQuery={(searchText) => ({new_name: searchText})}
                        fullWidth
                        size="small"
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
                <ReferenceInput
                    source="CountryID"
                    reference="v1_new_countryBase"
                    sort={{field: "new_name", order: "ASC"}}
                >
                    <AutocompleteInput
                        label="Country"
                        optionText="new_name"
                        parse={(value) => (value === "" ? null : value)}
                        filterToQuery={(searchText) => ({new_name: searchText})}
                        fullWidth
                        size="small"
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
        </Grid>
    );
};