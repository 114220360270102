import * as React from 'react';
import {NumberInput, NumberInputProps} from 'react-admin';

const NumberInputNoWheel = (props: NumberInputProps) => {
    const handleWheel = (e: any) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
            e.target.focus()
        }, 0)
    };

    return <NumberInput {...props} inputProps={{ onWheel: handleWheel }} />;
};

export default NumberInputNoWheel;
