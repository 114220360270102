import {UpdateManyParams, UpdateParams, UseUpdateManyMutateParams,} from 'react-admin';
import {MutateOptions} from 'react-query';

export const safeUpdate = (
    notifyFunction: any,
    updateFunction: any,
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options?:
        | (MutateOptions<
              any[],
              unknown,
              Partial<UseUpdateManyMutateParams>
          > & {})
        | undefined
) => {
    if (!resource || !params || !params.id) {
        notifyFunction(
            `An error has occurred! Please refresh the page and try again.`,
            {
                type: 'error',
            }
        );

        throw new Error('Missing required parameters');
    }

    updateFunction(resource, params, {...options, mutationMode: 'pessimistic'});
};

export const safeUpdateMany = (
    notifyFunction: any,
    updateManyFunction: any,
    resource: string | undefined,
    params: Partial<UpdateManyParams> | undefined,
    options?:
        | (MutateOptions<
              any[],
              unknown,
              Partial<UseUpdateManyMutateParams>
          > & {})
        | undefined
) => {
    if (!resource || !params || !params.ids || params.ids?.length === 0) {
        notifyFunction(
            `An error has occurred! Please refresh the page and try again.`,
            {
                type: 'error',
            }
        );

        throw new Error('Missing required parameters');
    }

    updateManyFunction(resource, params, {...options, mutationMode: 'pessimistic'});
};
