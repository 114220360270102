import {Box, Card, CircularProgress, Grid, Typography} from "@mui/material";
import React, {Dispatch, SetStateAction, useState} from "react";
import {Button, Loading, RaRecord, useGetList, useGetOne, useNotify, useRecordContext, useRefresh} from "react-admin";
import {COLORS, POST_DURATION_VALUES} from "../../../config/constants";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {PaypalPayment} from "./paypal/paypalPayment";
import {completePayment} from "./utils/competePayment";
import {useGetUserBalance} from "../../../hooks/useGetUserBalance";


export const CheckoutBillingForm = () => {
    const checkout = useRecordContext();
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);

    const {balance: walletBalance, isLoading: isBalanceLoading} = useGetUserBalance();

    const {data: checkoutDetails, isLoading: isDetailsLoading} = useGetList(
        'v1_CheckoutDetail',
        {
            filter: {checkout_id: checkout?.id},
        },
        {enabled: !!checkout?.id})

    if (!checkout || isDetailsLoading || isBalanceLoading) return <Loading/>;

    const total = checkoutDetails?.reduce((total, item) => total + item.price, 0);
    const totalPayment = walletBalance > total ? 0 : total - walletBalance;
    const checkoutDetailIds = checkoutDetails?.map(item => item.id);

    return (
        <Card
            sx={{
                padding: 4,
            }}
        >
            <Typography variant="h3" mb={5}>
                Summary
            </Typography>
            <Grid
                container
                xs={12}
                sx={{
                    borderBottom: '1px solid #e0e0e0',
                }}
            ></Grid>
            {checkoutDetails?.map((item, index) => (
                <CheckoutRow
                    index={index}
                    record={item}
                />
            ))}
            <WalletTotal total={total} walletBalance={walletBalance}/>
            <OrderTotal total={totalPayment}/>
            {!showPaymentOptions && <BillingPayment
                setShowPaymentOptions={setShowPaymentOptions} checkoutDetailIds={checkoutDetailIds}
                totalPayment={totalPayment}
            />}
            {showPaymentOptions && <PaypalPayment
                checkoutId={checkout.id}
                totalPayment={totalPayment}
                checkoutDetailIds={checkoutDetailIds}
                userId={checkout.user_id}
            />}
        </Card>
    )

}

const CheckoutRow = ({
                         index, record
                     }: {
    index: number,
    record
        :
        any
}) => {

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {
            id: record?.offer_id,
        },
        {enabled: !!record},
    );

    if (isOfferLoading) return <CircularProgress/>


    return (
        <Grid
            container
            xs={12}
            sx={{
                borderBottom: '1px solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                borderRight: '1px solid #e0e0e0',
            }}
        >
            <Grid
                item
                xs={11}
                p={2}
            >
                <Typography variant="body1" display="flex" color="primary">
                    {offer.name}
                </Typography>
                <OfferInformation offer={offer} stepId={record.step_id} nicheId={record.niche_id}/>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{
                    borderLeft: '1px solid #e0e0e0',
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body1" p={2} fontSize="14px">
                    ${record.price}
                </Typography>
            </Grid>
        </Grid>
    );
}

const BillingPayment = ({
                            checkoutDetailIds, totalPayment, setShowPaymentOptions
                        }:
                            {
                                checkoutDetailIds?: number[],
                                totalPayment
                                    :
                                    number,
                                setShowPaymentOptions
                                    :
                                    Dispatch<SetStateAction<boolean>>
                            }) => {
    const checkout = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [proceedingPayment, setProceedingPayment] = useState(false)

    const handlePayment = async () => {
        if (totalPayment) {
            setShowPaymentOptions(true);
        } else {
            setProceedingPayment(true);

            const isSuccessfulComplete = await completePayment(checkout?.id, checkoutDetailIds);

            if (isSuccessfulComplete) {
                notify('adzz.resources.v1_Checkout.actions.payment.success', {type: "success"})
                refresh()
            } else {
                setProceedingPayment(false);
                notify('adzz.resources.v1_Checkout.actions.payment.error', {type: "warning"})
            }
        }
    }

    return (
        <Grid
            container
            xs={12}
            mt={5}
        >
            <Grid item xs={12} display="flex" justifyContent="end">
                <Button
                    label="MAKE PAYMENT"
                    disabled={proceedingPayment}
                    sx={{
                        padding: "0px 18px",
                        width: "300px",
                        height: "40px",
                        fontSize: 14,
                        background: COLORS.LIGHT_BLUE,
                        color: COLORS.WHITE,
                        "&:hover": {
                            backgroundColor: COLORS.BLUE,
                        },
                    }}
                    onClick={handlePayment}
                />
            </Grid>
        </Grid>
    );
}

const WalletTotal = ({
                         total, walletBalance
                     }: {
    total: number,
    walletBalance
        :
        number
}) => {

    return (
        <Grid
            container
            xs={12}
            sx={{
                borderBottom: '1px solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                borderRight: '1px solid #e0e0e0',
            }}
        >
            <Grid
                item
                xs={11}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="center"
            >
                <Typography variant="body1" display="flex" fontWeight={700}>
                    Total
                </Typography>
                <Typography variant="body1" display="flex" fontSize={12} sx={{color: COLORS.GREEN}}>
                    Wallet Credit
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{
                    borderLeft: '1px solid #e0e0e0',
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body1" display="flex" fontWeight={700} fontSize="14px">
                    ${total}
                </Typography>
                <Typography variant="body1" display="flex" fontSize="14px">
                    - ${walletBalance > total
                    ? total
                    : walletBalance}
                </Typography>
            </Grid>
        </Grid>
    );
}

const OrderTotal = ({
                        total
                    }: {
    total: number
}) => {

    return (
        <Grid
            container
            xs={12}
            sx={{
                borderBottom: '1px solid #e0e0e0',
                borderLeft: '1px solid #e0e0e0',
                borderRight: '1px solid #e0e0e0',
            }}
        >
            <Grid
                item
                xs={11}
                p={2}
                py={4}
                display="flex"
                flexDirection="column"
                alignItems="start"
                justifyContent="center"
            >
                <Typography variant="body1" display="flex" fontWeight={600} color="primary" fontSize="20px">
                    Order Total
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                sx={{
                    borderLeft: '1px solid #e0e0e0',
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body1" display="flex" fontWeight={600} color="primary" fontSize="20px">
                    ${total}
                </Typography>
            </Grid>
        </Grid>
    );
}

const OfferInformation = ({
                              offer, stepId, nicheId
                          }: {
    offer: RaRecord,
    stepId
        :
        number | null,
    nicheId
        :
        number | null
}) => {

    const {data: steps, isLoading: isStepsLoading} = useGetList(
        'v1_LanguagePriceStep',
        {
            filter: {language_id: offer?.LanguageId},
            sort: {field: 'step', order: 'ASC'},
        },
        {enabled: !!offer?.LanguageId}
    );

    const {data: niches, isLoading: isNichesLoading} = useGetList(
        'v1_PostNiche',
    );

    if (isStepsLoading || isNichesLoading) return <Loading/>

    return (
        <Box
            display="flex"
            gap={2}
            sx={{
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                ".MuiTypography-body2": {fontWeight: "600"}
            }}
        >

            <Typography>{steps?.find(step => step.id === stepId)?.step} words</Typography>

            <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>
            {offer.do_follow && <LinkIcon fontSize="small"/>}
            {!offer.do_follow && <LinkOffIcon fontSize="small"/>}

            {offer?.IsHomepageLink && [
                <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>,
                <HomeOutlinedIcon fontSize="small" sx={{marginRight: 1}}/>
            ]}

            {nicheId && [
                <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>,
                <Typography>{niches?.find(niche => niche.id === nicheId)?.name}</Typography>
            ]}

            {offer?.postduration_id && [
                <FiberManualRecordIcon sx={{fontSize: "smaller"}}/>,
                <Typography sx={{marginRight: 1}}>{POST_DURATION_VALUES[offer.postduration_id]}</Typography>
            ]}
        </Box>)
}