import {Box, CircularProgress} from "@mui/material";
import {useRecordContext} from "react-admin";
import PageTitle from "../../../layout/PageTitle";
import CheckoutStatusLine from "./checkoutStatusLine";
import {CHECKOUT_STATUS, CHECKOUT_STATUS_VALUES} from "../../../config/statuses";

const CheckoutTitle = ({isCreate}: { isCreate: boolean }) => {
    const checkout = useRecordContext();

    if (!checkout && !isCreate) return <CircularProgress/>;

    return (
        <Box
            display="flex" justifyContent="space-between"
            sx={{
                width: "100%",
            }}
        >
            <PageTitle>Checkout
                - {isCreate
                    ? CHECKOUT_STATUS_VALUES[CHECKOUT_STATUS.ORDER_REVIEW]
                    : CHECKOUT_STATUS_VALUES[checkout.status_id]} </PageTitle>
            <CheckoutStatusLine isCreate={isCreate}/>
        </Box>
    );
};
export default CheckoutTitle;
