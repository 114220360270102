import {Box, Stack, Typography} from '@mui/material';
import {useRecordContext} from 'react-admin';
import {OnlineAssistant} from '../../common/dashboard/onlineAssistant';
import {PublisherDashboardOrderList} from './orders/list';
import {PublisherDashboardOfferList} from './offers/list';
import {Stats} from "./stats";
import {Payouts} from "./payouts";

export const PublisherDashboard = () => {
    const {currentUser} = useRecordContext();

    return (
        <Stack pb={8}>
            <Typography variant="h1" my={4}>
                Hi, {currentUser.data.FullName}
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4}>
                <Box gridColumn="span 15" gridRow="span 3">
                    <PublisherDashboardOrderList/>
                </Box>
                <Box gridColumn="span 3">
                    <Stats/>
                </Box>
                <Box gridColumn="span 3">
                    <Payouts/>
                </Box>
                <Box gridColumn="span 3">
                    <OnlineAssistant/>
                </Box>
                <Box gridColumn="span 18">
                    <PublisherDashboardOfferList/>
                </Box>
            </Box>
        </Stack>
    );
};
