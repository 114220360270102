import {
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import {useRef, useState} from 'react';
import {FilterProps} from './filters/filters';

const MenuFilterItem = ({filter, handleCheck}: any) => {

    return (
        <Box>
            <MenuItem
                dense
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    backgroundColor: filter.display
                        ? 'transparent'
                        : 'background.default',
                }}
            >
                <Typography display="flex" alignItems="center" gap={2}>
                    {filter.icon && (
                        <img src={filter.icon} alt={filter.label}/>
                    )}
                    {filter.label}
                </Typography>
                <Checkbox
                    checked={filter.display}
                    onChange={() => handleCheck(filter.name)}
                />
            </MenuItem>
            <Divider
                sx={{
                    margin: '0 !important',
                }}
            />
        </Box>
    );
};

interface MarketPlaceMenuFiltersProps {
    filters: Array<FilterProps>;
    setFilters: Function;
}

export const MarketPlaceMenuFilters = ({
                                           filters,
                                           setFilters,
                                       }: MarketPlaceMenuFiltersProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const setFilterDisplayValue = (callback: (filter: FilterProps) => boolean) => {
        const newFilters = filters.map((filter) => {
            filter.display = callback(filter);
            return filter;
        });
        setFilters(newFilters);
    };

    const handleCheck = (filterName: string) => {
        setFilterDisplayValue((filter) => filter.name === filterName ? !filter.display : filter.display);
    };

    const handleSelect = () => {
        setFilterDisplayValue((filter) => true);
    };

    const handleUnselect = () => {
        setFilterDisplayValue((filter) => false);
    };

    return (
        <Box>
            <Button
                ref={anchorRef}
                id="filter-menu-button"
                aria-controls={open ? 'filter-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                startIcon={<TuneIcon/>}
            >
                Edit Criteria
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                }}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start'
                                    ? 'left top'
                                    : 'left bottom',
                        }}
                    >
                        <Paper
                            elevation={3} sx={{
                            maxHeight: '400px',
                            overflow: 'auto'
                        }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="filter-menu"
                                    aria-labelledby="filter-button"


                                >
                                    <MenuItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1.1rem',
                                            }}
                                        >
                                            Edit Criteria
                                        </Typography>
                                        <IconButton onClick={handleClose}>
                                            <CloseIcon/>
                                        </IconButton>
                                        <IconButton onClick={handleSelect}>
                                            <CheckBoxOutlinedIcon/>
                                        </IconButton>
                                        <IconButton onClick={handleUnselect}>
                                            <CheckBoxOutlineBlankOutlinedIcon/>
                                        </IconButton>
                                    </MenuItem>
                                    <Divider sx={{margin: '0 !important'}}/>

                                    {filters.map((filter) => (
                                        <MenuFilterItem
                                            key={filter.name}
                                            filter={filter}
                                            handleCheck={handleCheck}
                                        />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};
