import React, {useState} from 'react';
import {Box, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {FileField, FileInput, Form, Loading, useNotify} from 'react-admin';
import {sendPostToApi} from '../../../../services/api';
import type {ImportCsvDialogContentProps} from './types';
import {OFFER_CSV_TEMPLATE_LINK, ROUTES} from '../../../../config/constants';
import {nhost} from "../../../../lib/nhost";
import {Link} from 'react-router-dom';
import LanguageNotificationBox from './languageNotificationBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export function ImportCsvDialogContent({
                                           setResult,
                                       }: ImportCsvDialogContentProps) {
    const notify = useNotify();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const nhostUser = nhost.auth.getUser();

    if (!nhostUser) return <Loading/>;

    const importCsvHandler = async ({file, supplier_id}: any) => {
        try {
            setIsSubmitDisabled(true);
            const formData = new FormData();
            const TMP_FILE_FIELD = 'csv_file';
            formData.append(TMP_FILE_FIELD, file.rawFile);
            formData.append('supplier_id', String(nhostUser.metadata.crm_userid));
            formData.append('user_id', '1');
            const response = await sendPostToApi(
                ROUTES.OFFER_IMPORT_CSV,
                formData
            );
            setResult(response.data);
        } catch (error: any) {
            notify(error?.message, {type: 'error'});
        } finally {
            setIsSubmitDisabled(false);
        }
    };
    return (
        <>
            <DialogTitle>Import CSV</DialogTitle>
            <DialogContent>
                <Box display={'flex'} flexDirection={'row'} gap={2} mb={2}>
                    <Button
                        sx={{
                            lineHeight: 1.5,
                            boxShadow: 2,
                            gap: 0.5,
                            textAlign: 'center',
                        }}
                        component={Link}
                        to={OFFER_CSV_TEMPLATE_LINK}
                        target="_blank"
                    >
                        <Typography sx={{fontSize: 13}}>CSV Template</Typography>
                    </Button>
                    <LanguageNotificationBox/>
                </Box>
                <Form onSubmit={importCsvHandler} id="importCsvForm">
                    <FileInput source="file" accept=".csv" removeIcon={DeleteForeverIcon}>
                        <FileField source="src" title="title"/>
                    </FileInput>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    form="importCsvForm"
                    disabled={isSubmitDisabled}
                >
                    Import Offers
                </Button>
            </DialogActions>
        </>
    );
}
