import {Box, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {styled} from '@mui/system';

import {useContext} from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {NotificationModal} from './notificationModal';
import {NotificationContext} from "../../context/notificationContext";
import {COLORS} from "../../config/constants";

const tooltipStyles = {
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: COLORS.DARK_GRAY,
        boxShadow:
            '0px 4px 18px 3px rgba(0, 0, 0, 0.12), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.20)',
        maxWidth: 500,
        borderRadius: '8px',
        padding: 0,
    },
    '& .MuiTooltip-arrow': {
        color: COLORS.DARK_GRAY,
    },
};

const NotificationTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip arrow {...props} classes={{popper: className}}>
        {props.children}
    </Tooltip>
))(tooltipStyles);

export const NotificationButton = () => {
    const {
        unreadNotificationsCounter,
        handleToggleNotificationModal,
        isNotificationModalOpen,
        handleCloseNotificationModal,
    } = useContext(NotificationContext);

    return (
        <NotificationTooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleCloseNotificationModal}
            open={isNotificationModalOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement='top-start'
            title={<NotificationModal />}
        >
            <Box
                sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={handleToggleNotificationModal}
            >
                {Number(unreadNotificationsCounter) > 0 && (
                    <Box
                        sx={{
                            width: '14px',
                            height: '14px',
                            borderRadius: '50%',
                            bgcolor: COLORS.RED,
                            position: 'absolute',
                            right: '3px',
                            top: '2px',
                        }}
                    >
                        <Typography
                            sx={{
                                color: COLORS.WHITE,
                                fontSize: 10,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            {unreadNotificationsCounter}
                        </Typography>
                    </Box>
                )}
                <NotificationsIcon
                    sx={{
                        height: '40px',
                        width: '40px',
                        padding: '8px',
                    }}
                />
            </Box>
        </NotificationTooltip>
    );
};
