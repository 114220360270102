import {Box, Stack, Typography} from '@mui/material';
import {useRecordContext} from 'react-admin';
import {OnlineAssistant} from '../../common/dashboard/onlineAssistant';
import {Projects} from './projects/projects';
import {LatestOrdersList} from './orders/latestOrdersList';
import {LinksBought} from './linksBought/linksBought';
import {LinksStatus} from './linksStatus/linksStatus';

export const AdvertiserDashboard = () => {
    const {currentUser} = useRecordContext();
    return (
        <Stack pb={8}>
            <Typography variant="h1" my={4}>
                Hi, {currentUser.data.FullName}
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4.5}>
                <Box gridColumn="span 15" gridRow="span 3">
                    <LatestOrdersList />
                </Box>
                <Box gridColumn="span 3">
                    <LinksBought />
                </Box>
                <Box gridColumn="span 3">
                    <LinksStatus />
                </Box>
                <Box gridColumn="span 3">
                    <OnlineAssistant />
                </Box>
                <Box gridColumn="span 18">
                    <Projects />
                </Box>
            </Box>
        </Stack>
    );
};
