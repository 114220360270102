import {
    Step,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    StepLabel,
    Stepper,
    styled,
    SvgIcon,
    Typography,
} from '@mui/material';
import {FunctionField, ReferenceField} from 'react-admin';
import StatusOrderedIcon from '../../../layout/icons/StatusOrderedIcon';
import StatusWaitingApprovalIcon from '../../../layout/icons/StatusWaitingApprovalIcon';
import StatusApprovedIcon from '../../../layout/icons/StatusApprovedIcon';
import {CHECKOUT_STATUS, CHECKOUT_STATUS_VALUES} from '../../../config/statuses';
import StatusDeclinedIcon from '../../../layout/icons/StatusDeclinedIcon';
import StatusBillingIcon from "../../../layout/icons/StatusBillingIcon";

const STEPS = [
    CHECKOUT_STATUS.ORDER_REVIEW,
    CHECKOUT_STATUS.BILLING,
    CHECKOUT_STATUS.CONTENT_DETAILS,
    CHECKOUT_STATUS.COMPLETED,
];

const StatusStepperConnector = styled(StepConnector)(({theme}) => ({
    minWidth: 20,
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: '6px',
        border: 0,
        backgroundColor: theme.palette.grey[400],
        borderRadius: 1,
    },
}));

const StatusStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean; error?: boolean };
}>(({theme, ownerState}) => ({
    zIndex: 1,
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    ...(ownerState.active && {
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.background.paper}`,
        color: 'white',
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    }),
    ...(ownerState.error && {
        backgroundColor: theme.palette.warning.main,
        border: `2px solid ${theme.palette.background.paper}`,
        color: theme.palette.primary.main,
    }),
}));

function StatusStepIcon(props: StepIconProps) {
    const {active, completed, error, className} = props;
    const errorIcon = (
        <SvgIcon
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 20,
                height: 21,
            }}
        >
            <StatusDeclinedIcon/>
        </SvgIcon>
    );

    const icons: { [index: string]: React.ReactElement } = {
        1: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusOrderedIcon/>
            </SvgIcon>
        ),
        2: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusBillingIcon/>
            </SvgIcon>
        ),
        3: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusWaitingApprovalIcon/>
            </SvgIcon>
        ),
        4: (
            <SvgIcon
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 20,
                    height: 21,
                }}
            >
                <StatusApprovedIcon/>
            </SvgIcon>
        ),
    };

    return (
        <StatusStepIconRoot
            ownerState={{completed, active, error}}
            className={className}
        >
            {error ? errorIcon : icons[String(props.icon)]}
        </StatusStepIconRoot>
    );
}

function getActiveStep(value: any) {
    return STEPS.findIndex((step) => step === value);
}

const StatusStepper = ({value}: any) => {
    const indexActiveStep = getActiveStep(value);
    return (
        <Stepper
            activeStep={indexActiveStep}
            connector={<StatusStepperConnector/>}
        >
            {STEPS.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={StatusStepIcon}
                            sx={{
                                '& .MuiStepLabel-iconContainer': {
                                    padding: 0,
                                },
                                '& .MuiStepLabel-label': {
                                    paddingX: 1,
                                },
                            }}
                        >
                            {index === indexActiveStep && (
                                <Typography color="primary" fontWeight={600}>
                                    {CHECKOUT_STATUS_VALUES[label]}
                                </Typography>
                            )}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

const CheckoutStatusLine = ({isCreate}: {isCreate: boolean}) => {
    return (
        isCreate
            ? <StatusStepper value={CHECKOUT_STATUS.ORDER_REVIEW}/>
            : <ReferenceField
                label="Status"
                source="status_id"
                reference="v1_CheckoutStatus"
            >
                <FunctionField
                    render={(record: any) => <StatusStepper value={record.id}/>}
                />
            </ReferenceField>
    );
};
export default CheckoutStatusLine;
