import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Modal, Typography} from '@mui/material';
import React from 'react';
import {useGetList} from 'react-admin';
import {COLORS} from "../../../../config/constants";

type LanguageCodeModalProps = {
    open: boolean;
    onClose: () => void;
};
const LanguageCodeModal = ({open, onClose}: LanguageCodeModalProps) => {
    const {data: languageData} = useGetList(
        'v1_new_languagesBase',
        {
            pagination: {
                page: 1,
                perPage: 10000,
            },
        },
        {}
    );

    const languageList = languageData
        ?.filter((entity) => entity['new_2LettersLanguageCode'])
        .map((p) => {
            return {
                code: p['new_2LettersLanguageCode'],
                name: p['new_name'],
            };
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

    const handleClose = (_: any, reason: string) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    bgcolor: COLORS.WHITE,
                    width: '300px',
                    height: '85%',
                    boxShadow: 1,
                    borderRadius: 1,
                    p: 1,
                    boxSizing: 'border-box',
                    overflowY: 'scroll',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Typography variant="h5" mb={2} textTransform="uppercase" textAlign="center">
                    Languages list
                </Typography>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="row" gap={5}>
                        <Box width={60} mb={2}>
                            <Typography>Code</Typography>
                        </Box>
                        <Typography width={100}>Name</Typography>
                    </Box>

                    {languageList?.map((lang) => {
                        return (
                            <Box display="flex" flexDirection="row" gap={5} key={lang.code}>
                                <Box width={60}>
                                    <Typography>{lang.code.split(',').join(', ')}</Typography>
                                </Box>
                                <Typography width={100}>{lang.name}</Typography>
                            </Box>
                        );
                    })}
                </Box>
                <IconButton
                    sx={{
                        p: 0.2,
                        position: 'absolute',
                        top: 2,
                        right: 2,
                    }}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Modal>
    );
};

export default LanguageCodeModal;
