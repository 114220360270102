import {CircularProgress, Stack} from '@mui/material';
import {useCanAccess} from '@react-admin/ra-rbac';
import {LoadingIndicator} from 'react-admin';
import Cart from './Cart/Cart';
import {MyDivider} from './myDivider';
import React from "react";
import {useGetActiveCheckout} from "../hooks/useGetActiveCheckout";
import {ActiveCheckoutButton} from "../components/advertiser/checkout/buttons/activeCheckoutButton";
import {BalanceButton} from "./balanceButton";
import {ROLE_ADVERTISER} from "../models/role-model";
import {useGetUserBalance} from "../hooks/useGetUserBalance";
import {NotificationButton} from "./notifications/notificationButton";

export const MyToolbar = ({role}: { role?: string }) => {
    const {canAccess: canAccessCart} = useCanAccess({
        action: 'read',
        resource: 'cart',
    });

    const {checkout: activeCheckout, isLoading: isActiveCheckoutLoading} = useGetActiveCheckout();
    const {balance, isLoading: isBalanceLoading} = useGetUserBalance();

    if (isActiveCheckoutLoading || isBalanceLoading) return <CircularProgress/>;

    const hasActiveCheckout = !!activeCheckout;

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            {/*<AlertsButton/>*/}
            {(canAccessCart && !hasActiveCheckout) && <Cart/>}
            {hasActiveCheckout && <ActiveCheckoutButton/>}
            {role === ROLE_ADVERTISER && <BalanceButton balance={balance}/>}
            <MyDivider/>
            <NotificationButton />
            {/* @ts-ignore TS type doesn't include size although it is supported */}
            <LoadingIndicator size={16} sx={{margin: '0 !important'}}/>
        </Stack>
    );
};
