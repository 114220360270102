import {Button} from "react-admin";
import React, {useState} from "react";
import {Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import {COLORS} from "../../../config/constants";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useTranslate} from "ra-core";

export const ProfileFormSecurity = (props: any) => {
    const [visibleNew, setVisibleNew] = useState(false);
    const [visibleOld, setVisibleOld] = useState(false);
    const translate = useTranslate();

    const handleClickNew = () => {
        setVisibleNew(!visibleNew);
    };
    const handleClickOld = () => {
        setVisibleOld(!visibleOld);
    };

    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={9}>
                <TextField
                    label="Old password"
                    fullWidth
                    type={visibleNew ? 'text' : 'password'}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={translate(
                                        visibleNew
                                            ? "ra.input.password.toggle_visible"
                                            : "ra.input.password.toggle_hidden"
                                    )}
                                    onClick={handleClickNew}
                                    size="small"
                                >
                                    {visibleNew ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Button label="Check"
                        fullWidth
                        sx={{
                            borderRadius: "16px",
                            backgroundColor: COLORS.LIGHT_BLUE,
                            color: COLORS.WHITE,
                            fontSize: "14px",
                            "&:hover": {
                                backgroundColor: COLORS.BLUE,
                            },
                        }}/>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    label="New password"
                    fullWidth
                    type={visibleOld? 'text' : 'password'}
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={translate(
                                        visibleOld
                                            ? "ra.input.password.toggle_visible"
                                            : "ra.input.password.toggle_hidden"
                                    )}
                                    onClick={handleClickOld}
                                    size="small"
                                >
                                    {visibleOld ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>)
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Button label="Apply"
                        fullWidth
                        sx={{
                            borderRadius: "16px",
                            backgroundColor: COLORS.GREEN,
                            color: COLORS.WHITE,
                            fontSize: "14px",
                            "&:hover": {
                                backgroundColor: COLORS.DEEP_GREEN,
                            },
                        }}/>
            </Grid>
        </Grid>
    );
};