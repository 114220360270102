import {CircularProgress, IconButton} from '@mui/material';
import {FunctionField, useGetList, useNotify, useRecordContext} from 'react-admin';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {sendPostToApi} from "../../../../services/api";
import {useEffect, useState} from "react";

const LikeField = ({label, userId}: { label: string, source: string, userId?: string | number }) => {
    const [liked, setLiked] = useState(false);
    const offer = useRecordContext();
    const notify = useNotify();

    const {data: likes, isLoading: isLikesLoading} = useGetList(
        "v1_UsersToOffers",
        {
            filter: {user_id: userId, offer_id: offer?.id},
        },
        {enabled: !!offer?.id, refetchOnMount: true});

    useEffect(() => {
        setLiked(!!likes?.length);
    }, [likes]);

    if (isLikesLoading || !offer) return <CircularProgress/>

    const handleClick = async (event: any) => {
        try {
            await sendPostToApi('marketplace/offers/change-like', {
                user_id: userId,
                offer_id: offer?.id,
                value: !liked,
            });
            setLiked(!liked);
        } catch (error: unknown) {
            console.error(error);
            notify('adzz.resources.v1_Offer.actions.changeLike.error', {type: "warning"})
        }
    };

    return (
        <FunctionField
            label={label}
            render={() => {
                return (
                    <IconButton
                        aria-label="like"
                        onClick={handleClick}
                        color="primary"
                    >
                        {liked ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                    </IconButton>
                );
            }}
        />
    );
};

export default LikeField;
