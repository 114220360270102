import {PUBLISHER_POST_STATUS} from '../../../../config/statuses';
import {
    Datagrid,
    DateField,
    List,
    Loading,
    NumberField,
    Pagination,
    ReferenceField,
    useCreatePath,
    useGetIdentity,
    useGetList,
    WrapperField,
} from 'react-admin';
import {Box, Card, Typography, useTheme} from '@mui/material';
import {EmptyList} from '../../../general/emptyList';
import {ListHeader} from '../../../general/listHeader';
import {StatusField} from '../../../general/statusField';
import {PUBLISHER_ORDER_STATUS_CHIP_MAP} from '../../../general/constants';
import {LinkField} from "../../../general/linkField";
import {v1_Offer} from "../../../../../generated/types";
import React from "react";
import {TooltipLinkField} from "../../../general/tooltipLinkField";

export const PublisherDashboardOrderList = () => {
    const theme = useTheme();
    const {data: identity} = useGetIdentity();
    const createPath = useCreatePath();
    const publisherId = identity?.id;

    const orderFilter = {
        supplier_id: publisherId,
        'PublisherStatus@_in': [
            PUBLISHER_POST_STATUS.TO_PUBLISH,
            PUBLISHER_POST_STATUS.APPROVE_ORDER,
        ],
    };

    const {
        total: orderCount,
        isLoading: isOrdersLoading,
    } = useGetList('v1_Posts', {
        filter: {...orderFilter},
    });

    if (!publisherId || isOrdersLoading) return <Loading/>;

    return (
        <Card
            sx={{
                padding: 7,
                height: '100%',
            }}
        >
            <ListHeader
                title="Orders"
                attentionText="require attention"
                attentionCount={Number(orderCount)}
                orderCount={Number(orderCount)}
                buttonText="View All Orders"
                link={createPath({resource: 'v1_Posts', type: 'list'})}
            />
            <List
                disableSyncWithLocation
                actions={false}
                resource="v1_Posts"
                perPage={8}
                filter={{...orderFilter}}
                sort={{field: 'ordered_on', order: 'DESC'}}
                pagination={<Pagination rowsPerPageOptions={[8]}/>}
                empty={<EmptyList
                    titleText="You don't have any orders which require attention."
                    subtitleText="Add more offers to increase your chances of selling."
                    buttonText="CREATE OFFER"
                    link={createPath({resource: 'v1_Offer', type: 'create'})}
                />}
                sx={{overflow: 'auto'}}
            >
                <Datagrid
                    bulkActionButtons={false}
                    rowClick="edit"
                >
                    <ReferenceField
                        label="Order Status"
                        source="PublisherStatus"
                        reference="v1_PublisherPostStatus"
                        link={false}
                    >
                        <StatusField chipMap={PUBLISHER_ORDER_STATUS_CHIP_MAP} source="Status"/>
                    </ReferenceField>
                    <DateField
                        source="ordered_on"
                        label="Ordered On"
                        locales="en-GB"
                    />

                    <WrapperField label="Amount" sortBy="Offer.link_price">
                        <Box flexDirection="row" display="flex">
                            <Typography>$</Typography>
                            <ReferenceField
                                source="OfferId"
                                reference="v1_Offer"
                                link={false}
                                label="Amount"
                                emptyText={'0'}
                            >
                                <NumberField
                                    source="link_price"
                                />
                            </ReferenceField>
                        </Box>
                    </WrapperField>

                    <ReferenceField
                        source="OfferId"
                        reference="v1_Offer"
                        label="Site/Section"
                        link={false}
                    >
                        <TooltipLinkField
                            label="Site/Section"
                            source="name"
                        />
                    </ReferenceField>

                    <LinkField
                        source="new_TargetURL1"
                        label="Target URL"
                    />
                </Datagrid>
            </List>
        </Card>
    );
};

const stopPropagation = (e: any) => e.stopPropagation();
