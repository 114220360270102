import {Grid} from '@mui/material';
import {ProjectCard} from './card';
import type {ProjectListProps} from './types';

export function ProjectList({projects}: ProjectListProps) {
    return (
        <Grid container spacing={4}>
            {projects?.map((project) => {
                return (
                    <Grid item xs={3} key={project.id}>
                        <ProjectCard project={project} />
                    </Grid>
                );
            })}
        </Grid>
    );
}
