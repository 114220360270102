import {Box} from "@mui/material";
import React from "react";
import {BooleanInput} from "react-admin";
import WrapperLookAlikeMUI from "../publisher/offer/WrapperLookAlikeMUI";

export const BooleanFormInput = ({label, source}: { label: string, source: string }) => {

    return (
        <Box sx={{
            borderRadius: 1,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            ".MuiFormControlLabel-root": {
                marginRight: "0px",
            }
        }}
        >
            <WrapperLookAlikeMUI label={label}/>
            <BooleanInput
                source={source}
                label={false}
                size="small"
                helperText={false}
            />

        </Box>
    );
};
