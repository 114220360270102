import {Maybe} from '../../../../generated/types';
import {sendPostToApi} from '../../../services/api';

export const checkAndCreateWebsite = async (url: Maybe<string> | undefined) => {
    try {
        const response = await sendPostToApi('marketplace/websites/create', {
            url,
        });
        const isSuccessResponse = response.data['success'];
        if (isSuccessResponse) {
            return response.data['websiteId'];
        }
    } catch (error: unknown) {
        console.error(error);
    }
    return;
};
