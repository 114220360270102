export const POST_DURATION = {
    PERMANENT: 1,
    ONE_YEAR: 2,
};

export const STATE_CODE = {
    ENABLED: 0,
    DISABLED: 1,
};


export const POST_DURATION_VALUES = {
    [POST_DURATION.PERMANENT]: 'Permanent',
    [POST_DURATION.ONE_YEAR]: 'One year',
};

export const COLORS = {
    ORANGE: "#ff9800",
    DARK_ORANGE: '#CE6300',
    RED: "#b71c1c",
    DARK_RED: "#8b0000",
    WHITE: "#ffffff",
    BLACK: "#000000",
    GREEN: "#4caf50",
    DEEP_GREEN: "#328c36",
    SOFT_BLUE: '#D0F0FF',
    LIGHT_BLUE: "#03a9f4",
    BLUE: "#0276ab",
    LIGHT_GREY: "#f7f9fC",
    GREY: '#707070',
    AQUA: '#039be5',
    BLUE_GREEN: '#008080',
    CHESTNUT: '#CD5C5C',
    DARK_GRAY: '#303241',
    DARK_YELLOW: '#cc7436',
    DEEP_BROWN: '#541811',
    DEEP_YELLOW: '#b27d00',
    GOLD: '#FFD700',
    GRAY: '#818181',
    HOT_PINK: '#FF69B4',
    LIGHT_DARK_GRAY: '#414354',
    LIGHT_GRAY: '#F4F4F4',
    LIGHT_GREEN: '#90EE90',
    MATE_GRAY: '#EAEAEA',
    MAGENTA: '#8B008B',
    POWDER_BLUE: '#ADD8E6',
    TRANSPARENT_GRAY: '#00000099',
    VIOLET: '#5D4C6F',
    YELLOW_GREEN: '#4caf50',
    CRAYOLA: '#6699DA',
};

export const enum ROUTES {
    OFFER_IMPORT_CSV = 'marketplace/offers/import-csv',
    TOKENIZE = 'ai/tokenize',
}

export const enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export const enum NOTIFICATION_SOCKET_EVENTS {
    NEW_NOTIFICATION = 'new_notification',
}

export const SOCKET_EVENT = {
    READ: 'read_notification',
    DISABLE: 'disable_notification',
}

export const enum PAGE {
    EDIT = 'edit',
    CREATE = 'create',
    SHOW = 'show',
}

export const OFFER_CSV_TEMPLATE_LINK =
    'https://docs.google.com/spreadsheets/d/14o9pIKpXLbaHEhcl1XhPtNXtmxiu72wqpTZbJVrsjlQ';

export const MARKETPLACE_USER_ID = 189;
