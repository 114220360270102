import {SyntheticEvent, useState} from 'react';
import {Stack, Tab} from '@mui/material';
import PageTitle from '../../../layout/PageTitle';
import {ListItem} from './listItem';
import {DateField, DateInput, NumberField, ReferenceField, TextField, WrapperField,} from 'react-admin';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {ORDER_STATUS_VALUE} from '../../../config/statuses';
import {TextFieldWithTooltip} from '../../common/TextFieldWithTooltip';
import {IUserIdentity} from '../../../models/identity-model';
import {TooltipLinkField} from "../../general/tooltipLinkField";

enum TabValues {
    ORDERED = 'Ordered',
    DECLINED = 'Declined',
    WAITING_APPROVAL = 'Waiting Approval',
    APPROVED = 'Approved',
}

const getPostListFields = (tab: TabValues) => {
    const dateFileds = {
        [TabValues.ORDERED]: {source: 'ordered_on', label: 'Ordered On'},
        [TabValues.DECLINED]: {source: 'new_DeclineDate', label: 'Declined On'},
        [TabValues.WAITING_APPROVAL]: {
            source: 'new_SenttoApproval',
            label: 'Sent to Approval On',
        },
        [TabValues.APPROVED]: {
            source: 'new_DateApproved',
            label: 'Approved On',
        },
    };

    return [
        <ReferenceField
            label="Order ID"
            reference="v1_Posts"
            source="id"
            link="edit"
            key="id"
        >
            <TextField source="id" />
        </ReferenceField>,
        <DateField
            label={dateFileds[tab].label}
            source={dateFileds[tab].source}
            locales="en-GB"
            key="dateField"
        />,
        <DateField
            label="Due Date"
            source="new_Contentduedate"
            locales="en-GB"
            key="new_Contentduedate"
        />,
        <WrapperField
            label="Content Price"
            sortBy="new_ContentCost"
            key="new_ContentCost"
        >
            <ReferenceField
                reference="v1_TransactionCurrencyBase"
                source="currency_id"
            >
                <TextField source="CurrencySymbol" sx={{marginInlineEnd: 1}} />
            </ReferenceField>
            <NumberField source="new_ContentCost" />
        </WrapperField>,
        <WrapperField
            label="Upload Price"
            sortBy="new_UploadPrice"
            key="new_UploadPrice"
        >
            <ReferenceField
                reference="v1_TransactionCurrencyBase"
                source="currency_id"
            >
                <TextField source="CurrencySymbol" sx={{marginInlineEnd: 1}} />
            </ReferenceField>
            <NumberField source="new_UploadPrice" />
        </WrapperField>,
        <WrapperField
            label="Total Price"
            sortBy="comp_total_price_writer"
            key="comp_total_price_writer"
        >
            <ReferenceField
                reference="v1_TransactionCurrencyBase"
                source="currency_id"
            >
                <TextField source="CurrencySymbol" sx={{marginInlineEnd: 1}} />
            </ReferenceField>
            <NumberField source="comp_total_price_writer" />
        </WrapperField>,
        <ReferenceField
            label="Publisher Site"
            reference="v1_Website"
            source="website_id"
            link={false}
            key="website_id"
        >
            <TooltipLinkField
                label="Publisher Site"
                source="new_name"
            />
        </ReferenceField>,
        <ReferenceField
            label="Project"
            reference="v1_Project"
            source="_Project"
            link={false}
            emptyText="No Project"
            key="new_name"
        >
            <TextFieldWithTooltip
                source="new_name"
                sx={{
                    fontWeight: '600',
                }}
            />
        </ReferenceField>,
        <TextFieldWithTooltip
            label="H1 Title"
            source="new_Title"
            key="new_Title"
        />,
    ];
};

const approvedDateFilters = [
    <DateInput
        key="From"
        label="Approved From"
        alwaysOn
        source="new_DateApproved@_gte"
    />,
    <DateInput
        key="Until"
        label="Approved Until"
        alwaysOn
        source="new_DateApproved@_lte"
    />,
];

interface PostListProps {
    identity: IUserIdentity | undefined;
}

export const Lists = ({identity}: PostListProps) => {
    const [tabValue, setTabValue] = useState<TabValues>(TabValues.ORDERED);

    const handleTabChange = (_: SyntheticEvent, newValue: TabValues) => {
        setTabValue(newValue);
    };

    const filterForRole = {writer_id: identity?.id};

    return (
        <TabContext value={tabValue}>
            <Stack direction="row" spacing={2} alignItems="center" mb={7}>
                <PageTitle>Orders</PageTitle>
                <TabList onChange={handleTabChange} textColor="inherit">
                    <Tab value={TabValues.ORDERED} label={TabValues.ORDERED} />
                    <Tab
                        value={TabValues.DECLINED}
                        label={TabValues.DECLINED}
                    />
                    <Tab
                        value={TabValues.WAITING_APPROVAL}
                        label={TabValues.WAITING_APPROVAL}
                    />
                    <Tab
                        value={TabValues.APPROVED}
                        label={TabValues.APPROVED}
                    />
                </TabList>
            </Stack>
            <TabPanel
                value={TabValues.ORDERED}
                sx={{padding: 0, paddingBottom: 7}}
            >
                <ListItem
                    title={ORDER_STATUS_VALUE.ORDERED}
                    filterForRole={filterForRole}
                    statusToFilter={[ORDER_STATUS_VALUE.ORDERED]}
                    sort={{field: 'ordered_on', order: 'DESC'}}
                >
                    {getPostListFields(TabValues.ORDERED)}
                </ListItem>
            </TabPanel>
            <TabPanel
                value={TabValues.DECLINED}
                sx={{padding: 0, paddingBottom: 7}}
            >
                <ListItem
                    title={ORDER_STATUS_VALUE.DECLINED}
                    filterForRole={filterForRole}
                    statusToFilter={[ORDER_STATUS_VALUE.DECLINED]}
                    sort={{field: 'new_DeclineDate', order: 'DESC'}}
                >
                    {getPostListFields(TabValues.DECLINED)}
                </ListItem>
            </TabPanel>
            <TabPanel
                value={TabValues.WAITING_APPROVAL}
                sx={{padding: 0, paddingBottom: 7}}
            >
                <ListItem
                    title={ORDER_STATUS_VALUE.WAITING_APPROVAL}
                    filterForRole={filterForRole}
                    statusToFilter={[ORDER_STATUS_VALUE.WAITING_APPROVAL, ORDER_STATUS_VALUE.WAITING_APPROVAL_ADVERTISER]}
                    sort={{field: 'new_SenttoApproval', order: 'DESC'}}
                >
                    {getPostListFields(TabValues.WAITING_APPROVAL)}
                </ListItem>
            </TabPanel>
            <TabPanel
                value={TabValues.APPROVED}
                sx={{padding: 0, paddingBottom: 7}}
            >
                <ListItem
                    title={ORDER_STATUS_VALUE.APPROVED}
                    filterForRole={filterForRole}
                    statusToFilter={[ORDER_STATUS_VALUE.APPROVED]}
                    sort={{field: 'new_DateApproved', order: 'DESC'}}
                    listFilters={approvedDateFilters}
                >
                    {getPostListFields(TabValues.APPROVED)}
                </ListItem>
            </TabPanel>
        </TabContext>
    );
};
