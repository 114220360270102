import {SaveButton, SimpleForm,} from "react-admin";
import React from "react";
import OfferFormFields from "./offerFormFields";
import {Stack} from "@mui/material";

const OfferForm = (props: any) => {

    return (
        <SimpleForm toolbar={false}>
            <OfferFormFields/>
            <Stack mt={3}
                   direction="row"
                   justifyContent="flex-end"
                   alignItems="center"
                   width="100%">
                <SaveButton label="SUBMIT" icon={<></>} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 18px",
                    gap: "10px",
                    width: "252px",
                    height: "40px",
                    background: "#9E9E9E",
                    flex: "none",
                    order: "0",
                    flexGrow: "0",
                }}/>
            </Stack>
        </SimpleForm>
    );
};

export default OfferForm;
