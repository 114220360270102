import {
    Datagrid,
    DateField,
    Loading,
    ReferenceField,
    TextField,
    useGetOne,
    useRecordContext,
    WrapperField
} from "react-admin";
import {StatusField} from "../../general/statusField";
import {MARKETPLACE_ORDER_STATUS_CHIP_MAP} from "../../general/constants";
import React from "react";
import {v1_Posts} from "../../../../generated/types";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkIcon from "@mui/icons-material/Link";
import {Box} from "@mui/material";
import {TruncatedTooltipField} from "../../common/truncatedTooltipField";

const SiteSection = () => {

    const post = useRecordContext<v1_Posts>();

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {id: post?.OfferId},
        {enabled: !!post?.OfferId}
    );

    if (!post || isOfferLoading) return <Loading/>

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <ReferenceField
                source="OfferId"
                reference="v1_Offer"
                link={false}

            >
                <TruncatedTooltipField
                    source="name"
                />
            </ReferenceField>
            {offer?.IsHomepageLink &&
                <HomeOutlinedIcon fontSize="small"/>
            }
            {offer?.do_follow &&
                <LinkIcon fontSize="small"/>
            }
        </Box>
    )

}
export const PostListFields = () => {

    return (
        <Datagrid
            bulkActionButtons={false}
            rowClick="edit"
        >
            <WrapperField label="Site/section" sortBy="Offer.name">
                <SiteSection/>
            </WrapperField>
            <DateField
                label="Created On"
                source="CreatedOn"
                locales="en-GB"
                key="dateField"
            />
            <ReferenceField
                source="OfferId"
                reference="v1_Offer"
                link={false}
                label="Duration"
                sortBy="Offer.PostDuration.value"
            >
                <ReferenceField
                    source="postduration_id"
                    reference="v1_PostDuration"
                    link={false}
                >
                    <TextField
                        source="value"
                    />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                label="Status"
                source="marketplacestatus_id"
                reference="v1_MarketplaceStatus"
                link={false}
            >
                <StatusField chipMap={MARKETPLACE_ORDER_STATUS_CHIP_MAP} source="name"/>
            </ReferenceField>
            <TextField
                source="new_TargetURL1"
                label="Target URL"
            />
            <ReferenceField
                source="MarketplaceProjectId"
                reference="v1_MarketplaceProjects"
                label="Project"
            >
                <TextField source='url'/>
            </ReferenceField>
            <TextField
                source="new_RequestedMinWords"
                label="Words"
            />
            <TextField
                source="advertiser_price"
                label="Price"
            />
            <TextField source="id" label="Order ID"/>
        </Datagrid>
    );
};
