import {useContext} from 'react';
import {Button} from 'react-admin';
import {DialogContext} from './dialogContext';

export function AddProjectButton() {
    const {setIsAddDialogOpen} = useContext(DialogContext);
    return (
        <Button
            label="Add Project"
            variant="contained"
            onClick={() => setIsAddDialogOpen(true)}
        />
    );
}
