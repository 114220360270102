import React, {SyntheticEvent, useState} from "react";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {Stack, Tab} from "@mui/material";
import PageTitle from "../../../layout/PageTitle";
import {PostListCard} from "./postListCard";
import {Loading,} from "react-admin";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {MARKETPLACE_STATUS} from "../../../config/statuses";


export const PostList = () => {
    const [tabValue, setTabValue] = useState('all_posts');

    const handleTabChange = (_: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    if (isIdentityLoading) return <Loading/>

    const filterForRole = {AdvertiserId: identity?.id};

    return (
        <TabContext value={tabValue}>
            <Stack direction="row" spacing={2} alignItems="center" mb={7}>
                <PageTitle>My Posts</PageTitle>
                <TabList onChange={handleTabChange} textColor="inherit">
                    <Tab value="all_posts" label="All Posts"/>
                    <Tab value="requires_attention" label="Requires Attention"/>
                    <Tab value="in_progress" label="In Progress"/>
                    <Tab value="published" label="Published Posts"/>
                </TabList>
            </Stack>
            <TabPanel value="all_posts" sx={{padding: 0, paddingBottom: 7}}>
                <PostListCard
                    title="All Posts"
                    filter={filterForRole}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="requires_attention"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="Requires Attention"
                    filter={filterForRole}
                    statusFilterList={[MARKETPLACE_STATUS.WAITING_APPROVAL,
                        MARKETPLACE_STATUS.PROVIDE_DETAILS]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="in_progress"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="In Progress"
                    filter={filterForRole}
                    statusFilterList={[MARKETPLACE_STATUS.CONTENT_CREATION,
                        MARKETPLACE_STATUS.SENT_TO_PUBLISHER,
                        MARKETPLACE_STATUS.ORDER_PLACED]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="published"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="Published Posts"
                    filter={filterForRole}
                    statusFilterList={[MARKETPLACE_STATUS.PUBLISHED]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
        </TabContext>
    );
};
