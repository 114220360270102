import {Button, Stack, Typography} from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export const BalanceButton = ({balance}:{balance: number}) => {

    return (
        <Button
            variant="text"
            startIcon={
                <Typography color="primary.contrastText">
                    <AccountBalanceWalletIcon fontSize="small"/>
                </Typography>
            }
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <Typography color="primary.contrastText" variant="h6">
                    {formatter.format(balance)}
                </Typography>
            </Stack>
        </Button>
    );
};
