import CheckoutTitle from "./checkoutTitle";
import {Box, Card, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {Button, Identifier, useCreatePath, useGetIdentity, useGetOne, useNotify, useRedirect} from "react-admin";
import React, {useContext} from "react";
import {CartContext, CartItem} from "../../../context/CartContext";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {COLORS} from "../../../config/constants";
import {NicheInput} from "./unputs/nicheInput";
import {WordCountInput} from "./unputs/wordCountInput";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WestIcon from '@mui/icons-material/West';
import {Link} from "react-router-dom";
import {sendPostToApi} from "../../../services/api";
import {CHECKOUT_STATUS} from "../../../config/statuses";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const mapCartItemsToDetails = (cartItems: CartItem[]) => {
    return cartItems.map(item => {
        return {
            offer_id: item.id,
            niche_id: item.nicheId,
            base_price: item.price,
            niche_fee: item.nicheFee,
            word_fee: item.wordFee,
            price: item.total,
            step_id: item.stepId,
        };
    });
};

export const CheckoutCreate = () => {
    const createPath = useCreatePath();
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const {cartItems} = useContext(CartContext);

    if (isIdentityLoading) return <CircularProgress/>;

    const userId = identity?.id;

    return (
        <Stack gap={4}>
            <CheckoutTitle isCreate={true}/>
            <Card
                sx={{
                    padding: 4,
                }}
            >
                <Typography variant="h3">
                    Order Summary
                </Typography>
                <CheckoutHeader/>
                {cartItems.map((item, index) => (
                    <CheckoutRow
                        index={index}
                        record={item}
                    />
                ))}
                <CheckoutSubtotal/>
                <CheckoutSave userId={userId}/>
            </Card>

            <Box display="flex" alignItems="center">
                <WestIcon color="primary" fontSize="medium"/>
                <Button
                    label="Back To Marketplace" sx={{fontSize: 17}} component={Link}
                    to={createPath({resource: 'v1_Offer', type: 'list'})}
                />
            </Box>
        </Stack>
    )
}

const CheckoutSave = ({userId}: { userId: Identifier | undefined }) => {

    const {cartItems, deleteAllItems} = useContext(CartContext);
    const notify = useNotify();
    const redirect = useRedirect();

    const handleSave = async () => {
        try {
            const response = await sendPostToApi('marketplace/checkouts/create', {
                user_id: userId,
                status_id: CHECKOUT_STATUS.BILLING,
                details: mapCartItemsToDetails(cartItems)
            });
            const isSuccessResponse = response.data['success'];

            if (isSuccessResponse) {
                deleteAllItems();
                redirect('edit', 'checkout');
            } else {
                notify('adzz.resources.v1_Checkout.actions.create.error', {type: "warning"})
            }
        } catch (error: unknown) {
            console.error(error);
            notify('adzz.resources.v1_Checkout.actions.create.error', {type: "warning"})
        }
    }

    return (
        <Grid container>
            <Grid
                container
                xs={12}
                alignItems="center"
                mt={3}
            >
                <Grid
                    item
                    xs={4}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <WarningAmberIcon fontSize="large" sx={{color: COLORS.DARK_ORANGE}}/>
                    <Box>
                        <Typography
                            variant="body2" px={2} pb={1} fontSize={16} fontWeight={600}
                            sx={{color: COLORS.DARK_ORANGE}}
                        >
                            After placing your order, you will be able to add more details to include in the content.
                        </Typography>
                    </Box>

                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={3} display='flex' justifyContent='end'>
                    <Button
                        label="GO TO BILLING" sx={{
                        padding: "0px 18px",
                        width: "300px",
                        height: "40px",
                        fontSize: 14,
                        background: COLORS.LIGHT_BLUE,
                        color: COLORS.WHITE,
                        "&:hover": {
                            backgroundColor: COLORS.BLUE,
                        },
                    }}
                        onClick={handleSave}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

const CheckoutHeader = () => {
    return (
        <Grid
            container
            xs={12}
            sx={{
                borderBottom: '1px solid #e0e0e0',
            }}
        >
            <CheckoutHeaderColumn xs={4.5} label="Site/section"/>
            <CheckoutHeaderColumn xs={1.5} label="Restricted Niches"/>
            <CheckoutHeaderColumn xs={2} label="Article Word Count"/>
            <CheckoutHeaderColumn xs={1} label="Base Price"/>
            <CheckoutHeaderColumn xs={1} label="Niche Fee"/>
            <CheckoutHeaderColumn xs={1} label="Word Fee"/>
            <CheckoutHeaderColumn xs={1} label="Final Price"/>
        </Grid>
    )
}

const CheckoutSubtotal = () => {
    const {getSubTotalByCurrency} = useContext(CartContext);
    const subTotalByCurrency = getSubTotalByCurrency();

    if (Object.keys(subTotalByCurrency).length === 0) {
        return null;
    }

    return (
        <Grid container>
            {Object.keys(subTotalByCurrency).map((currency) => (
                <Grid
                    container
                    xs={12}
                    sx={{
                        borderBottom: '1px solid #e0e0e0',
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: '#f5f5f5',
                    }}
                    alignItems="center"
                    mt={1}
                >
                    <Grid
                        item
                        xs={11}
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography
                            variant="body1"
                            px={2}
                            pt={1}
                            fontSize={18}
                            color="primary"
                        >
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <Typography variant="body1" p={2} fontSize="18px" fontWeight="bold" color="primary">
                            {currency}
                            {subTotalByCurrency[currency].total}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

const CheckoutHeaderColumn = ({xs, label}: { xs: number, label: string }) => {
    return (<Grid
        item
        xs={xs}
    >
        <Typography variant="body1" p={2}>
            {label}
        </Typography>
    </Grid>)
}

const CheckoutRow = ({index, record}: {
    index: number,
    record: CartItem
}) => {

    const {deleteCartItem} = useContext(CartContext);

    const {data: offer, isLoading: isOfferLoading} = useGetOne(
        'v1_Offer',
        {
            id: record?.id,
        },
        {enabled: !!record},
    );

    if (isOfferLoading) return <CircularProgress/>

    const handleDeleteCartItem = (index: number) => {
        deleteCartItem(index);
    };

    return (
        <Grid
            container
            xs={12}
            sx={{
                borderBottom: '1px solid #e0e0e0',
            }}
        >
            <Grid
                item
                xs={4.5}
            >
                <Typography variant="body1" p={2} display="flex" gap={2}>
                    {offer.name}
                    {offer?.IsHomepageLink &&
                        <Typography
                            paddingX={2}
                            sx={{backgroundColor: COLORS.SOFT_BLUE}}
                            display="flex"
                            gap={1}
                        >
                            <HomeOutlinedIcon fontSize="small"/> Homepage Link
                        </Typography>}
                    {offer.do_follow && <LinkIcon fontSize="small"/>}
                    {!offer.do_follow && <LinkOffIcon fontSize="small"/>}
                </Typography>

            </Grid>
            <Grid
                item
                xs={1.5}
                padding={2}
            >
                <NicheInput
                    record={record}
                    additionalPrice={offer.additional_price_percentage}
                />
            </Grid>
            <Grid
                item
                xs={1.5}
                textAlign="right"
            >
                <WordCountInput languageId={offer?.LanguageId} record={record}/>
            </Grid>
            <Grid
                item
                xs={.5}
                sx={{
                    borderRight: '1px solid #e0e0e0',
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
            >
                <Button
                    sx={{minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                    onClick={() => handleDeleteCartItem(index)}
                    startIcon={<DeleteOutlineOutlinedIcon
                        sx={{color: COLORS.RED}}
                    />}
                ></Button>
            </Grid>
            <Grid
                item
                xs={1}
                textAlign="right"
                sx={{
                    borderRight: '1px solid #e0e0e0',
                }}
            >
                <Typography variant="body1" p={2} fontSize="16px">
                    ${record.price}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                textAlign="right"
                sx={{
                    borderRight: '1px solid #e0e0e0',
                }}
            >
                <Typography variant="body1" p={2} fontSize="16px">
                    ${record.nicheFee}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                textAlign="right"
                sx={{
                    borderRight: '1px solid #e0e0e0',
                }}
            >
                <Typography variant="body1" p={2} fontSize="16px">
                    ${record.wordFee}
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                textAlign="right"
                sx={{
                    borderRight: '1px solid #e0e0e0',
                }}
            >
                <Typography variant="body1" p={2} fontSize="16px" fontWeight="bold">
                    ${record.total}
                </Typography>
            </Grid>
        </Grid>
    );

}
