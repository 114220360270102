export interface IRole {
    Role: IRoleContent;
}

export interface IRoleContent {
    name: RoleName;
}

export const ROLE_WRITER = 'Writer';
export const ROLE_SUPPLIER = 'Supplier';
export const ROLE_ADVERTISER = 'Advertiser';
export const ROLE_PUBLISHER = 'Publisher';

export type RoleName = 'Writer' | 'Supplier' | 'Advertiser' | 'Publisher';

export interface IRolesConfig {
    config: any;
    id: number;
    name: string;
}
