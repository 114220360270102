import type {Dispatch, SetStateAction} from "react";
import React, {useEffect, useState} from "react";
import {Grid, Stack, Typography} from "@mui/material";
import {
    AutocompleteInput,
    ChipField,
    Labeled,
    Loading,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    SingleFieldList,
    TextField,
    useGetList,
    useNotify,
    useRecordContext,
} from "react-admin";
import {MARKETPLACE_STATUS} from "../../../config/statuses";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {Maybe, v1_Posts} from "../../../../generated/types";
import {useFormContext} from "react-hook-form";
import {sendPostToApi} from "../../../services/api";
import {ReferenceManyToManyField} from "@react-admin/ra-relationships";

const SEOTitleMaxlength = 64;
const SEODescriptionMaxlength = 160;

interface FormFieldsProps {
    record?: any;
    label?: string;
    validate?: any;
    onChange?: any;
    idx?: number;
}

interface TextEditorProps {
    setWordCount: Dispatch<SetStateAction<Maybe<number> | undefined>>;
    record: any;
}

const TextFieldStyles = {
    minHeight: "36px",
    padding: "8.5px 14px",
    backgroundColor: "#FFF",
    color: "text.primary",
    border: 1,
    borderColor: "border.color",
    borderRadius: 1,
    wordBreak: "break-word",
};

const PostFormFields = () => {
    const post = useRecordContext<v1_Posts>();
    const {setValue} = useFormContext();
    const notify = useNotify();
    const [wordCount, setWordCount] = useState<Maybe<number> | undefined>(post.new_wordcount);
    const [seoTitleCounter, setSeoTitleCounter] = useState(
        post?.new_SeoTitle?.length ?? 0,
    );
    const [seoDescriptionCounter, setSeoDescriptionCounter] = useState(
        post?.new_PostDesciption?.length ?? 0,
    );
    const [seoTitleLabel, setSeoTitleLabel] = useState("SEO Title");
    const [seoDescriptionLabel, setSeoDescriptionLabel] =
        useState("SEO Description");

    const marketplaceStatus = post?.marketplacestatus_id;

    const isWaitingApproval = marketplaceStatus === MARKETPLACE_STATUS.WAITING_APPROVAL;
    const isToPublish = marketplaceStatus === MARKETPLACE_STATUS.SENT_TO_PUBLISHER;
    const isPublished = marketplaceStatus === MARKETPLACE_STATUS.PUBLISHED;

    const handleChangeProject = async (projectId: number | null) => {
        try {
            const response = await sendPostToApi('marketplace/posts/update-project', {
                ids: [post.id],
                project_id: projectId
            });
            const isSuccessResponse = response.data['success'];

            notify(`adzz.resources.v1_Posts.actions.updateProject.${
                isSuccessResponse ? 'success' : 'error'
            }`, {type: isSuccessResponse ? "success" : 'warning'})

            if (!isSuccessResponse) {
                setValue('MarketplaceProjectId', post.MarketplaceProjectId)
            }
        } catch (error: unknown) {
            console.error(error);
            notify('adzz.resources.v1_Posts.actions.updateProject.error', {type: "warning"})
            setValue('MarketplaceProjectId', post.MarketplaceProjectId)
        }
    }

    useEffect(() => {
        setSeoTitleLabel(
            seoTitleCounter > 0
                ? `SEO Title (${seoTitleCounter}/${SEOTitleMaxlength})`
                : "SEO Title",
        );
    }, [seoTitleCounter]);

    useEffect(() => {
        setSeoDescriptionLabel(
            seoDescriptionCounter > 0
                ? `SEO Description (${seoDescriptionCounter}/${SEODescriptionMaxlength})`
                : "SEO Description",
        );
    }, [seoDescriptionCounter]);

    return (
        <>
            <Grid container flexDirection="column">

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <TargetUrl
                            idx={1}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnchorText
                            idx={1}
                        />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <TargetUrl
                            idx={2}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnchorText
                            idx={2}
                        />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <Labeled label="Project" fullWidth>
                            <ReferenceInput
                                source="MarketplaceProjectId"
                                reference="v1_MarketplaceProjects"
                                sort={{field: 'url', order: 'ASC'}}
                            >
                                <AutocompleteInput
                                    optionText="url"
                                    label={false}
                                    emptyText="Not Set"
                                    size="small"
                                    placeholder="Select one"
                                    filterToQuery={(searchText) => ({
                                        url: searchText,
                                    })}
                                    helperText={false}
                                    sx={{
                                        'legend': {width: '0'}
                                    }}
                                    onChange={(value: any) => {
                                        handleChangeProject(value ? value : null)
                                    }}
                                />
                            </ReferenceInput>
                        </Labeled>
                    </Grid>
                    <Grid item xs={6}>
                        <ArticleTones record={post}/>
                    </Grid>
                </Grid>

                <Grid mb={3}>
                    <Categories offer_id={post?.OfferId}/>
                </Grid>

                <Grid mb={3}>
                    <Instructions/>
                </Grid>

                <Grid mb={3}>
                    <Keywords/>
                </Grid>

                <Grid mb={3}>
                    <H1Title/>
                </Grid>
                <Grid mb={3}>
                    <SeoTitle
                        label={seoTitleLabel}
                    />
                </Grid>
                <Grid mb={3}>
                    <SeoDescription
                        label={seoDescriptionLabel}
                    />
                </Grid>

                {(isWaitingApproval || isPublished || isToPublish) &&
                    <Grid>
                        <ContentBody
                            setWordCount={setWordCount}
                            record={post}
                        />
                    </Grid>}
            </Grid>
        </>
    );
};

const Categories = ({offer_id}: { offer_id?: Maybe<number> }) => {

    const {data: categories, isLoading: isCategoriesLoading} = useGetList(
        'v1_MarketPlaceCategories_Offers',
        {
            filter: {offer_id: offer_id},
        },
        {enabled: !!offer_id})

    if (isCategoriesLoading) return <Loading/>

    const hasCategories = categories && categories.length > 0;

    return (
        hasCategories && <ReferenceField
            source="OfferId"
            reference="v1_Offer"
            link={false}
        >
            <ReferenceManyToManyField
                source="id"
                reference="v1_MarketPlaceCategories"
                through="v1_MarketPlaceCategories_Offers"
                using="offer_id,marketplace_category_id"
            >
                <Labeled label="Marketplace categories" fullWidth>
                    <SingleFieldList linkType={false}>
                        <ChipField source="CategoryName"/>
                    </SingleFieldList>
                </Labeled>
            </ReferenceManyToManyField>
        </ReferenceField>
    )
}

function H1Title() {
    return (
        <Labeled label="H1 Title" fullWidth>
            <Stack direction="row" spacing={{xs: 1, sm: 1}}>
                <TextField
                    source="new_Title"
                    emptyText="Not set"
                    sx={{...TextFieldStyles, width: "100%"}}
                />
            </Stack>
        </Labeled>
    );
}

function Instructions() {
    return (
        <Labeled label="Requested Details" fullWidth>
            <RichTextField
                source="requestDetails"
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function Keywords() {
    return (
        <Labeled label="Requested Keywords" fullWidth>
            <RichTextField
                source="new_Keywords"
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function ArticleTones({record}: FormFieldsProps) {

    if (record?.ArticleTone === null) {
        return (
            <Labeled label="Tone/Style of the Article" fullWidth>
                <Typography component="span" variant="body2" sx={TextFieldStyles}>
                    Not Set
                </Typography>
            </Labeled>
        );
    } else {
        return (
            <Labeled label="Tone/Style of the Article" fullWidth>
                <ReferenceField source="ArticleTone" reference="v1_ArticleTones">
                    <TextField
                        source="value"
                        sx={{...TextFieldStyles, display: "inline-grid", width: "100%"}}
                    />
                </ReferenceField>
            </Labeled>
        );
    }

}

function AnchorText({idx}: FormFieldsProps) {
    return (
        <Labeled label={`Requested anchor text (${idx})`} fullWidth>
            <TextField
                source={`new_Anchortext${idx}`}
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function TargetUrl({idx}: FormFieldsProps) {
    return (
        <Labeled label={`Target URL (${idx})`} fullWidth>
            <TextField
                source={`new_TargetURL${idx}`}
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function SeoTitle({label}: FormFieldsProps) {

    return (
        <Labeled label={label} fullWidth>
            <Stack direction="row" spacing={{xs: 1, sm: 1}}>
                <TextField
                    source="new_SeoTitle"
                    emptyText="Not set"
                    sx={{...TextFieldStyles, width: "100%"}}
                />
            </Stack>
        </Labeled>
    );
}

function SeoDescription({label}: FormFieldsProps) {

    return (
        <Labeled label={label} fullWidth>
            <Stack direction="row" spacing={{xs: 1, sm: 1}}>
                <TextField
                    source="new_PostDesciption"
                    emptyText="Not set"
                    sx={{...TextFieldStyles, width: "100%"}}
                />
            </Stack>
        </Labeled>
    );
}

function ContentBody({setWordCount, record}: TextEditorProps) {
    return (
        <Labeled
            label="Post"
            fullWidth
            className="tinymce-body tinymce-body--read"
        >
            <>
                <div className="tinymce-body__information">
                    <Stack direction="row" mr={2}>
                        <ArticleOutlinedIcon color="info" fontSize="small" sx={{marginRight: 1}}/>
                        <span className="tinymce-body__wordcount-text">
                            Word Count: {record.new_wordcount}
                          </span>
                    </Stack>
                </div>
                <RichTextField
                    source="new_TextHTMLbody"
                    sx={{...TextFieldStyles, ...{paddingTop: "40px"}}}
                    emptyText="Not set"
                />
            </>
        </Labeled>
    );
}

export default PostFormFields;
