import buildHasuraProvider from "ra-data-hasura";
import { useEffect } from "react";
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from "react-admin";
import { http } from "../services/httpService";
import { clientOptions } from "./hasuraConfig";
import customBuildFields from "../queries/customBuildQueries";
import { nhost } from "../lib/nhost";
import {RoleName} from "../models/role-model";

const useHasuraDataProvider = async (setStateProvider: any) => {
  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProviderHasura = await buildHasuraProvider(
        {
          clientOptions,
        },
        {
          buildFields: customBuildFields,
        }
      );
      const modifiedProvider: DataProvider = {
        getList: async (resource: string, params: GetListParams) => {
          if (resource === "v1_Events") {
            const modifiedParams = {
              ...params,
              filter: {
                ...params.filter,
                "payload@_contains": JSON.stringify(params.filter['payload']),
              },
            };
            delete modifiedParams.filter["payload"];
            return dataProviderHasura.getList(resource, modifiedParams);
          }
          return dataProviderHasura.getList(resource, params);
        },
        getOne: (resource: string, params: GetOneParams<any>) =>
          dataProviderHasura.getOne(resource, params),
        getMany: (resource: string, params: GetManyParams) =>
          dataProviderHasura.getMany(resource, params),
        getManyReference: (resource: string, params: GetManyReferenceParams) =>
          dataProviderHasura.getManyReference(resource, params),
        update: (resource: string, params: UpdateParams<any>) => {
          if (
            JSON.stringify(params.data) ===
              JSON.stringify(params.previousData) ||
            !params.data
          ) {
            return Promise.resolve({ data: params.data }) as any;
          }
          return dataProviderHasura.update(resource, params);
        },
        updateMany: (resource: string, params: UpdateManyParams<any>) =>
          dataProviderHasura.updateMany(resource, params),
        create: async (resource: any, params: CreateParams<any>) => {
          if (resource === "v1_Events")
            params.data.author_id = params.data.author.id;
          return dataProviderHasura.create(resource, params);
        },
        delete: (resource: string, params: DeleteParams<any>) =>
          dataProviderHasura.delete(resource, params),
        deleteMany: (resource: string, params: DeleteManyParams<any>) =>
          dataProviderHasura.deleteMany(resource, params),
        uploadPost: (
          postId: number,
          email: string
        ): Promise<{ success?: string; error?: string }> => {
          return http
            .post("https://postupload.apidome.net", {
              postId,
              email,
            })
            .then((response) => {
              return response.data;
            });
        },
      };

      setStateProvider(modifiedProvider);
    };

    buildDataProvider();
  }, [setStateProvider]);
};
export default useHasuraDataProvider;
