import gql from 'graphql-tag';
import {CONTENT_STATUS} from '../config/statuses';

export const GET_WRITER = gql`
  {
    id
    FullName
    owner_id
    CreatedOn
    FirstName
    LastName
    EMailAddress1
    MobilePhone
    language1_id
    language_id
    CountryID
    city_id
    billing_address
    billing_zip_code
    avatar_url
    AssistantID
    approved: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.APPROVED} } }
    ) {
      aggregate {
        count
      }
    }
    waiting: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.WAITING_APPROVAL} } }
    ) {
      aggregate {
        count
      }
    }
    declined: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.DECLINED} } }
    ) {
      aggregate {
        count
      }
    }
    ordered: Posts_aggregate(
      where: { new_ContentOrderStatus: { _eq: ${CONTENT_STATUS.ORDERED} } }
    ) {
      aggregate {
        count
      }
    }
    links_bought_total
    links_bought_last_month
    links_bought_this_month
    links_status_in_progress
    links_status_live
    allow_upload_content_portal
  }
`;
