import {useGetIdentity, useGetList} from "react-admin";

export const useGetActiveCheckout = () => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    const {data: checkouts, isLoading: isCheckoutsLoading} = useGetList(
        'v1_Checkout',
        {
            filter: {statecode: 0, user_id: identity?.id},
        },
        {enabled: !!identity}
    );

    const isLoading = isIdentityLoading || isCheckoutsLoading;

    const checkout = checkouts && checkouts[0];

    return {checkout, isLoading};
};