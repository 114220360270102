import {Chip} from "@mui/material";
import {COLORS} from "../../../config/constants";
import {MARKETPLACE_STATUS} from "../../../config/statuses";

const STATUSES = {
    [MARKETPLACE_STATUS.ORDER_PLACED]:
        {value: "Order Placed", textColor: COLORS.GREEN, bgColor: COLORS.WHITE, borderColor: COLORS.GREEN},
    [MARKETPLACE_STATUS.CONTENT_CREATION]:
        {value: "Content Creation", textColor: COLORS.WHITE, bgColor: COLORS.LIGHT_BLUE, borderColor: COLORS.LIGHT_BLUE},
    [MARKETPLACE_STATUS.WAITING_APPROVAL]:
        {value: "Waiting Approval", textColor: COLORS.RED, bgColor: COLORS.WHITE, borderColor: COLORS.RED},
    [MARKETPLACE_STATUS.SENT_TO_PUBLISHER]:
        {value: "Sent To Publisher", textColor: COLORS.WHITE, bgColor: COLORS.ORANGE, borderColor: COLORS.ORANGE},
    [MARKETPLACE_STATUS.PUBLISHED]:
        {value: "Published", textColor: COLORS.WHITE, bgColor: COLORS.GREEN, borderColor: COLORS.GREEN},
    [MARKETPLACE_STATUS.CANCELLED]:
        {value: "Cancelled", textColor: COLORS.WHITE, bgColor: COLORS.RED, borderColor: COLORS.RED},
};

const timelineSx = {
    minWidth: '100px',
    borderRadius: '16px',
    borderWidth: '2px',
    cursor: 'pointer',
    height: '20px'
}

const editSx = {
    minWidth: "200px",
    borderRadius: "16px",
    border: "2px solid",
    padding: "10px",
    fontSize: "16px"
}

export const PostStatusChip = ({statusId, timeline}: { statusId: number, timeline?: boolean }) => {

    const statusSettings = STATUSES[statusId];

    if (!statusId || !statusSettings) return null;

    return (
        <Chip
            sx={
                {
                    ...timeline ? timelineSx : editSx,
                    backgroundColor: statusSettings.bgColor,
                    color: statusSettings.textColor,
                    borderColor: statusSettings.borderColor,
                }
            }
            label={statusSettings.value}
            variant={"filled"}
        />
    );
}