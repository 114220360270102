import {ReferenceManyToManyField} from "@react-admin/ra-relationships";
import {ChipField, ReferenceManyField, SingleFieldList} from "react-admin";
import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import React, {Ref} from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";

export const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const LightToolTipWrapper = React.forwardRef(
    (
        {
            reference,
            through,
            using,
            perPage,
            label,
            source,
            sx,
            ...props
        }: {
            reference: string;
            through: string;
            label: string;
            source: string;
            using: string;
            perPage: number;
            sx?: SxProps<Theme>
        },
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <div {...props} ref={ref}>
                <ReferenceManyToManyField
                    label={label}
                    reference={reference}
                    through={through}
                    using={using}
                    perPage={perPage}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField source={source} sx={sx}/>
                    </SingleFieldList>
                </ReferenceManyToManyField>
            </div>
        );
    }
);

export const LightToolTipManyField = React.forwardRef(
    (
        {
            reference,
            target,
            perPage,
            label,
            source,
            ...props
        }: {
            reference: string;
            target: string;
            label: string;
            source: string;
            perPage: number;
        },
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <div {...props} ref={ref}>
                <ReferenceManyField
                    label={label}
                    reference={reference}
                    target={target}
                    perPage={perPage}
                >
                    <SingleFieldList linkType={false}>
                        <ChipField source={source}/>
                    </SingleFieldList>
                </ReferenceManyField>
            </div>
        );
    }
);