import {EditBase, EditView} from "react-admin";
import OfferForm from "./offerForm";
import {Box, Button} from "@mui/material";
import OfferEditTitle from "./OfferEditTitle";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const OfferEdit = () => {
    return (
        <EditBase mutationMode="optimistic"
                  mutationOptions={{
                      onError: () => {
                      },
                  }}>,
            redirect={false}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{flexDirection: {xs: "column", md: "row"}}}
            >
                <OfferEditTitle/>
                <Button startIcon={<DeleteOutlineOutlinedIcon sx={{color: "#9E9E9E"}}/>}></Button>
            </Box>
            <EditView>
                <OfferForm/>
            </EditView>
        </EditBase>
    );
};
export default OfferEdit;
