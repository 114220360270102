import {Box, Stack, Typography} from "@mui/material";
import {DateField, ReferenceField, TextField, useGetOne, useRecordContext} from "react-admin";
import PageTitle from "../../../layout/PageTitle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LanguageIcon from "@mui/icons-material/Language";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {PostEditStatus} from "./postEditStatus";
import {v1_Posts} from "../../../../generated/types";
import {AvatarCard} from "../../avatarCard";

const PostEditTitle = () => {
    const post = useRecordContext<v1_Posts>();

    const {data: publisher} = useGetOne(
        "v1_ContactBase",
        {
            id: post?.supplier_id,
        },
        {enabled: !!post},
    );

    if (!post) return null;

    return (
        <Box
            display="flex" justifyContent="space-between"
            sx={{
                width: "100%",
            }}
        >
            <Box
                sx={{
                    maxWidth: "68%",
                    ".MuiTypography-body2": {fontWeight: "600"}
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{flexDirection: {xs: "column", md: "row"}}}
                    gap={3}
                >
                    <PageTitle>Ordered Post - {post.id}</PageTitle>
                    <PostEditStatus/>
                </Box>

                <Stack direction="column" gap={3} mt={1}>
                    <Stack direction="row" gap={3} mt={1}>
                        <Box display="flex" alignItems="center">
                            <DateRangeIcon fontSize="small" sx={{marginRight: 1}}/>
                            <Typography sx={{marginRight: 1}}>Created On:</Typography>
                            <DateField
                                source="CreatedOn"
                                options={{year: "numeric", month: "long", day: "numeric"}}
                                emptyText="No Created Date"
                            />
                        </Box>

                        <Box display="flex" alignItems="center">
                            <DateRangeIcon fontSize="small" sx={{marginRight: 1}}/>
                            <Typography sx={{marginRight: 1}}>Duration:</Typography>
                            <ReferenceField
                                source="OfferId"
                                reference="v1_Offer"
                                link={false}
                                emptyText="No Duration"
                            >
                                <ReferenceField
                                    source="postduration_id"
                                    reference="v1_PostDuration"
                                    link={false}
                                >
                                    <TextField
                                        source="value"
                                    />
                                </ReferenceField>
                            </ReferenceField>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <StorefrontIcon fontSize="small" sx={{marginRight: 1}}/>
                            <Typography sx={{marginRight: 1}}>Offer ID:</Typography>
                            <TextField
                                source="OfferId"
                            />
                            {/*<ReferenceField*/}
                            {/*    source="OfferId"*/}
                            {/*    reference="v1_Offer"*/}
                            {/*    link={false}*/}
                            {/*    emptyText="No Offer"*/}
                            {/*>*/}
                            {/*    <TextField*/}
                            {/*        source="id"*/}
                            {/*    />*/}
                            {/*</ReferenceField>*/}
                        </Box>
                    </Stack>

                    <Box display="flex" alignItems="center">
                        <LanguageIcon fontSize="small" sx={{marginRight: 1}}/>
                        <Typography sx={{marginRight: 1}}>Site/section:</Typography>
                        <ReferenceField
                            source="OfferId"
                            reference="v1_Offer"
                            link={false}
                            emptyText="No Site/section"
                        >
                            <TextField
                                source="name"
                            />
                        </ReferenceField>
                    </Box>
                </Stack>

            </Box>

            <Stack direction="row" spacing={3} display="flex" alignItems="center" sx={{width: "32%"}}>
                <AvatarCard
                    imageUrl={publisher?.avatar_url}
                    name={publisher?.FullName}
                    jobTitle="Publisher"
                    sx={{width: "100%"}}
                />
            </Stack>

        </Box>
    );
};
export default PostEditTitle;
