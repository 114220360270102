import React, {SyntheticEvent, useState} from "react";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {Stack, Tab} from "@mui/material";
import PageTitle from "../../../layout/PageTitle";
import {PostListCard} from "./postListCard";
import {Loading,} from "react-admin";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {PUBLISHER_POST_STATUS} from "../../../config/statuses";


export const PostList = () => {
    const [tabValue, setTabValue] = useState('all_orders');

    const handleTabChange = (_: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    if (isIdentityLoading) return <Loading/>

    const filterForRole = {supplier_id: identity?.id};

    return (
        <TabContext value={tabValue}>
            <Stack direction="row" spacing={2} alignItems="center" mb={7}>
                <PageTitle>Orders</PageTitle>
                <TabList onChange={handleTabChange} textColor="inherit">
                    <Tab value="all_orders" label="All Orders"/>
                    <Tab value="requires_attention" label="Requires Attention"/>
                    <Tab value="in_progress" label="In Progress"/>
                    <Tab value="completed" label="Completed"/>
                </TabList>
            </Stack>
            <TabPanel value="all_orders" sx={{padding: 0, paddingBottom: 7}}>
                <PostListCard
                    title="All Orders"
                    filter={filterForRole}
                    statusFilterList={[PUBLISHER_POST_STATUS.APPROVE_ORDER,
                        PUBLISHER_POST_STATUS.TO_PUBLISH,
                        PUBLISHER_POST_STATUS.CONTENT_CREATION,
                        PUBLISHER_POST_STATUS.DECLINED_ORDER,
                        PUBLISHER_POST_STATUS.PUBLISHED,
                        PUBLISHER_POST_STATUS.CANCELLED]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="requires_attention"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="Requires Attention"
                    filter={filterForRole}
                    statusFilterList={[PUBLISHER_POST_STATUS.APPROVE_ORDER,
                        PUBLISHER_POST_STATUS.TO_PUBLISH]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="in_progress"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="In Progress"
                    filter={filterForRole}
                    statusFilterList={[PUBLISHER_POST_STATUS.CONTENT_CREATION,
                        PUBLISHER_POST_STATUS.DECLINED_ORDER]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
            <TabPanel
                value="completed"
                sx={{padding: 0, paddingBottom: 7}}
            >
                <PostListCard
                    title="Completed"
                    filter={filterForRole}
                    statusFilterList={[PUBLISHER_POST_STATUS.PUBLISHED]}
                    sort={{field: "CreatedOn", order: "DESC"}}
                />
            </TabPanel>
        </TabContext>
    );
};
