import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";
import CheckIcon from "@mui/icons-material/Check";
import { ORDER_STATUS_VALUE } from "../../../config/statuses";

export const PostStatusField = () => {
    const record = useRecordContext();

    if (!record) return null;

    const { value } = record;
    let chipColor: "primary" | "success" | "warning" | undefined;
    switch (value) {
        case ORDER_STATUS_VALUE.ORDERED:
            chipColor = "warning";
            break;
        case ORDER_STATUS_VALUE.DECLINED:
            chipColor = "warning";
            break;
        case ORDER_STATUS_VALUE.APPROVED:
            chipColor = "success";
            break;
        case ORDER_STATUS_VALUE.WAITING_APPROVAL:
            chipColor = "primary";
            break;
    }

    return (
        <Chip
            sx={{
                minWidth: "120px",
                borderRadius: "16px",
                borderWidth: "2px",
                cursor: "pointer",
            }}
            label={value}
            variant={
                value === ORDER_STATUS_VALUE.ORDERED ? "outlined" : "filled"
            }
            color={chipColor}
            icon={
                value === ORDER_STATUS_VALUE.APPROVED ? (
                    <CheckIcon />
                ) : undefined
            }
        />
    );
};
