import {useContext} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    Paper,
    Popper,
    Typography,
} from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import {CartContext} from '../../context/CartContext';
import {Loading, useCreatePath, useGetList} from "react-admin";
import {Link} from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {COLORS} from '../../config/constants';

const GridCartItemsHeader = () => {
    return (
        <Box p={4}>
            <Typography
                sx={{
                    fontSize: 21,
                    fontWeight: 600,
                }}
                gutterBottom
            >
                Shopping Cart
            </Typography>
            <Typography
                sx={{
                    fontWeight: 500,
                    color: 'warning.main',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
                gutterBottom
            >
                <WarningIcon fontSize="large"/>
                Content creation for your links is included in the price
            </Typography>
        </Box>
    );
};

const GridCartItems = () => {
    const {cartItems, deleteCartItem} = useContext(CartContext);
    const offerIds = cartItems?.map(item => item.id);

    const {data: offers, isLoading: isOffersLoading} = useGetList(
        'v1_Offer',
        {
            filter: {'id@_in': offerIds},
        },
        {enabled: !!offerIds});

    if (isOffersLoading) return <Loading/>

    const handleDeleteCartItem = (index: number) => {
        deleteCartItem(index);
    };

    return (
        <Grid container>
            <Grid
                container
                xs={12}
                sx={{
                    borderBottom: '1px solid #e0e0e0',
                }}
            >
                <Grid
                    item
                    xs={6}
                    sx={{
                        borderRight: '1px solid #e0e0e0',
                    }}
                >
                    <Typography
                        variant="body1" p={2}
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Site/section
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    textAlign="right"
                    sx={{
                        borderRight: '1px solid #e0e0e0',
                    }}
                >
                    <Typography
                        variant="body1" p={2} sx={{
                        fontWeight: 'bold',
                    }}
                    >
                        Base price
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    textAlign="right"
                    sx={{
                        borderRight: '1px solid #e0e0e0',
                    }}
                >
                    <Typography
                        variant="body1" p={2} sx={{
                        fontWeight: 'bold',
                    }}
                    >
                        Niche Fee
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    textAlign="right"
                    sx={{
                        borderRight: '1px solid #e0e0e0',
                    }}
                >
                    <Typography
                        variant="body1" p={2} sx={{
                        fontWeight: 'bold',
                    }}
                    >
                        Word Fee
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                    textAlign="right"
                    sx={{
                        borderRight: '1px solid #e0e0e0',
                    }}
                >
                    <Typography
                        variant="body1" p={2} sx={{
                        fontWeight: 'bold',
                    }}
                    >
                        Total
                    </Typography>
                </Grid>
            </Grid>
            {cartItems.map((item, index) => (
                <Grid
                    container
                    xs={12}
                    sx={{
                        borderBottom: '1px solid #e0e0e0',
                    }}
                >
                    <Grid
                        item
                        xs={6}
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Typography variant="body1" p={2}>
                            {item.label || (offers?.find(offer => offer.id === item.id)?.name || '')}
                        </Typography>
                        <Button
                            sx={{minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                            onClick={() => handleDeleteCartItem(index)}
                            startIcon={<DeleteOutlineOutlinedIcon
                                sx={{color: COLORS.RED}}
                            />}
                        ></Button>
                    </Grid>
                    <Grid
                        item
                        xs={1.5}
                        textAlign="right"
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography variant="body1" p={2}>
                            {item.currency}{item.price}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1.5}
                        textAlign="right"
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography variant="body1" p={2}>
                            {item.currency}{item.nicheFee}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1.5}
                        textAlign="right"
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography variant="body1" p={2}>
                            {item.currency}{item.wordFee}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1.5}
                        textAlign="right"
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography variant="body1" p={2}>
                            {item.currency}{item.total}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

const GridCartItemsSubtotal = () => {
    const {getSubTotalByCurrency} = useContext(CartContext);
    const subTotalByCurrency = getSubTotalByCurrency();

    if (Object.keys(subTotalByCurrency).length === 0) {
        return null;
    }

    return (
        <Grid container>
            {Object.keys(subTotalByCurrency).map((currency) => (
                <Grid
                    container
                    xs={12}
                    sx={{
                        borderBottom: '1px solid #e0e0e0',
                        backgroundColor: '#f5f5f5',
                    }}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={10.5}
                        sx={{
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <Typography
                            variant="body1"
                            px={2}
                            pt={1}
                            fontWeight={900}
                        >
                            Total
                        </Typography>
                        {/*<Typography variant="body2" px={2} pb={1} fontSize={10}>*/}
                        {/*    VAT calculated at checkout*/}
                        {/*</Typography>*/}
                    </Grid>
                    <Grid item xs={1.5} textAlign="right">
                        <Typography variant="body1" p={2}>
                            {currency}
                            {subTotalByCurrency[currency].total}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

const CartPopper = ({
                        open,
                        anchorEl,
                        handleClose,
                    }: {
    open: boolean;
    anchorEl: null | HTMLElement;
    handleClose: (event: Event | React.SyntheticEvent) => void;
}) => {

    const createPath = useCreatePath();

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
            }}
        >
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start'
                                ? 'left top'
                                : 'left bottom',
                    }}
                >
                    <Paper elevation={3}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Card
                                sx={{
                                    minWidth: 275,
                                    maxWidth: 600,
                                }}
                            >
                                <CardContent
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    <GridCartItemsHeader/>
                                    <Divider/>
                                    <GridCartItems/>
                                    <GridCartItemsSubtotal/>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={Link}
                                        to={createPath({resource: 'checkout', type: 'create'})}
                                        size="large"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleClose}
                                    >Checkout</Button>
                                </CardActions>
                            </Card>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default CartPopper;
