import {useEffect} from 'react';
import {useRefresh} from 'react-admin';

/**
 * Executes the useRefresh function and triggers a refresh when the component is mounted.
 */
const useRefreshQueryData = () => {
    const refresh = useRefresh();
    useEffect(() => {
        refresh();
    }, [refresh]);
};

export default useRefreshQueryData;
