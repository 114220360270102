import { AdvertiserStatusField } from './advertiserStatusField';
import { OutreachStatusField } from './outreachStatusField';
import { PublisherStatusField } from './publisherStatusField';

const auditedFieldsDisplayComponents: Record<string, React.FC> = {
  new_OutreachStatus: OutreachStatusField,
  AdvertiserStatus: AdvertiserStatusField,
  PublisherStatus: PublisherStatusField,
};

export const getAuditedFieldDisplayComponent = (updatedField: string) => {
  return auditedFieldsDisplayComponents[updatedField];
};
