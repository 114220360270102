import {
    DatagridProps,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    TextField,
    WrapperField,
} from "react-admin";
import PriceField from "./fields/priceField";
import AhrefsLogo from "../../../layout/images/ahrefs-logo.png";
import MozLogo from "../../../layout/images/moz-logo.png";
import SemrushLogo from "../../../layout/images/semrush-logo.png";
import {LightTooltip, LightToolTipWrapper} from "../../common/lightToolTipWrapper";
import {v1_Offer} from "../../../../generated/types";
import {TruncatedTooltipField} from "../../common/truncatedTooltipField";
import LikeField from "./fields/likeField";
import {DatagridConfigurableBySavedQuery} from "../../general/datagridConfigurableBySavedQuery";
import {TooltipLinkField} from "../../general/tooltipLinkField";

const LIKE_WIDTH = 70;
const PRICE_WIDTH = 160;

type MarketplaceDatagridPorps = {
    userId?: number | string
}

export const MarketplaceDatagrid = (props: DatagridProps & MarketplaceDatagridPorps) => (
    <DatagridConfigurableBySavedQuery
        bulkActionButtons={false}
        rowClick="edit"
        {...props}
        omit={[]}
        sx={{
            overflowX: "auto",
            width: props.width,

            "& [class*=\"column-\"]": {
                textAlign: "center",
                width: "100%",
            },
            "& [class*=\"like\"]": {
                textAlign: "center",
                minWidth: `${LIKE_WIDTH}px`,
                maxWidth: `${LIKE_WIDTH}px`,
                position: "sticky",
                left: 0,
                backgroundColor: "#fafafa",
                zIndex: 3,
            },
            "& [class*=\"link_price\"]": {
                textAlign: "center",
                minWidth: `${PRICE_WIDTH}px`,
                maxWidth: `${PRICE_WIDTH}px`,
                position: "sticky",
                left: `${LIKE_WIDTH}px`,
                backgroundColor: "#fafafa",
                zIndex: 3,
            },
            "& [class*=\"website_name\"]": {
                textAlign: "center",
                position: "sticky",
                left: `${LIKE_WIDTH + PRICE_WIDTH}px`,
                backgroundColor: "#fafafa",
                zIndex: 3,
            },
            "& .RaDatagrid-headerCell": {
                lineBreak: "1",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                backgroundColor: "#f5f5f5f5",
            },
            "& .RaDatagrid-rowEven": {
                "& .column-link_price ": {backgroundColor: "#039be5"},
                "& .column-website_name": {backgroundColor: "#fff"},
                "& .column-like": {backgroundColor: "#fff"},
            },
            "& .RaDatagrid-rowOdd": {
                "& .column-link_price ": {backgroundColor: "#007dba "},
            },

            // Display icons in the header for specific columns with before element
            "& .MuiTableSortLabel-root[data-field=\"Website.MOZDa\"]:before, .MuiTableSortLabel-root[data-field=\"Website.MOZSpamScore\"]:before":
                {
                    content: "''",
                    display: "block",
                    background: `url(${MozLogo}) no-repeat`,
                    width: "33px",
                    height: " 10px",
                    float: "left",
                    margin: "0 6px 0 0",
                },
            "& .MuiTableSortLabel-root[data-field=\"Website.SemrushAS\"]:before":
                {
                    content: "''",
                    display: "block",
                    background: `url(${SemrushLogo}) no-repeat`,
                    width: "80px",
                    height: " 10px",
                    float: "left",
                    margin: "0 6px 0 0",
                },
            "& .MuiTableSortLabel-root[data-field=\"Website.new_DomainRatingMOZ\"]:before": {
                content: "''",
                display: "block",
                background: `url(${AhrefsLogo}) no-repeat`,
                width: "40px",
                height: " 10px",
                float: "left",
                margin: "0 6px 0 0",
            },
        }}
    >
        <TextField source="id" label="Offer ID"/>
        <LikeField label="Like" source="like" userId={props.userId}/>
        <PriceField label="Price" source="link_price"/>
        <TooltipLinkField
            label="Site/section"
            source="name"
            justifyContent="center"
        />
        <ReferenceField
            label="Language"
            source="LanguageId"
            reference="v1_new_languagesBase"
            link="show"
        >
            <TextField source="new_name"/>
        </ReferenceField>
        <ReferenceField
            label="DR"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.new_DomainRatingMOZ"
        >
            <NumberField source="new_DomainRatingMOZ"/>
        </ReferenceField>
        <ReferenceField
            label="AS"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.SemrushAS"
        >
            <NumberField source="SemrushAS"/>
        </ReferenceField>
        <ReferenceField
            label="Domain Authority"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.new_MozDomainAuthority"
        >
            <NumberField source="new_MozDomainAuthority"/>
        </ReferenceField>
        <ReferenceField
            label="Spam Score"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.MOZSpamScore"
        >
            <NumberField source="MOZSpamScore"/>
        </ReferenceField>
        <ReferenceField
            label="Organic Traffic"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.OrganicTraffic"
        >
            <NumberField source="OrganicTraffic"/>
        </ReferenceField>
        <ReferenceField
            label="Reffering Domains"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.referring_domains_total"
        >
            <NumberField source="referring_domains_total"/>
        </ReferenceField>
        <ReferenceField
            label="Outgoing Domains"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.OutgoingDomains"
        >
            <NumberField source="OutgoingDomains"/>
        </ReferenceField>
        <ReferenceField
            label="Keywords"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.keywords"
        >
            <NumberField source="keywords"/>
        </ReferenceField>
        <FunctionField
            label="Homepage Link"
            sortBy="IsHomepageLink"
            render={(record: v1_Offer) =>
                record.IsHomepageLink
                    ? 'Yes'
                    : 'No'
            }
        />
        <FunctionField
            label="Do Follow"
            sortBy="do_follow"
            render={(record: v1_Offer) =>
                record.do_follow
                    ? 'Yes'
                    : 'No'
            }
        />
        <NumberField source="links_to_follow" label="Number of Links"/>
        <ReferenceField
            label="Domain Age"
            source="website_id"
            reference="v1_Website"
            link="show"
            sortBy="Website.comp_domain_age"
        >
            <NumberField source="comp_domain_age"/>
        </ReferenceField>
        <DateField label="Published On" source="published_on" locales="en-GB"/>
        <WrapperField label="Categories">
            <LightTooltip
                title={
                    <LightToolTipWrapper
                        perPage={25}
                        reference="v1_MarketPlaceCategories"
                        through="v1_MarketPlaceCategories_Offers"
                        label="Categories"
                        source="CategoryName"
                        using="offer_id,marketplace_category_id"
                    />
                }
                placement="top-end"
            >
                <LightToolTipWrapper
                    reference="v1_MarketPlaceCategories"
                    through="v1_MarketPlaceCategories_Offers"
                    label="Categories"
                    source="CategoryName"
                    using="offer_id,marketplace_category_id"
                    perPage={1}
                />
            </LightTooltip>
        </WrapperField>
        <WrapperField label="Restricted Niches">
            <LightTooltip
                title={
                    <LightToolTipWrapper
                        perPage={25}
                        reference="v1_PostNiche"
                        through="v1_RestrictedNichesToOffer"
                        label="Restricted Niches"
                        source="name"
                        using="offer_id,niche_id"
                    />
                }
                placement="top-end"
            >
                <LightToolTipWrapper
                    reference="v1_PostNiche"
                    through="v1_RestrictedNichesToOffer"
                    label="Restricted Niches"
                    source="name"
                    using="offer_id,niche_id"
                    perPage={1}
                />
            </LightTooltip>
        </WrapperField>
        <ReferenceField
            label="Duration"
            source="postduration_id"
            reference="v1_PostDuration"
            link="show"
            sortBy="PostDuration.value"
        >
            <NumberField source="value"/>
        </ReferenceField>
        <TruncatedTooltipField source="notes" label="Offer Details" limit={20}/>
    </DatagridConfigurableBySavedQuery>
);
