import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import {addEventsForMutations} from "@react-admin/ra-audit-log";
import {useEffect, useState} from "react";
import {Admin, CustomRoutes, DataProvider, Loading, Resource} from "react-admin";
import {QueryClient} from "react-query";
import "./App.css";
import {authProvider} from "./authorization/authProvider";
import {Dashboard} from "./components/common/dashboard/dashboard";
import LoginForm from "./layout/loginForm";
import {PostListRouter} from "./components/common/routers/postListRouter";
import useHasuraDataProvider from "./data/useHasuraDataProvider";
import i18nProvider from "./i18n/i18nProvider";
import MyLayout from "./layout/layout";
import createTheme from "./theme";
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from "./models/role-model";
import {CartContextProvider} from "./context/CartContext";
import {Resource as RBacResource} from "@react-admin/ra-rbac";
import {OfferListRouter} from "./components/common/routers/offerListRouter";
import {OfferEditRouter} from "./components/common/routers/offerEditRouter";
import {PostEditRouter} from "./components/common/routers/postEditRouter";
import ProfileEdit from "./components/common/profile/profileEdit";
import {Route} from "react-router";
import OfferCreate from "./components/publisher/offer/offerCreate";
import {CheckoutCreate} from "./components/advertiser/checkout/checkoutCreate";
import {CheckoutEdit} from "./components/advertiser/checkout/checkoutEdit";
import {BillingPage} from "./components/advertiser/billing/billingPage";
import {ProfileChangePassword} from "./components/common/profile/profileChangePassword";
import {BrowserRouter} from 'react-router-dom';
import {initStore, store} from "./data/storeWrapper";

declare global {
    interface Window {
        FrontChat: any;
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            retry: process.env.NODE_ENV === "development" ? 0 : undefined,
        },
    },
});

const App = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);

    useHasuraDataProvider(setDataProvider);

    useEffect(() => {
        window.FrontChat('init', {chatId: `${process.env.REACT_APP_SUPPORT_CHAT_ID}`, useDefaultLauncher: true});
    }, []);

    if (!dataProvider) return <Loading/>;

    const augmentedDataProvider = addEventsForMutations(
        dataProvider,
        authProvider,
        {
            name: "v1_Events",
            resources: ["v1_Posts"],
        },
    );

    initStore(augmentedDataProvider);

    return (
        <BrowserRouter>
            <CartContextProvider>
                <Admin
                    theme={createTheme("DEFAULT")}
                    authProvider={authProvider}
                    store={store}
                    dataProvider={augmentedDataProvider}
                    loginPage={LoginForm}
                    layout={MyLayout}
                    dashboard={Dashboard}
                    queryClient={queryClient}
                    i18nProvider={i18nProvider}
                    requireAuth
                >
                    <RBacResource
                        name="v1_Offer"
                        options={{
                            label: "Marketplace",
                            labelByRole: {
                                [ROLE_ADVERTISER]: "Marketplace",
                                [ROLE_SUPPLIER]: "My Offers",
                            },
                        }}
                        icon={StorefrontOutlinedIcon}
                        list={OfferListRouter}
                        edit={OfferEditRouter}
                        create={OfferCreate}
                    />
                    <Resource
                        name="v1_Posts"
                        options={{
                            label: "Orders",
                            labelByRole: {
                                [ROLE_ADVERTISER]: "Posts",
                                [ROLE_SUPPLIER]: "Orders",
                                [ROLE_WRITER]: "Orders",
                            },
                        }}
                        icon={AssignmentOutlinedIcon}
                        list={PostListRouter}
                        edit={PostEditRouter}
                    />
                    <Resource
                        name="billing"
                        options={{
                            label: "Billing",
                        }}
                        icon={ReceiptOutlinedIcon}
                        list={BillingPage}
                    />
                    <CustomRoutes>
                        <Route
                            key="checkout-create"
                            path="/checkout/create"
                            element={<CheckoutCreate/>}
                        />
                        <Route
                            key="checkout"
                            path="/checkout"
                            element={<CheckoutEdit/>}
                        />
                        <Route
                            key="my-profile"
                            path="/my-profile"
                            element={<ProfileEdit/>}
                        />
                        <Route
                            key="change-password"
                            path="/change-password"
                            element={<ProfileChangePassword/>}
                        />
                    </CustomRoutes>
                </Admin>
            </CartContextProvider>
        </BrowserRouter>
    );
};

export default App;
