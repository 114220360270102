import {Card, CardContent, Stack, Typography} from '@mui/material';
import {List, Loading, useGetIdentity} from 'react-admin';
import {EmptyList} from '../../../general/emptyList';
import * as React from "react";
import {ListDatagrid} from "./listDatagrid";
import {ListActions} from "./listActions";

export const PublisherDashboardOfferList = () => {
    const {data: identity} = useGetIdentity();
    const publisherId = identity?.id;

    if (!publisherId) return <Loading/>;

    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h3">My Offers</Typography>
                    </Stack>

                    <List
                        resource="v1_Offer"
                        filter={{supplier_id: publisherId}}
                        sort={{field: 'created_on', order: 'DESC'}}
                        perPage={6}
                        pagination={false}
                        actions={<ListActions/>}
                        empty={<EmptyList
                            titleText="You don't have any offers yet."
                            subtitleText="Add more offers to increase your chances of selling."
                            buttonText="CREATE OFFER"
                            link="#/v1_Offer/create"
                        />}
                        sx={{overflow: 'auto'}}
                    >
                        <ListDatagrid/>
                    </List>
                </Stack>
            </CardContent>
        </Card>
    );
};
