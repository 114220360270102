import {Loading, useRecordContext} from "react-admin";
import {v1_Posts} from "../../../../generated/types";
import {PostStatusChip} from "./postStatusChip";

export const PostEditStatus = () => {
    const post = useRecordContext<v1_Posts>();

    if (!post) return <Loading/>;

    if (!post.marketplacestatus_id) return null;

    return (
        <PostStatusChip statusId={post.marketplacestatus_id}/>
    );
};

