import {useEffect} from 'react';
import {useGetList, useStoreContext} from 'react-admin';
import {nhost} from "../lib/nhost";

interface IEvent {
    created_by: number;
    id: number;
    path: string;
    preference: any[];
}

const MAX_PREFERENCES = 1000;

const useGetPreferences = () => {
    const store = useStoreContext();
    const nhostUser = nhost.auth.getUser();
    const userId = nhostUser?.id;
    const {data, isLoading} = useGetList<IEvent>('v1_PreferencesMarketplace', {
        filter: {created_by: userId},
        pagination: {page: 1, perPage: MAX_PREFERENCES},
    });

    useEffect(() => {
        if (isLoading || !userId) {
            return;
        }
        data?.forEach((p) => {
            store.setItem(p.path, p.preference);
        });
    }, [isLoading, data, store, userId]);
};
export default useGetPreferences;
