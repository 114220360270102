import {Loading, useRecordContext} from "react-admin";
import {PostStatusChip} from "./postStatusChip";

export const PostEditStatus = () => {
    const record = useRecordContext();

    if (!record) return <Loading/>;

    if (!record.PublisherStatus) return null;

    return (
        <PostStatusChip statusId={record.PublisherStatus}/>
    );
};

