import {Typography} from '@mui/material';
import type {StatItemProps} from './types';

const amountFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export function StatItem({
    label,
    counterColor,
    amount,
    currency,
}: StatItemProps) {
    return (
        <>
            <Typography variant="h3" color={counterColor}>
                {!!currency && currency}
                {amountFormatter.format(amount)}
            </Typography>
            <Typography variant="body2" color="neutral.main">
                {label}
            </Typography>
        </>
    );
}
