import {CircularProgress} from "@mui/material";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from "../../../models/role-model";
import OfferEdit from "../offer/offerEdit";
import {OfferEdit as PublisherOfferEdit} from "../../publisher/offer/offerEdit"

export const OfferEditRouter = () => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const role = identity?.role;

    if (isIdentityLoading) return <CircularProgress/>;

    function offerEdit() {
        switch (role) {
            case ROLE_WRITER:
                return <OfferEdit/>;
            case ROLE_SUPPLIER:
                return <PublisherOfferEdit/>;
            case ROLE_ADVERTISER:
                return <OfferEdit/>;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        offerEdit()
    );
};
