import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useGetList,
} from "react-admin";
import React, {useEffect} from "react";
import {Grid, InputAdornment} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {useFormContext, useWatch} from "react-hook-form";
import {ReferenceManyToManyInput} from "@react-admin/ra-relationships";
import {POST_DURATION} from "../../../config/constants";
import {BooleanFormInput} from "../../common/booleanFormInput";
import NumberInputNoWheel from "../../common/numberInputNoWheel";
import {FormAction} from "../../../type/formActions";
import {urlValidator} from "../../../utils/validators";

const OfferFormFields = ({action}:{action: FormAction}) => {

    const {data: categoriesTypes, isLoading: isCategoriesTypesLoading} =
        useGetList('v1_MarketPlaceCategoriesTypes', {
            sort: {field: 'id', order: 'DESC'},
        });

    const postdurationId = useWatch({
        name: "postduration_id",
    });

    const isEditPage = action === 'edit';

    const {setValue} = useFormContext();

    useEffect(() => {
        setValue("IsHomepageLink", false);
    }, [setValue, postdurationId]);

    const isOneYearDuration = POST_DURATION.ONE_YEAR === postdurationId;

    if (isCategoriesTypesLoading) return <>Loading...</>;

    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={5}>
                <TextInput
                    source="name"
                    label="Site/section"
                    placeholder="Write here"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                    validate={required() && urlValidator()}
                    helperText={false}
                />
            </Grid>
            <Grid item xs={1}>
                <BooleanFormInput label="Do follow" source="do_follow"/>
            </Grid>
            <Grid item xs={2}>
                <SelectInput
                    source="links_to_follow"
                    label="Num of Links"
                    choices={[
                        {id: 1, name: "1"},
                        {id: 2, name: "2"},
                        {id: 3, name: "3"},
                    ]}
                    helperText={false}
                    InputLabelProps={{shrink: true}}
                    fullWidth
                    sx={{margin: "0px"}}
                />
            </Grid>
            {isOneYearDuration && <Grid item xs={2}>
                <BooleanFormInput label="Homepage" source="IsHomepageLink"/>
            </Grid>}
            <Grid item xs={isOneYearDuration ? 2 : 4}>
                <ReferenceInput
                    source="postduration_id"
                    reference="v1_PostDuration"
                >
                    <AutocompleteInput
                        optionText="value"
                        size="small"
                        label="Post Duration"
                        validate={required()}
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={2}>
                <ReferenceInput
                    label="Language"
                    source="LanguageId"
                    reference="v1_new_languagesBase"
                    sort={{field: 'new_name', order: 'ASC'}}
                    filter={{'new_name@_neq': 'null'}}
                    perPage={1000}
                >
                    <AutocompleteInput
                        optionText="new_name"
                        size="small"
                        placeholder="Select one"
                        filterToQuery={(searchText) => ({
                            new_name: searchText,
                        })}
                        helperText={false}
                    />
                </ReferenceInput>
            </Grid>
            <Grid item xs={2}>
                <TextInput
                    source="link_price"
                    label="Price"
                    placeholder="Write here"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <CreditCardIcon/>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>

            <Grid item xs={8}>
                <ReferenceManyToManyInput
                    source="id"
                    reference="v1_MarketPlaceCategories"
                    through="v1_MarketPlaceCategories_Offers"
                    using="offer_id,marketplace_category_id"
                    sortChoices={{field: "MarketPlaceCategoriesType.name", order: "ASC"}}
                    perPageChoices={1000}
                >
                    <AutocompleteArrayInput
                        label="Categories"
                        size="small"
                        optionText="CategoryName"
                        optionValue="id"
                        filterToQuery={(searchText) => ({
                            CategoryName: searchText,
                        })}
                        parse={(value) => (value === "" ? null : value)}
                        fullWidth
                        helperText={false}
                        groupBy={(option) => {
                            return (
                                categoriesTypes?.find(
                                    (type) =>
                                        type.id === option.TypeId
                                )?.name || ''
                            );
                        }}
                        sx={{'.MuiChip-root':{maxHeight: '18px'}}}
                    />
                </ReferenceManyToManyInput>
            </Grid>
            <Grid item xs={6}>
                <ReferenceManyToManyInput
                    source="id"
                    reference="v1_PostNiche"
                    through="v1_RestrictedNichesToOffer"
                    using="offer_id,niche_id"
                    sort={{field: "niche_id", order: "ASC"}}
                >
                    <AutocompleteArrayInput
                        label="Restricted Niches"
                        size="small"
                        optionText="name"
                        optionValue="id"
                        filterToQuery={(searchText) => ({
                            name: searchText,
                        })}
                        parse={(value) => (value === "" ? null : value)}
                        fullWidth
                        helperText={false}
                        sx={{'.MuiChip-root':{maxHeight: '18px'}}}
                    />
                </ReferenceManyToManyInput>
            </Grid>
            <Grid item xs={3}>
                <NumberInputNoWheel
                    fullWidth
                    label="Restricted Niche Price"
                    source="additional_price_percentage"
                    InputLabelProps={{shrink: true}}
                    helperText={false}
                />
            </Grid>
            <Grid item xs={3}>
                <TextInput
                    label="Publication Sample"
                    source="PublicationSample"
                    multiline
                    placeholder="Paste a link here - optional"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label="Offer Details"
                    source="notes"
                    multiline
                    placeholder="Write here"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    helperText={false}
                />
            </Grid>
        </Grid>
    );
};
export default OfferFormFields;
