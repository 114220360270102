import {Chip, ChipOwnProps} from '@mui/material';
import {useRecordContext} from 'react-admin';
import {COLORS} from "../../config/constants";

export const StatusField = (props: {
    chipMap: {
        [p: number]: {
            color: string,
            variant: ChipOwnProps['variant'],
            icon?: ChipOwnProps['icon'],
            textColor?: string,
            borderColor?: string
        }
    },
    source: string,
}) => {
    const record = useRecordContext();

    if (!record) return null;

    const recordId = Number(record.id);

    return (
        <Chip
            label={record[props.source]}
            variant={props.chipMap[recordId].variant}
            icon={props.chipMap[recordId].icon}
            sx={{
                minWidth: '130px',
                borderRadius: '16px',
                borderWidth: '2px',
                cursor: 'pointer',
                backgroundColor: props.chipMap[recordId].color,
                color: props.chipMap[recordId].textColor || COLORS.WHITE,
                borderColor: props.chipMap[recordId].borderColor,
            }}
        />
    );
};
