import {List, Loading, Pagination} from 'react-admin';
import {useGetIdentity} from '../../../hooks/useGetIdentity';
import {OfferDatagrid} from './offerDatagrid';
import {Card, CardContent, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {OfferListActions} from "./offerListActions";

export const OfferList = () => {
    const {data: identity} = useGetIdentity();

    const publisherId = identity?.id;

    if (!publisherId) return <Loading/>;

    return (<Card>
        <CardContent>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3">Offers</Typography>
                </Stack>
                <List
                    title="My Offers"
                    resource="v1_Offer"
                    actions={<OfferListActions/>}
                    filter={{supplier_id: publisherId}}
                    sort={{field: 'created_on', order: 'DESC'}}
                    pagination={
                        <Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>
                    }
                    perPage={50}
                >
                    <OfferDatagrid/>
                </List>
            </Stack>
        </CardContent>
    </Card>);
};
