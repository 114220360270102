import {CircularProgress} from "@mui/material";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from "../../../models/role-model";
import OrderEdit from "../order/orderEdit";
import {PostEdit as PublisherPostEdit} from "../../publisher/post/postEdit";
import {PostEdit as AdvertiserPostEdit} from "../../advertiser/post/postEdit";

export const PostEditRouter = () => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const role = identity?.role;

    if (isIdentityLoading) return <CircularProgress/>;

    function postEdit() {
        switch (role) {
            case ROLE_WRITER:
                return <OrderEdit/>;
            case ROLE_SUPPLIER:
                return <PublisherPostEdit/>;
            case ROLE_ADVERTISER:
                return <AdvertiserPostEdit/>;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        postEdit()
    );
};
