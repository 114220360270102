import {useState, useContext} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
} from '@mui/material';
import {
    Button,
    Form,
    TextInput,
    required,
    useNotify,
    useRefresh,
} from 'react-admin';
import {DialogContext} from './dialogContext';
import {sendPostToApi} from '../../../../services/api';
import {ensureHttpsSchema, apiErrorMessage} from '../../../../utils/utils';

export function AddDialog() {
    const notify = useNotify();
    const refresh = useRefresh();
    const [isDisabled, setIsDisabled] = useState(false);
    const {isAddDialogOpen, setIsAddDialogOpen} = useContext(DialogContext);

    async function addProject(dashboardCtx: any) {
        try {
            setIsDisabled(true);
            const {currentUser, url} = dashboardCtx;
            const newProject = {
                url: ensureHttpsSchema(url),
                user_id: currentUser.data.id,
            };
            await sendPostToApi('marketplace/projects/create', newProject);
            notify(`The "${url}" project was successfully added`, {
                type: 'success',
            });
            setIsDisabled(false);
            setIsAddDialogOpen(false);
            refresh();
        } catch (error: unknown) {
            const message = apiErrorMessage(error);
            notify(message, {type: 'error'});
            setIsDisabled(false);
        }
    }

    return (
        <Dialog
            open={isAddDialogOpen}
            onClose={() => setIsAddDialogOpen(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Add Project</DialogTitle>
            <DialogContent>
                <Box sx={{pt: '12px'}}>
                    <Form id="addProject" onSubmit={addProject}>
                        <TextInput
                            source="url"
                            label="Project URL"
                            validate={required()}
                            fullWidth
                        />
                    </Form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    form="addProject"
                    label="Add"
                    variant="contained"
                    disabled={isDisabled}
                />
                <Button
                    label="Close"
                    onClick={() => setIsAddDialogOpen(false)}
                />
            </DialogActions>
        </Dialog>
    );
}
