import * as React from 'react';
import { Checkbox, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { styled } from '@mui/material/styles';

/**
 * UI to enable/disable a field
 */
export const MarketPlaceFieldCheckbox = (props: any) => {
    const { selected, label, onToggle, onMove, source, index } = props;
    const dropIndex = React.useRef<number | null>(null);
    const x = React.useRef<number | null>(null);
    const y = React.useRef<number | null>(null);

    const handleDocumentDragOver = React.useCallback((event: any) => {
        x.current = event.clientX;
        y.current = event.clientY;
    }, []);

    const handleDragStart = () => {
        document.addEventListener('dragover', handleDocumentDragOver);
    };

    const handleDrag = (event: any) => {
        // imperative DOM manipulations using the native Drag API
        const selectedItem = event.target;
        selectedItem.classList.add('drag-active');
        const list = selectedItem.parentNode;
        let dropItem =
            document.elementFromPoint(x.current!, y.current!) === null
                ? selectedItem
                : document.elementFromPoint(x.current!, y.current!);
        if (dropItem.classList.contains('dragIcon')) {
            dropItem = dropItem.parentNode;
        }
        if (dropItem === selectedItem) {
            return;
        }
        if (list === dropItem.parentNode) {
            dropIndex.current = dropItem.dataset.index;
            if (dropItem === selectedItem.nextSibling) {
                dropItem = dropItem.nextSibling;
            }
            list.insertBefore(selectedItem, dropItem);
        }
    };

    const handleDragEnd = (event: any) => {
        const selectedItem = event.target;
        onMove(selectedItem.dataset.index, dropIndex.current);
        selectedItem.classList.remove('drag-active');
        document.removeEventListener('dragover', handleDocumentDragOver);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    return (
        <Root
            key={source}
            draggable={onMove ? 'true' : undefined}
            onDrag={onMove ? handleDrag : undefined}
            onDragStart={onMove ? handleDragStart : undefined}
            onDragEnd={onMove ? handleDragEnd : undefined}
            onDragOver={onMove ? handleDragOver : undefined}
            data-index={index}
        >
            <DragIndicatorIcon
                className="dragIcon"
                color="disabled"
                fontSize="small"
                sx={{ color: 'neutral.main' }}
            />
            <Typography display="flex" alignItems="center" gap={2}>
                {label}
            </Typography>
            <Checkbox
                checked={selected}
                onChange={onToggle}
                name={index}
                id={`switch_${index}`}
                size="small"
                sx={{ mr: 0.5, ml: -0.5 }}
            />
        </Root>
    );
};

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    '& svg': {
        cursor: 'move',
    },
    '& p': {
        flex: 1,
    },
    '&.drag-active': {
        background: 'transparent',
        color: 'transparent',
        outline: `1px solid ${theme.palette.action.selected}`,
        '& .MuiSwitch-root, & svg': {
            visibility: 'hidden',
        },
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
}));
