import {FilterPayload, List, Pagination, SortPayload} from 'react-admin';
import {Box, Card, Typography} from '@mui/material';
import React from "react";
import {PostListFields} from "./postListFields";

interface PostListCardProps {
    title: string;
    filter: FilterPayload;
    statusFilterList?: number[];
    attentionContentStatusFilterList?: string[];
    showAttention?: boolean;
    showRedirectButton?: boolean;
    sort?: SortPayload;
    showOnDashboard?: boolean;
}

export const PostListCard = ({
                                 title,
                                 filter,
                                 statusFilterList,
                                 sort,
                             }: PostListCardProps) => {

    const filterForStatus = {
        'PublisherStatus@_in': statusFilterList,
    };

    return (
        <Card
            sx={{
                padding: 7,
            }}
        >
            <Box display="flex" justifyContent="space-between" mb={4.5}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="baseline"
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '1.5rem',
                            fontWeight: '600',
                            lineHeight: '1.5rem',
                        }}
                    >
                        {title}
                    </Typography>

                </Box>
            </Box>
            <List
                disableSyncWithLocation
                actions={false}
                resource="v1_Posts"
                perPage={10}
                filter={{
                    ...filter,
                    ...filterForStatus,
                }}
                sort={sort}
                pagination={
                    <Pagination rowsPerPageOptions={[10, 15]}/>
                }
                sx={{overflow: 'auto'}}
            >
                <PostListFields/>
            </List>
        </Card>
    );
};
