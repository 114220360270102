import {Button, SaveButton, SimpleForm} from "react-admin";
import React, {useState} from "react";
import {ProfileFormCommonFields} from "./profileFormCommonFields";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    TextField as TextFieldMui,
    Typography
} from "@mui/material";
import {ROLE_SUPPLIER, ROLE_WRITER} from "../../../models/role-model";
import {ProfileFormWritterFields} from "./profileFormWriterFields";
import {ProfileFormPublisherFields} from "./profileFormPublisherFields";
import {ProfileFormSecurity} from "./profileFormSecurity";
import {COLORS} from "../../../config/constants";

export const ProfileForm = (props: any) => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const role = identity?.role;
    const [descriptionDelete, setDescriptionDelete] = useState("");
    const [isOpenDelete, setOpenDelete] = useState(false);

    if (isIdentityLoading) return <CircularProgress/>;

    return (
        <SimpleForm toolbar={false}>
            <Grid container spacing={4}>
                <Grid
                    item xs={6} sx={{
                    display: "flex",
                    flexDirection: "column"
                }}
                >
                    <Typography variant="h3" sx={{marginBottom: 5}}>
                        Personal Information
                    </Typography>
                    <ProfileFormCommonFields/>
                    {role === ROLE_WRITER && <ProfileFormWritterFields/>}
                    {role === ROLE_SUPPLIER && <ProfileFormPublisherFields/>}
                    <SaveButton
                        label="Save" icon={<></>} sx={{
                        marginTop: "auto",
                        width: "max-content",
                        padding: "5px 30px",
                        borderRadius: "16px",
                        background: COLORS.LIGHT_BLUE,
                        fontSize: "14px",
                    }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h3" sx={{marginBottom: 5}}>
                        Password Change
                    </Typography>
                    <ProfileFormSecurity/>
                    <Stack direction="column" ml={2} mt={4}>
                        <Typography variant="h3" sx={{marginBottom: "5px"}}>
                            Account
                        </Typography>
                        <Button
                            label="Delete Account"
                            sx={{
                                alignSelf: "end",
                                width: "max-content",
                                marginTop: "10px",
                                padding: "5px 30px",
                                borderRadius: "16px",
                                backgroundColor: COLORS.RED,
                                color: COLORS.WHITE,
                                fontSize: "14px",
                                "&:hover": {
                                    backgroundColor: COLORS.DARK_RED,
                                },
                            }}
                            onClick={() => setOpenDelete(!isOpenDelete)}
                        />
                        <Dialog open={isOpenDelete} onClose={() => setOpenDelete(false)}>
                            <DialogTitle sx={{color: COLORS.RED, paddingBottom: "2px"}}>
                                {"Delete Account"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{paddingBottom: "15px", fontSize: "10px"}}>
                                    {"State your reason for deleting your account"}
                                </DialogContentText>
                                <TextFieldMui
                                    label="Description"
                                    onChange={(e) => {
                                        setDescriptionDelete(e.target.value);
                                    }}
                                    multiline
                                    rows={3}
                                    fullWidth
                                    sx={{minWidth: "300px"}}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    label="SEND"
                                    fullWidth
                                />
                                <Button
                                    variant="outlined"
                                    label="CANCEL"
                                    sx={{border: "none", color: COLORS.BLACK}}
                                    fullWidth
                                    onClick={() => setOpenDelete(false)}
                                />
                            </DialogActions>
                        </Dialog>
                    </Stack>
                </Grid>
            </Grid>
            {/*<Grid container spacing={4} mt={2} justifyContent="space-between" alignItems="end">*/}
            {/*    <Grid item xs={6}>*/}
            {/*        <SaveButton label="Save" icon={<></>} sx={{*/}
            {/*            marginTop: "5px",*/}
            {/*            padding: "5px 30px",*/}
            {/*            borderRadius: "16px",*/}
            {/*            background: COLORS.LIGHT_BLUE,*/}
            {/*            fontSize: "14px"*/}
            {/*        }}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={6}>*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}
        </SimpleForm>
    );
};