import { Typography } from "@mui/material";
import { ReactNode } from "react";

interface PageTitleProps {
    children: ReactNode;
}

const PageTitle = ({ children }: PageTitleProps) => {
    return (
        <Typography
            variant="h1"
            color="text.primary"
            sx={{
                fontSize: "2.5rem",
                fontWeight: "600",
            }}
        >
            {children}
        </Typography>
    );
};
export default PageTitle;
