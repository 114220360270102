import ahrefsLogo from '../../../../layout/images/ahrefs-logo.png';
import MozLogo from '../../../../layout/images/moz-logo.png';
import SemrushLogo from '../../../../layout/images/semrush-logo.png';
import RangeNumberFilter from "./rangeNumberFilter";
import RangeDateFilter from "./rangeDateFilter";
import SearchFilter from "./searchFilter";
import LanguageFilter from './languageFilter';
import CategoryFilter from './categoryFilter';
import NicheFilter from './nicheFilter';
import NullableBooleanFilter from "./nullableBooleanFilter";

export interface FilterProps {
    name: string;
    label: string;
    source: string;
    component: any;
    display: boolean;
    icon?: any;
}

export const FILTERS = [
    {
        name: 'SearchFilter',
        label: 'Search',
        source: 'name',
        component: SearchFilter,
        display: true,
    },
    {
        name: 'PriceFilter',
        label: 'Price',
        source: 'link_price',
        component: RangeNumberFilter,
        display: true,
        sign: '$',
    },
    {
        name: 'OrganicTrafficFilter',
        label: 'Organic Traffic',
        source: 'Website#OrganicTraffic',
        component: RangeNumberFilter,
        display: true,
    },
    {
        name: 'KeywordsFilter',
        label: 'Keywords',
        source: 'Website#keywords',
        component: RangeNumberFilter,
        display: true,
    },
    {
        name: 'AhrefsDRFilter',
        label: 'Ahrefs DR',
        source: 'Website#new_DomainRatingMOZ',
        component: RangeNumberFilter,
        display: true,
        icon: ahrefsLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'ReferringDomainsFilter',
        label: 'Referring Domains',
        source: 'Website#referring_domains_total',
        component: RangeNumberFilter,
        display: true,
    },
    {
        name: 'OutgoingDomains',
        label: 'Outgoing Domains',
        source: 'Website#OutgoingDomains',
        component: RangeNumberFilter,
        display: true,
    },
    {
        name: 'SemrushFilter',
        label: 'Authority Score',
        source: 'Website#SemrushAS',
        component: RangeNumberFilter,
        display: true,
        icon: SemrushLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'MOZDaFilter',
        label: 'Domain Authority',
        component: RangeNumberFilter,
        source: 'Website#new_MozDomainAuthority',
        display: true,
        icon: MozLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'MOZSpamScoreFilter',
        label: 'Spam Score',
        component: RangeNumberFilter,
        source: 'Website#MOZSpamScore',
        display: true,
        icon: MozLogo,
        range: {
            min: 0,
            max: 100,
        },
    },
    {
        name: 'DomainAge',
        label: 'Domain Age (years)',
        source: 'Website#comp_domain_age',
        component: RangeNumberFilter,
        display: true,
        range: {
            min: 1,
            max: 20,
        },
    },
    {
        name: 'published_on',
        label: 'Published On',
        source: 'published_on',
        component: RangeDateFilter,
        display: false,
    },
    {
        name: 'LanguageFilter',
        label: 'Language',
        source: 'LanguageId',
        component: LanguageFilter,
        display: true,
    },
    {
        name: 'MarketPlaceCategories',
        label: 'Categories',
        source: 'MarketPlaceCategories#MarketPlaceCategories#id',
        component: CategoryFilter,
        display: true,
    },
    {
        name: 'RestrictedNiches',
        label: 'Restricted Niches',
        source: 'RestrictedNiches#PostNiches#id',
        component: NicheFilter,
        display: true,
    },
    {
        name: 'HomepageLink',
        label: 'Homepage Link',
        source: 'IsHomepageLink',
        component: NullableBooleanFilter,
        display: false,
        width: 200
    },
    {
        name: 'DoFollow',
        label: 'Do Follow',
        source: 'do_follow',
        component: NullableBooleanFilter,
        display: false,
        width: 150
    },
    {
        name: 'NumberOfLinks',
        label: 'Number of Links',
        source: 'links_to_follow',
        component: RangeNumberFilter,
        display: false,
        range: {
            min: 0,
            max: 3,
        },
    },
];
