import {Box, Stack, Typography} from "@mui/material";
import {DateField, ReferenceField, TextField, useRecordContext} from "react-admin";
import {MyOrders} from "./myOrders";
import {OnlineAssistant} from "./onlineAssistant";
import {PostStatusField} from "../post/PostStatusField";
import {TextFieldWithTooltip} from "../TextFieldWithTooltip";
import {PostListCard} from "../post/PostListCard";
import {ORDER_STATUS_VALUE} from "../../../config/statuses";
import {TooltipLinkField} from "../../general/tooltipLinkField";

const PostListFields = [
  <ReferenceField
    label="Order Status"
    source="new_ContentOrderStatus"
    reference="v1_PostsContentStatus"
    link={false}
    key="new_ContentOrderStatus"
  >
    <PostStatusField />
  </ReferenceField>,
  <ReferenceField source="id" reference="v1_Posts" link="edit" label="Order ID" key={6}>
    <TextField source="id" />
  </ReferenceField>,
  <DateField
    source="ordered_on"
    label="Ordered On"
    locales="en-GB"
    key="ordered_on"
  />,
  <DateField
    source="new_Contentduedate"
    label="Due Date"
    locales="en-GB"
    key="new_Contentduedate"
  />,
  <ReferenceField
    source="website_id"
    reference="v1_Website"
    label="Publisher Site"
    link={false}
    key="website_id"
  >
    <TooltipLinkField
      source="new_name"
      label="Publisher Site"
    />
  </ReferenceField>,
  <TextFieldWithTooltip source="new_Title" label="H1 Title" key="new_Title" />,
];

export const WriterDashboard = () => {
  const { currentUser } = useRecordContext();

  return (
      <Stack pb={8}>
        <Typography variant="h1" mb={7}>
          Hi, {currentUser.data.FullName}
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4.5}>
          <Box gridColumn="span 15" gridRow="span 2" >
            <PostListCard
                title="Orders"
                filter={{
                  writer_id: currentUser.data.id,
                }}
                contentStatusFilterList={[
                  ORDER_STATUS_VALUE.ORDERED,
                  ORDER_STATUS_VALUE.DECLINED,
                ]}
                showRedirectButton
                sort={{ field: "id", order: "DESC" }}
                showOnDashboard
            >
              {PostListFields}
            </PostListCard>
          </Box>
          <Box gridColumn="span 3" gridRow="span 1">
            <MyOrders />
          </Box>
          <Box gridColumn="span 3" gridRow="span 1">
            <OnlineAssistant />
          </Box>
        </Box>
      </Stack>
  );
};
