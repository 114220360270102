import {Link} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import React from "react";
import {useCreatePath} from "react-admin";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {COLORS} from "../../../../config/constants";

export const ActiveCheckoutButton = () => {
    const createPath = useCreatePath();

    return (
        <Button
            startIcon={
                <ShoppingCartCheckoutIcon sx={{color: COLORS.WHITE}}/>
            }
            component={Link}
            to={createPath({resource: 'checkout', type: 'edit'})}
        >
            <Typography color="primary.contrastText" fontSize={14}>
                Checkout
            </Typography>
        </Button>
    )
}