import {Button, useNotify, useRefresh} from "react-admin";
import React, {useState} from "react";
import {v1_Posts} from "../../../../generated/types";
import {sendPostToApi} from "../../../services/api";
import {useAddRecordEvent} from "../../../hooks/useAddRecordEvent";
import {MARKETPLACE_STATUS, PUBLISHER_POST_STATUS} from "../../../config/statuses";

export const PostApproveButton = ({post}: { post: v1_Posts }) => {
    const addRecordEvent = useAddRecordEvent();
    const notify = useNotify();
    const refresh = useRefresh();
    const [proceedingApprove, setProceedingApprove] = useState(false)

    const handleApprove = async () => {
        setProceedingApprove(true);

        const response = await sendPostToApi('marketplace/posts/publisher-approve', {
            post_id: post.id,
        });
        const isSuccessResponse = response.data['success'];

        if (isSuccessResponse) {
            addRecordEvent('PublisherStatusChange', {
                id: post.id,
                PublisherStatus: PUBLISHER_POST_STATUS.CONTENT_CREATION,
            });
            addRecordEvent('MarketplaceStatusChange', {
                id: post.id,
                marketplacestatus_id: MARKETPLACE_STATUS.CONTENT_CREATION,
            });
            notify('adzz.resources.v1_Posts.actions.approve.success', {type: "success"})
            refresh()
        } else {
            setProceedingApprove(false);
            notify('adzz.resources.v1_Posts.actions.approve.error', {type: "warning"})
        }
    }

    return (
        <Button
            label="APPROVE"
            disabled={proceedingApprove}
            sx={{
                padding: "0px 18px",
                width: "200px",
                height: "40px",
                background: "#4caf50",
                color: "#ffffff",
                "&:hover": {
                    backgroundColor: "#388e3c",
                },

            }}
            onClick={handleApprove}
        />
    );
}

