import { Editor } from '@tinymce/tinymce-react';
import { AxiosResponse } from 'axios';
import purify from 'dompurify';
import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';
import { useInput } from 'react-admin';
import { Editor as TinyMCEEditor } from 'tinymce';
import { getNumberOfWords, LangData } from '../../utils/wordCounter';

export interface TinyMceProps {
    source: string;
    setWordCount: Dispatch<SetStateAction<number>>;
}

const TinyMce = ({ setWordCount, source }: TinyMceProps) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const { field } = useInput({ source });

    const onInit = async (_evt: any, editor: TinyMCEEditor) => {
        editorRef.current = editor;
        setWordCount(await getWordCount());
    }

    const onChange = async (newValue: string) => {
        field.onChange(newValue);
        setWordCount(await getWordCount());
    }

    const getWordCount = async (): Promise<number> => {
        const text = editorRef?.current?.getContent({ format: 'text' }).trim() || '';
        const sanitizedText = purify.sanitize(text, { ALLOWED_TAGS: [] });
        const dataLang: AxiosResponse<LangData> | undefined =  await getNumberOfWords(sanitizedText);
        return dataLang?.data.length || 0;
    }

    return (
        <Editor
            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
            init={{
                menubar: false,
                convert_urls: false,
                plugins: [
                    'image',
                    'link',
                    'anchor',
                    'code',
                    'lists',
                    'textcolor blocks',
                    'table',
                ],
                toolbar: [
                    'blocks undo redo styleselect link image media anchor bold italic underline strikethrough forecolor backcolor numlist bullist align outdent indent removeformat code table tabledelete',
                ],
                images_upload_url: `${process.env.REACT_APP_PROXY_BASE}/upload`,
                referrer_policy: 'unsafe-url',
                height: 400,
            }}
            onInit={onInit}
            onEditorChange={onChange}
            textareaName={source}
            value={field.value}
        />
    );
};

export default TinyMce;
