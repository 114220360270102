import { grey } from '@mui/material/colors';

export const raComponents = {
  RaDatagrid: {
    styleOverrides: {
      root: {
        '& .RaDatagrid-rowOdd': {
          backgroundColor: grey[50],
        },
        '& .RaDatagrid-headerCell': {
          backgroundColor: grey[100],
        },
      },
    },
  },
  RaUserMenu: {
    styleOverrides: {
      root: {
        '& .RaUserMenu-userButton': {
          lineHeight: 2.5,
        },
      },
    },
  },
};
