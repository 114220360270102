const StatusDeclinedIcon = () => {
    return (
        <svg
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.69238 2.99414C3.58781 2.99414 2.69238 3.88957 2.69238 4.99414V16.9941C2.69238 18.0987 3.58781 18.9941 4.69238 18.9941H10.6924C11.2447 18.9941 11.6924 19.4419 11.6924 19.9941C11.6924 20.5464 11.2447 20.9941 10.6924 20.9941H4.69238C2.48324 20.9941 0.692383 19.2033 0.692383 16.9941V4.99414C0.692383 2.785 2.48324 0.994141 4.69238 0.994141H12.6924C14.9015 0.994141 16.6924 2.785 16.6924 4.99414V13.3751C16.6924 13.9274 16.2447 14.3751 15.6924 14.3751C15.1401 14.3751 14.6924 13.9274 14.6924 13.3751V4.99414C14.6924 3.88957 13.797 2.99414 12.6924 2.99414H4.69238Z"
                fill="white"
            />
            <path
                d="M4.69238 15.9941C4.69238 15.4419 5.1401 14.9941 5.69238 14.9941H10.6924C11.2447 14.9941 11.6924 15.4419 11.6924 15.9941V15.9941C11.6924 16.5464 11.2447 16.9941 10.6924 16.9941H5.69238C5.1401 16.9941 4.69238 16.5464 4.69238 15.9941V15.9941Z"
                fill="white"
            />
            <rect
                x="4.69238"
                y="10.9941"
                width="8"
                height="2"
                rx="1"
                fill="white"
            />
            <rect
                x="4.69238"
                y="4.99414"
                width="8"
                height="4"
                rx="2"
                fill="white"
            />
            <path
                d="M18.3813 16.7141L17.0813 18.0041L18.3813 19.2941C18.4751 19.3871 18.5494 19.4977 18.6002 19.6195C18.651 19.7414 18.6771 19.8721 18.6771 20.0041C18.6771 20.1361 18.651 20.2668 18.6002 20.3887C18.5494 20.5105 18.4751 20.6211 18.3813 20.7141C18.2884 20.8078 18.1778 20.8822 18.0559 20.933C17.934 20.9838 17.8033 21.0099 17.6713 21.0099C17.5393 21.0099 17.4086 20.9838 17.2867 20.933C17.1649 20.8822 17.0543 20.8078 16.9613 20.7141L15.6713 19.4141L14.3813 20.7141C14.2884 20.8078 14.1778 20.8822 14.0559 20.933C13.934 20.9838 13.8033 21.0099 13.6713 21.0099C13.5393 21.0099 13.4086 20.9838 13.2867 20.933C13.1649 20.8822 13.0543 20.8078 12.9613 20.7141C12.8676 20.6211 12.7932 20.5105 12.7424 20.3887C12.6917 20.2668 12.6655 20.1361 12.6655 20.0041C12.6655 19.8721 12.6917 19.7414 12.7424 19.6195C12.7932 19.4977 12.8676 19.3871 12.9613 19.2941L14.2613 18.0041L12.9613 16.7141C12.773 16.5258 12.6672 16.2704 12.6672 16.0041C12.6672 15.7378 12.773 15.4824 12.9613 15.2941C13.1496 15.1058 13.405 15 13.6713 15C13.9376 15 14.193 15.1058 14.3813 15.2941L15.6713 16.5941L16.9613 15.2941C17.1496 15.1058 17.405 15 17.6713 15C17.9376 15 18.193 15.1058 18.3813 15.2941C18.5696 15.4824 18.6754 15.7378 18.6754 16.0041C18.6754 16.2704 18.5696 16.5258 18.3813 16.7141Z"
                fill="white"
            />
        </svg>
    );
};

export default StatusDeclinedIcon;
