import React, {useEffect, useState} from "react";
import {Grid, Stack, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    Button,
    Labeled,
    ReferenceField,
    required,
    RichTextField,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin";
import {PUBLISHER_POST_STATUS} from "../../../config/statuses";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {COLORS} from "../../../config/constants";
import {urlValidator} from "../../../utils/validators";
import {PostPublishButton} from "./postPublishButton";
import {v1_Posts} from "../../../../generated/types";
import {PostFormReportAnIssue} from "./postFormReportAnIssue";

const SEOTitleMaxlength = 64;
const SEODescriptionMaxlength = 160;

interface FormFieldsProps {
    record?: any;
    label?: string;
    validate?: any;
    onChange?: any;
    idx?: number;
}

interface TextEditorProps {
    record: any;
}

const TextFieldStyles = {
    minHeight: "36px",
    padding: "8.5px 14px",
    backgroundColor: "#FFF",
    color: "text.primary",
    border: 1,
    borderColor: "border.color",
    borderRadius: 1,
    wordBreak: "break-word",
};

const PostFormFields = () => {
    const post = useRecordContext<v1_Posts>();

    const seoTitleCounter = post?.new_SeoTitle?.length ?? 0;
    const seoDescriptionCounter = post?.new_PostDesciption?.length ?? 0;

    const [seoTitleLabel, setSeoTitleLabel] = useState("SEO Title");
    const [seoDescriptionLabel, setSeoDescriptionLabel] =
        useState("SEO Description");

    const publisherStatus = post?.PublisherStatus;

    const isPublished = publisherStatus === PUBLISHER_POST_STATUS.PUBLISHED;
    const isToPublish = publisherStatus === PUBLISHER_POST_STATUS.TO_PUBLISH;

    useEffect(() => {
        setSeoTitleLabel(
            seoTitleCounter > 0
                ? `SEO Title (${seoTitleCounter}/${SEOTitleMaxlength})`
                : "SEO Title",
        );
    }, [seoTitleCounter]);

    useEffect(() => {
        setSeoDescriptionLabel(
            seoDescriptionCounter > 0
                ? `SEO Description (${seoDescriptionCounter}/${SEODescriptionMaxlength})`
                : "SEO Description",
        );
    }, [seoDescriptionCounter]);


    return (
        <>
            <Grid container flexDirection="column">

                {isToPublish &&
                    <Grid container columnSpacing={3} mb={3}>
                        <Grid item xs={10}>
                            <TextInput
                                source="new_name"
                                fullWidth
                                label="Updated Post URL"
                                helperText={false}
                                validate={[urlValidator(), required()]}
                            />
                        </Grid>
                        <PostPublishButton post={post}/>
                    </Grid>

                }

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <TargetUrl
                            idx={1}
                            record={post}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnchorText
                            idx={1}
                        />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <TargetUrl
                            idx={2}
                            record={post}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AnchorText
                            idx={2}
                        />
                    </Grid>
                </Grid>

                <Grid container columnSpacing={3} mb={3}>
                    <Grid item xs={6}>
                        <PostCategory record={post}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ArticleTones record={post}/>
                    </Grid>
                </Grid>

                <Grid mb={3}>
                    <Instructions/>
                </Grid>

                <Grid mb={3}>
                    <Keywords/>
                </Grid>


                {(isToPublish || isPublished) && [
                    <Grid mb={3}>
                        <H1Title record={post}/>
                    </Grid>,
                    <Grid mb={3}>
                        <SeoTitle
                            label={seoTitleLabel}
                            record={post}
                        />
                    </Grid>,
                    <Grid mb={3}>
                        <SeoDescription
                            label={seoDescriptionLabel}
                            record={post}
                        />
                    </Grid>
                ]}

                {(isToPublish || isPublished) && [
                    <PostFormReportAnIssue/>,
                    <Grid>
                        <ContentBody
                            record={post}
                        />
                    </Grid>]}
            </Grid>
        </>
    );
};

function H1Title({record}: FormFieldsProps) {
    const [icon, setIcon] = useState(<ContentCopyIcon/>);

    function copyText(text: string) {
        navigator.clipboard.writeText(text);
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setIcon(<ContentCopyIcon/>);
        }, 3000);
    }

    return (
        <Labeled label="H1 Title" fullWidth>
            <TextInput
                source="new_Title"
                label={""}
                variant="outlined"
                fullWidth
                disabled
                color="primary"
                helperText={false}
                sx={{
                    "&& .MuiInputBase-input": {color: COLORS.BLACK, "-webkit-text-fill-color": COLORS.BLACK}
                }}
                InputProps={{
                    endAdornment: (
                        <Button
                            startIcon={icon}
                            variant="outlined"
                            onClick={() => copyText(record.new_Title)}
                            className="copy-text"
                            sx={{border: "none", minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                        />
                    ),
                }}
            />
        </Labeled>);
}

function Instructions() {
    return (
        <Labeled label="Writing Instructions" fullWidth>
            <RichTextField
                source="new_WritingInstructions"
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function Keywords() {
    return (
        <Labeled label="Requested Keywords" fullWidth>
            <RichTextField
                source="new_Keywords"
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function PostCategory({record}: FormFieldsProps) {
    if (record?.OfferId === null) {
        return (
            <Labeled label="Post Category" fullWidth>
                <Typography component="span" variant="body2" sx={TextFieldStyles}>
                    Not Set
                </Typography>
            </Labeled>
        );
    } else {
        return (
            <Labeled
                label="Post Category" fullWidth
                sx={{".MuiTypography-body2": {...TextFieldStyles, display: "inline-grid", width: "100%"}}}
            >
                <ReferenceField
                    source="OfferId"
                    reference="v1_Offer"
                    link={false}
                >
                    <ReferenceField
                        source="MarketPlaceCategoriesId"
                        reference="v1_MarketPlaceCategories"
                        link={false}
                        emptyText="Not Set"
                    >
                        <TextField
                            emptyText="Not Set" source="CategoryName"
                            sx={{...TextFieldStyles, display: "inline-grid", width: "100%"}}
                        />
                    </ReferenceField>
                </ReferenceField>
            </Labeled>
        );
    }

}

function ArticleTones({record}: FormFieldsProps) {

    if (record?.ArticleTone === null) {
        return (
            <Labeled label="Tone/Style of the Article" fullWidth>
                <Typography component="span" variant="body2" sx={TextFieldStyles}>
                    Not Set
                </Typography>
            </Labeled>
        );
    } else {
        return (
            <Labeled label="Tone/Style of the Article" fullWidth>
                <ReferenceField source="ArticleTone" reference="v1_ArticleTones">
                    <TextField
                        source="value"
                        sx={{...TextFieldStyles, display: "inline-grid", width: "100%"}}
                    />
                </ReferenceField>
            </Labeled>
        );
    }

}

function AnchorText({idx}: FormFieldsProps) {
    return (
        <Labeled label={`Requested anchor text (${idx})`} fullWidth>
            <TextField
                source={`new_Anchortext${idx}`}
                emptyText="Not set"
                sx={TextFieldStyles}
            />
        </Labeled>
    );
}

function TargetUrl({idx, record}: FormFieldsProps) {
    const [icon, setIcon] = useState(<ContentCopyIcon/>);

    function copyText(text: string) {
        navigator.clipboard.writeText(text);
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setIcon(<ContentCopyIcon/>);
        }, 3000);
    }

    return (
        <Labeled label={`Target URL (${idx})`} fullWidth>
            <TextInput
                source={`new_TargetURL${idx}`}
                label={""}
                variant="outlined"
                fullWidth
                disabled
                color="primary"
                helperText={false}
                sx={{
                    "&& .MuiInputBase-input": {color: COLORS.BLACK!, "-webkit-text-fill-color": COLORS.BLACK!}
                }}
                InputProps={{
                    endAdornment: (
                        <Button
                            startIcon={icon}
                            variant="outlined"
                            onClick={() => copyText(record[`new_TargetURL${idx}`])}
                            className="copy-text"
                            sx={{border: "none", minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                        />
                    ),
                }}
            />
        </Labeled>
    );
}

function SeoTitle({label, record}: FormFieldsProps) {
    const [icon, setIcon] = useState(<ContentCopyIcon/>);

    function copyText(text: string) {
        navigator.clipboard.writeText(text);
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setIcon(<ContentCopyIcon/>);
        }, 3000);
    }

    return (
        <Labeled label={label} fullWidth>
            <TextInput
                source="new_SeoTitle"
                label={""}
                variant="outlined"
                fullWidth
                disabled
                color="primary"
                helperText={false}
                sx={{
                    "&& .MuiInputBase-input": {color: COLORS.BLACK, "-webkit-text-fill-color": COLORS.BLACK}
                }}
                InputProps={{
                    endAdornment: (
                        <Button
                            startIcon={icon}
                            variant="outlined"
                            onClick={() => copyText(record.new_SeoTitle)}
                            className="copy-text"
                            sx={{border: "none", minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                        />
                    ),
                }}
            />
        </Labeled>
    );
}

function SeoDescription({label, record}: FormFieldsProps) {
    const [icon, setIcon] = useState(<ContentCopyIcon/>);

    function copyText(text: string) {
        navigator.clipboard.writeText(text);
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setIcon(<ContentCopyIcon/>);
        }, 3000);
    }

    return (<Labeled label={label} fullWidth>
            <TextInput
                source="new_PostDesciption"
                label={""}
                variant="outlined"
                fullWidth
                disabled
                color="primary"
                helperText={false}
                sx={{
                    "&& .MuiInputBase-input": {color: COLORS.BLACK, "-webkit-text-fill-color": COLORS.BLACK}
                }}
                InputProps={{
                    endAdornment: (
                        <Button
                            startIcon={icon}
                            variant="outlined"
                            onClick={() => copyText(record.new_PostDesciption)}
                            className="copy-text"
                            sx={{border: "none", minWidth: "30px", ".MuiButton-startIcon": {marginRight: "0"}}}
                        />
                    ),
                }}
            />
        </Labeled>
    );
}

function ContentBody({record}: TextEditorProps) {
    const [label, setLabel] = useState("Copy HTML");
    const [icon, setIcon] = useState(<UploadFileIcon/>);

    const [labelText, setLabelText] = useState("Copy To Clipboard");
    const [iconText, setIconText] = useState(<ContentCopyIcon/>);

    const stripHtml = (html: string): string => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };

    function copyHtml(html: string) {
        navigator.clipboard.writeText(html);
        setLabel("Copied!");
        setIcon(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setLabel("Copy HTML");
            setIcon(<UploadFileIcon/>);
        }, 3000);
    }

    function copyText(html: string) {
        navigator.clipboard.writeText(stripHtml(html));
        setLabelText("Copied!");
        setIconText(<CheckCircleOutlineIcon/>);
        setTimeout(() => {
            setLabelText("Copy To Clipboard");
            setIconText(<ContentCopyIcon/>);
        }, 3000);
    }

    return (
        <Labeled
            label="Post"
            fullWidth
            className="tinymce-body tinymce-body--read"
        >
            <>
                <div className="tinymce-body__information">
                    <Stack direction="row" mr={2}>
                        <ArticleOutlinedIcon color="info" fontSize="small" sx={{marginRight: 1}}/>
                        <span className="tinymce-body__wordcount-text">
                            Word Count: {record.new_wordcount}
                          </span>
                    </Stack>
                    <div>
                        <Button
                            label={labelText}
                            startIcon={iconText}
                            variant="contained"
                            onClick={() => copyText(record.new_TextHTMLbody)}
                            className="copy-text"
                            sx={{marginRight: "5px"}}
                        />
                    </div>
                    <div>
                        <Button
                            label={label}
                            startIcon={icon}
                            variant="contained"
                            onClick={() => copyHtml(record.new_TextHTMLbody)}
                            className="copy-text"
                        />
                    </div>
                </div>
                <RichTextField
                    source="new_TextHTMLbody"
                    sx={{...TextFieldStyles, ...{paddingTop: "40px"}}}
                    emptyText="Not set"
                />
            </>
        </Labeled>
    );
}

export default PostFormFields;
