import React, {createContext, useEffect, useState} from 'react';

const LOCAL_STORAGE_CART_ITEMS = 'adzz-cart-items';

export interface CartItem {
    id: number;
    label?: string;
    nicheId: number | null;
    stepId: number | null;
    price: number;
    nicheFee: number;
    wordFee: number;
    total: number;
    currency: string;
}

interface CartContextProps {
    cartItems: CartItem[];
    addCartItem: (cartItem: CartItem) => void;
    setNewItems: (newItems: CartItem[]) => void;
    deleteCartItem: (index: number) => void;
    deleteAllItems: () => void
    getCartItem: (id: number) => CartItem | undefined;
    getItemQuantity: (id: number) => number | undefined;
    setNiche: (item: CartItem, nicheId: number | null, additionalPrice: number) => void;
    setWordCount: (item: CartItem, stepId: number | null, additionalPrice: number) => void;
    getSubTotalByCurrency: () => Record<
        string,
        { total: number }
    >;
}

// Create a context to store cart items
export const CartContext = createContext<CartContextProps>({
    cartItems: [] as CartItem[],
    addCartItem: () => {},
    setNewItems: () => {},
    deleteCartItem: () => undefined,
    deleteAllItems: () => undefined,
    getCartItem: () => undefined,
    getItemQuantity: () => undefined,
    setNiche: () => undefined,
    setWordCount: () => undefined,
    getSubTotalByCurrency: () => ({}),
});

export function addItem(cartItem: CartItem, cartItems: CartItem[]) {
    return [...cartItems, cartItem];
}

export function deleteItem(index: number, cartItems: CartItem[]) {
    return cartItems.filter((_, itemIndex) => itemIndex !== index);
}

export const CartContextProvider = ({children}: any) => {
    const [cartItems, setCartItems] = useState<CartItem[]>([]);

    useEffect(() => {
        const storedCartItems = localStorage.getItem(LOCAL_STORAGE_CART_ITEMS);
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);


    const calculateTotal = (cartItem: CartItem) => {
        return cartItem.price + cartItem.nicheFee + cartItem.wordFee;
    }

    const setNewItems = (newCartItems: CartItem[]) => {
        setCartItems(newCartItems);
        saveToLocalStorage(newCartItems)
    };

    const saveToLocalStorage = (cartItems: CartItem[]) => {
        localStorage.setItem(
            LOCAL_STORAGE_CART_ITEMS,
            JSON.stringify(cartItems),
        );
    };

    const addCartItem = (cartItem: CartItem) => {
        setNewItems(addItem(cartItem, cartItems));
    };

    const deleteCartItem = (index: number) => {
        setNewItems(deleteItem(index, cartItems));
    };

    const deleteAllItems = () => {
        setNewItems([]);
    }

    const getCartItem = (itemId: number) => {
        return cartItems.find((item) => item.id === itemId);
    };

    const setNiche = (item: CartItem, nicheId: number | null, additionalPrice: number) => {
        item.nicheId = nicheId;
        item.nicheFee = Math.round(item.price * (additionalPrice / 100));
        item.total = calculateTotal(item);
        setNewItems([...cartItems]);
    };

    const setWordCount = (item: CartItem, stepId: number | null, additionalPrice: number) => {
        item.stepId = stepId;
        item.wordFee = additionalPrice;
        item.total = calculateTotal(item);
        setNewItems([...cartItems]);
    };

    const getItemQuantity = (itemId: number) => {
        return cartItems.filter((item) => item.id === itemId).length;
    };
    const getSubTotalByCurrency = () => {
        // for each currency, sum up the total, sum up the total quantity, and return an array of objects
        // [{currency: 'USD', total: 100, quantity: 2}, {currency: 'EUR', total: 200, quantity: 3}]
        const subTotalByCurrency: Record<string, any> = {};
        cartItems.forEach((item) => {
            if (!subTotalByCurrency[item.currency]) {
                subTotalByCurrency[item.currency] = {
                    total: 0,
                };
            }
            subTotalByCurrency[item.currency].total += item.total
        });
        return subTotalByCurrency;
    };

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addCartItem,
                setNewItems,
                deleteCartItem,
                deleteAllItems,
                getCartItem,
                getItemQuantity,
                setNiche,
                setWordCount,
                getSubTotalByCurrency,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
