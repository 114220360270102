import {useRecordContext} from 'ra-core';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import React from 'react';
import {TypographyProps} from '@mui/material/Typography/Typography';
import {TextFieldTruncateProps} from './truncatedTooltipField';

const stripHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

export const MAX_STRING_LENGTH = 10;

export const truncateString = (props: { text: string; length: number }) => {
    const {text, length} = props;
    return text.slice(0, length);
};

const truncateStyle = {
    backgroundImage: 'linear-gradient(to right, black 75%, transparent)',
    backgroundClip: 'text',
    color: 'transparent',
    WebkitBackgroundClip: 'text',
};

export const TextFieldTruncate = (
    props: TypographyProps & TextFieldTruncateProps
) => {
    const {
        className,
        textValue,
        source,
        limit = props.limit || MAX_STRING_LENGTH,
    } = props;

    const record = useRecordContext(props);
    const value = textValue ? textValue : get(record, source) || '';
    const sanitizedValue = stripHtml(value);
    const shouldTruncate = value.length > limit;
    const resultText = shouldTruncate
        ? truncateString({
            text: sanitizedValue,
            length: limit,
        })
        : sanitizedValue;

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            style={shouldTruncate ? truncateStyle : {}}
            {...props}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: stripHtml(resultText),
                }}
            />
        </Typography>
    );
};
