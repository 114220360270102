import { Badge, Typography } from '@mui/material';
import { Button } from 'react-admin';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useContext } from 'react';
import { CartContext } from '../../context/CartContext';

const CartButton = ({
    handleClick,
}: {
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
    const { cartItems } = useContext(CartContext);
    const cartItemsLength = cartItems.length;
    return (
        <Button
            variant="text"
            onClick={handleClick}
            startIcon={
                cartItemsLength > 0 ? (
                    <Badge badgeContent={cartItemsLength} color="warning">
                        <Typography color="primary.contrastText">
                            <ShoppingCartOutlinedIcon />
                        </Typography>
                    </Badge>
                ) : (
                    <Typography color="primary.contrastText">
                        <ShoppingCartOutlinedIcon />
                    </Typography>
                )
            }
        >
            <Typography color="primary.contrastText">Cart</Typography>
        </Button>
    );
};

export default CartButton;
