import {useRecordContext, WrapperField} from 'react-admin';
import get from 'lodash/get';
import {Box} from '@mui/material';
import Link from '@mui/material/Link/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';

export const LinkField = (props: {
    label: string;
    source: string;
}) => {
    const MAX_STRING_LENGTH = 15;

    const record = useRecordContext();

    const {
        label,
        source,
    } = props;

    const value = get(record, source) || '';

    if (!value) return null;

    const linkUrl = value.startsWith('http') ? value : `https://${value}`;


    return (
        <WrapperField label={label}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                }}
            >
                <Link
                    rel="noreferrer"
                    target="_blank"
                    href={linkUrl}
                    onClick={stopPropagation}
                >
                    {value}
                </Link>
                <OpenInNewIcon
                    sx={{
                        verticalAlign: "bottom",
                        marginLeft: '5px'
                    }}
                    color="info"
                    fontSize="small"
                />
            </Box>
        </WrapperField>
    );
};

const stopPropagation = (e: any) => e.stopPropagation();
