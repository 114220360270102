import {InputAdornment} from '@mui/material';
import {FilterLiveSearch} from 'react-admin';
import {Search} from "@mui/icons-material";

const SearchFilter = () => {
    return (
        <FilterLiveSearch
            source="name"
            label="Search"
            placeholder="Search Offer Name"
            InputLabelProps={{shrink: true}}
            sx={{
                width: 300,
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search/>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchFilter;
