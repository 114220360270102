import {nhost} from '../lib/nhost';
import {getPermissionsFromRoles} from '@react-admin/ra-rbac';
import {roleDefinitions} from './roleDefinitions';
import type {AuthProvider} from 'react-admin';
import type {IUserIdentity} from '../models/identity-model';
import type {RoleName} from '../models/role-model';

const getNhostUser = () => {
    const user = nhost.auth.getUser();
    if (user === null) throw new Error('User not authenticated!');
    return user;
};

export const authProvider: AuthProvider = {

    googleLogin: async () => {
        try {
            const req = await nhost.auth.signIn({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.href}`,
                },
            });

            if (
                req.error != null &&
                (req.error.status < 200 || req.error.status >= 300)
            ) {
                return Promise.reject(req.error);
            }
            localStorage.setItem('role', req.session?.user.defaultRole ?? '');
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },

    emailLogin: async (email: string, password: string) => {
        try {
            const req = await nhost.auth.signIn({
                email,
                password,
            });

            if (
                req.error != null &&
                (req.error.status < 200 || req.error.status >= 300)
            ) {
                return Promise.reject(req.error);
            }
            localStorage.setItem('role', req.session?.user.defaultRole ?? '');
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },

    login: async () => {
        return Promise.resolve();
    },

    logout: () => {
        nhost.auth.signOut();
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    checkAuth: async () => {
        const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
        return isAuthenticated ? Promise.resolve() : Promise.reject();
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: (): Promise<IUserIdentity> => {
        const nhostUser = nhost.auth.getUser();

        if (nhostUser === null) return Promise.reject();

        const raUser = {
            id: Number(nhostUser.metadata.crm_userid),
            fullName: nhostUser.displayName,
            email: nhostUser.email,
            roles: nhostUser.roles as RoleName[],
            role: localStorage.getItem('role') as RoleName,
        };

        return Promise.resolve(raUser);
    },

    getPermissions: async (): Promise<any> => {
        let authStatus = nhost.auth.getAuthenticationStatus();

        // TODO: When the page is reloaded, the connection to nhost is lost.
        //  After the page loads, some time is spent obtaining information about the authorization status.
        //  However, the getPermissions method returns information ahead of time while Authentication Status is Loading.
        //  This workaround waits for the download to finish before returning a response.
        const MAX_ATTEMPTS = 20;
        for (
            let attempts = 0;
            authStatus.isLoading && attempts < MAX_ATTEMPTS;
            attempts++
        ) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            authStatus = nhost.auth.getAuthenticationStatus();
        }

        const hasuraClaims = nhost.auth.getHasuraClaims();

        if (!hasuraClaims) return Promise.reject({status: 401});

        if (!localStorage.getItem('role')) {
            const nhostUser = getNhostUser();
            localStorage.setItem('role', nhostUser.defaultRole ?? '');
        }

        const currentRole = localStorage.getItem('role')
        const userRoles = currentRole ? [currentRole] : []

        return Promise.resolve(
            getPermissionsFromRoles({
                roleDefinitions,
                userRoles,
            })
        );
    },
};
