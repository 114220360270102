import {Typography, Stack, Card, CardContent} from '@mui/material';
import {Loading, useGetOne} from 'react-admin';
import {useGetIdentity} from '../../../../hooks/useGetIdentity';

export const LinksStatus = () => {
    const {data: identity} = useGetIdentity();
    const advertiserId = identity?.id;
    const {data: advertiser, isLoading: advertiserLoading} = useGetOne(
        'v1_ContactBase',
        {
            id: advertiserId,
        },
        {enabled: !!advertiserId}
    );

    if (advertiserLoading) return <Loading />;

    const amountFormatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    return (
        <Card>
            <CardContent>
                <Stack>
                    <Typography variant="h3">Links Status</Typography>
                    <Stack direction="row" spacing={6}>
                        <Stack>
                            <Typography color="text.secondary">
                                In Progress
                            </Typography>
                            <Typography variant="h1">
                                {amountFormatter.format(
                                    advertiser.links_status_in_progress
                                )}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography color="text.secondary">Live</Typography>
                            <Typography variant="h1" color="secondary.main">
                                {amountFormatter.format(
                                    advertiser.links_status_live
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};
