import React from "react";
import {EditBase, EditView, Loading} from "react-admin";
import {ProfileForm} from "./profileForm";
import {Box} from "@mui/material";
import ProfileEditTitle from "./profileEditTitle";
import {nhost} from "../../../lib/nhost";

const ProfileEdit = () => {

    const nhostUser = nhost.auth.getUser();

    if (!nhostUser) return <Loading/>;

    const crmId = Number(nhostUser.metadata.crm_userid);

    return (
        <EditBase
            id={crmId}
            resource="v1_ContactBase"
            redirect={false}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{flexDirection: {xs: "column", md: "row"}}}
            >
                <ProfileEditTitle/>
            </Box>
            <EditView>
                <ProfileForm/>
            </EditView>
        </EditBase>
    );
};

export default ProfileEdit;
