import {Box, Typography, useTheme} from '@mui/material';
import React from 'react';
import {Button} from 'react-admin';
import {Link as LinkDom} from 'react-router-dom';

export const ListHeader = (props: {
    title: string,
    attentionText: string,
    attentionCount: number,
    orderCount: number,
    buttonText: string,
    link: string,
}) => {
    const RequiringAttentionTitle = (props: { attentionCount: number, attentionText: string }) => {
        const theme = useTheme();

        return <Typography
            variant="h4"
            sx={{
                whiteSpace: 'pre-wrap',
                fontSize: '1.125rem',
                lineHeight: '1.125rem',
                fontWeight: '600',
            }}
        >
            {' - '}
            <Typography
                variant="inherit"
                component="span"
            >
                {`${props.attentionCount} ${props.attentionText}`}
            </Typography>
        </Typography>;
    };

    const HeaderTitle = (props: { title: string }) => {
        return <Typography
            variant="h2"
            sx={{
                fontSize: '1.5rem',
                fontWeight: '600',
                lineHeight: '1.5rem',
            }}
        >
            {props.title}
        </Typography>;
    };

    const ViewAllRecordsButton = (props: {
        buttonText: string,
        link: string
    }) => {
        return <Button
            component={LinkDom}
            to={props.link}
            variant="contained"
            label={props.buttonText}
        />;
    };

    return <Box display="flex" justifyContent="space-between" mb={4.5}>
        <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="baseline"
        >
            <HeaderTitle title={props.title}/>
            {!!props.attentionCount && <RequiringAttentionTitle
                                            attentionCount={props.attentionCount}
                                            attentionText={props.attentionText}
                                        />}
        </Box>
        {!!props.orderCount && <ViewAllRecordsButton buttonText={props.buttonText} link={props.link}/>}
    </Box>;
};
