import {required, TextInput} from "react-admin";
import React from "react";
import {Grid} from "@mui/material";

export const ProfileFormCommonFields = (props: any) => {
    return (
        <Grid container spacing={2} rowSpacing={4} mb={4}>
            <Grid item xs={6}>
                <TextInput
                    source="FirstName"
                    label="First name"
                    fullWidth
                    validate={required()}
                    helperText={false}
                    disabled/>
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="LastName"
                    label="Last name"
                    fullWidth
                    validate={required()}
                    helperText={false}
                    disabled/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="EMailAddress1"
                           label="Email"
                           type="email"
                           fullWidth
                           validate={required()}
                           helperText={false}/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="MobilePhone"
                           fullWidth
                           label="Phone Number"
                           helperText={false}/>
            </Grid>
        </Grid>
    );
};