import styled from '@emotion/styled';
import {Stack, Typography} from '@mui/material';
import {Header} from '@react-admin/ra-navigation';
import {useGetIdentity} from '../hooks/useGetIdentity';
import {ReactComponent as Logo} from '../layout/images/logo.svg';
import {MyToolbar} from './myToolbar';

const BrandIcon = styled(Logo)`
    margin-right: ${(props) => props.theme.spacing(2)};
    color: ${(props) => props.theme.sidebar.header.brand.color};
    fill: ${(props) => props.theme.sidebar.header.brand.color};
    width: 32px;
    height: 32px;
`;

const StyledHeader = styled(Header)`
    background-color: ${(props) => props.theme.sidebar.header.background};

    div div div:nth-of-type(3) {
        align-items: center;
    }
`;

const MyAppBar = () => {
    const {data: identity} = useGetIdentity();

    const role = identity?.role;
    let title = '';
    if (role === 'Writer' || role === 'Advertiser') {
        title = role;
    }
    if (role === 'Supplier') {
        title = 'Publisher';
    }
    return (
        <StyledHeader
            position="sticky"
            title={
                <Stack direction="row" alignItems="center">
                    <BrandIcon/>
                    <Typography variant="h4" fontWeight={800}>
                        {title}
                    </Typography>
                </Stack>
            }
            toolbar={<MyToolbar role={role}/>}
        />
    );
};

export default MyAppBar;
