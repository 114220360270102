import LoginIcon from '@mui/icons-material/Login';
import {Box, CircularProgress, Divider, Grid, Stack, Typography} from '@mui/material';
import {type Dispatch, type SetStateAction, useState} from 'react';
import {
    Button,
    email,
    Form,
    Login,
    PasswordInput,
    SaveButton,
    TextInput,
    useAuthProvider,
    useNotify,
} from 'react-admin';
import logo from './images/adzz-logo.png';
import {nhost} from "../lib/nhost";
import {AuthErrorPayload} from "@nhost/nhost-js";
import GoogleIcon from '@mui/icons-material/Google';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const LoginForm = () => {
    const [emailInput, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [typeOfPage, setType] = useState('login');

    function currentPage() {
        switch (typeOfPage) {
            case 'login':
                return <LoginPage
                    emailInput={emailInput}
                    password={password}
                    setPassword={setPassword}
                    setEmail={setEmail}
                    setType={setType}
                />;
            case 'forgot':
                return <ForgotPasswordPage
                    emailInput={emailInput}
                    setEmail={setEmail}
                    setType={setType}
                />;
            case 'sent':
                return <ForgotPasswordLinkSent
                />;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        <Login>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                paddingBottom="30px"
            >
                <img src={logo} alt="logo" style={{maxWidth: 150}}/>
                {currentPage()}
            </Box>
        </Login>
    );
};

const LoginPage = ({emailInput, password, setEmail, setPassword, setType}: {
    emailInput: string,
    password: string,
    setEmail: Dispatch<SetStateAction<string>>,
    setPassword: Dispatch<SetStateAction<string>>,
    setType: Dispatch<SetStateAction<string>>
}) => {

    const [isClickedGoogleAuth, setIsClickedGoogleAuth] = useState(false);
    const {googleLogin, emailLogin} = useAuthProvider();
    const notify = useNotify();

    const handleForgotPassword = async () => {
        console.log(`${window.location.origin}/#/change-password`);
        setType('forgot')
    };

    const handleSubmit = async (e: any) => {
        try {
            await emailLogin(
                emailInput,
                password,
            );

            window.location.reload();
        } catch (error) {
            notify(
                (error as AuthErrorPayload).error === "disabled-user"
                    ? "Your account has been deactivated"
                    : (error as AuthErrorPayload).message ?? "An error occurred",
                {
                    type: "error",
                    autoHideDuration: 4000,
                }
            );
        }
    };

    const handleGoogleAuth = async () => {
        setIsClickedGoogleAuth(true);
        googleLogin();
    };

    return (
        <Stack>
            <Form onSubmit={handleSubmit}>
                <Grid container flexDirection="column" sx={{paddingTop: '20px'}}>
                    <Grid item container flexDirection="column">
                        <TextInput
                            onChange={(e) => setEmail(e.target.value)}
                            source="email"
                            label="Email"
                            value={emailInput}
                            required
                            autoComplete="on"
                            validate={email()}
                        />
                        <PasswordInput
                            source="password"
                            label="Password"
                            value={password}
                            required
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                            helperText={false}
                        />
                    </Grid>
                    <Button label="Forgot password?" onClick={handleForgotPassword} sx={{marginY: 3}}/>
                    <SaveButton label="Login" icon={<LoginIcon/>}/>
                </Grid>
            </Form>
            <Divider sx={{marginTop: 4}} />
            <Button
                style={{marginTop: 20}}
                size="large"
                label={isClickedGoogleAuth ? 'Loading...' : 'Click to Login with Google'}
                variant="contained"
                onClick={handleGoogleAuth}
                startIcon={isClickedGoogleAuth ? <HourglassEmptyIcon /> : <GoogleIcon />}
                disabled={isClickedGoogleAuth}
            />
        </Stack>

    )

}

const ForgotPasswordPage = ({emailInput, setEmail, setType}: {
    emailInput: string,
    setEmail: Dispatch<SetStateAction<string>>,
    setType: Dispatch<SetStateAction<string>>
}) => {

    const notify = useNotify();

    const handleSentToReset = async () => {
        try {
            await nhost.auth.resetPassword({
                email: emailInput,
                options: {redirectTo: '/change-password'}
            });
            setType('sent')
        } catch (error) {
            notify((error as Error).message ?? 'An error occurred', {
                type: 'error',
                autoHideDuration: 4000,
            });
        }
    };

    return (
        <Form onSubmit={handleSentToReset}>
            <Grid container flexDirection="column" sx={{paddingTop: '20px', minWidth: "250px"}}>
                <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    source="email"
                    label="Email"
                    value={emailInput}
                    required
                    autoComplete="on"
                    validate={email()}
                />
                <SaveButton label="Send Link" icon={<></>}/>
            </Grid>
        </Form>

    )

}


const ForgotPasswordLinkSent = () => {

    return (
        <Typography
            textAlign="center"
            maxWidth="300px"
            margin={4}
            paddingTop="20px"
            fontSize={16}
        >
            A link to change your password has been sent to the specified email. Follow the link and change your
            password.
        </Typography>
    )

}

export default LoginForm;
