import {Badge, Box, CircularProgress, IconButton, Typography} from '@mui/material';
import {FunctionField} from 'react-admin';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import React, {useContext, useState} from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {CartContext} from '../../../../context/CartContext';
import {useGetActiveCheckout} from "../../../../hooks/useGetActiveCheckout";

const currencyMapper: Record<string, string> = {
    $: 'USD',
    '€': 'EUR',
    '£': 'GBP',
    '¥': 'JPY',
    '₪': 'ILS',
};

function formatPrice(price: number, currency: string) {
    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyMapper[currency] || 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        useGrouping: true,
    });
}

const PriceField = ({label}: { label: string; source?: string }) => {
    const [displayMode, setDisplayMode] = useState<'price' | 'quantity'>(
        'price',
    );

    const { checkout: activeCheckout, isLoading: isActiveCheckoutLoading } = useGetActiveCheckout();

    const {addCartItem, getItemQuantity} =
        useContext(CartContext);

    if (isActiveCheckoutLoading) return <CircularProgress/>;

    const handleDisplayModeChange = (
        event: any,
        mode: 'price' | 'quantity',
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setDisplayMode(mode);
    };

    const handleAddToCart = (event: any, record: any) => {
        event.preventDefault();
        event.stopPropagation();

        addCartItem({
            id: record.id,
            label: record.name,
            nicheId: null,
            stepId: null,
            price: record.link_price,
            nicheFee: 0,
            wordFee: 0,
            total: record.link_price,
            currency: '$',
        });
    };

    const hasActiveCheckout = !!activeCheckout;

    return (
        <FunctionField
            label={label}
            sortBy="link_price"
            render={(record?: any) => {
                const quantity = getItemQuantity(record.id) || 0;

                if (displayMode === 'quantity') {
                    return (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton
                                onClick={(event) =>
                                    handleDisplayModeChange(event, 'price')
                                }
                                aria-label="like"
                                sx={{
                                    color: 'white',
                                }}
                            >
                                <AddShoppingCartIcon/>
                            </IconButton>
                            <Box display="flex" alignItems="center">
                                <Typography color="white" fontWeight={900}>
                                    {quantity}
                                </Typography>

                                <IconButton
                                    aria-label="like"
                                    onClick={(event) =>
                                        handleAddToCart(event, record)
                                    }
                                    sx={{
                                        color: 'white',
                                    }}
                                >
                                    <AddBoxOutlinedIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    );
                }

                return (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography color="white" fontWeight={900}>
                            {formatPrice(
                                record?.link_price,
                                record?.currency,
                            )}
                        </Typography>
                        {!hasActiveCheckout && <IconButton
                            onClick={(event) =>
                                handleDisplayModeChange(event, 'quantity')
                            }
                            aria-label="like"
                            sx={{
                                color: 'white',
                            }}
                        >
                            <Badge
                                variant="dot"
                                invisible={quantity < 1}
                                color="warning"
                            >
                                <AddShoppingCartIcon/>
                            </Badge>
                        </IconButton>}

                    </Box>
                );
            }}
        />
    );
};

export default PriceField;
