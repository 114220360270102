import {Box, Typography} from '@mui/material';
import {Button} from 'react-admin';
import {Link} from 'react-router-dom';

export const EmptyList = (props: {
    titleText: string,
    subtitleText: string,
    buttonText: string,
    link?: string,
    onClick?: () => void;
}) => {
    const Title = () => {
        return <Typography
            variant="h2"
            sx={{
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                color: '#007DBA',
            }}
        >
            {props.titleText}
        </Typography>;
    };

    const Subtitle = () => {
        return <Typography
            variant="h2"
            sx={{
                fontSize: '1rem',
                lineHeight: '2.5rem',
                color: '#000',
            }}
        >
            {props.subtitleText}
        </Typography>;
    };

    const CreateRecordButton = () => {
        const {link = '#/'} = props;

        return <Button
            component={Link}
            to={link}
            variant="contained"
            color="success"
            size="large"
            sx={{
                padding: '10px 70px',
            }}
            label={props.buttonText}
            onClick={props.onClick}
        />
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Title/>
            <Subtitle/>
            <CreateRecordButton/>
        </Box>
    );
};
