import {
    Box,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Popover,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as React from "react";
import {useRef, useState} from "react";
import {useSavedQueryDatagridInfo} from "../../../hooks/useSavedQueryPreferences";
import FilterListIcon from '@mui/icons-material/FilterList';
import {useNavigate} from "react-router-dom";
import {stringify} from "query-string";
import {AddSavedQueryIconButton, SavedQuery} from "react-admin";
import ClearIcon from '@mui/icons-material/Clear';
import isEqual from "lodash/isEqual";
import {useListContext} from "ra-core";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {COLORS} from "../../../config/constants";

export const MarketplaceSavedQueries = ({setNewResetKey}: {
    setNewResetKey: () => void
}) => {
    const {activeSavedQuery, validSavedQueries, setSavedQueries} = useSavedQueryDatagridInfo();
    const navigate = useNavigate();
    const {filterValues} = useListContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const outlinedInputRef = useRef<HTMLInputElement>(null);
    const [inputWidth, setInputWidth] = useState(0);

    const hasFilterValues = !isEqual(filterValues, {});

    const handleClick = (event: any) => {
        if (outlinedInputRef.current) {
            setInputWidth(outlinedInputRef.current.offsetWidth);
            console.log(inputWidth)
        }
        setAnchorEl(event.currentTarget);
        setIsClicked(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsClicked(false);
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const handleClearSavedQuery = (event: React.MouseEvent) => {
        event.stopPropagation();
        navigate({
            search: stringify({
                filter: JSON.stringify({}),
            }),
        });
        setNewResetKey();
    }

    const deletePreset = (preset: SavedQuery) => {
        if (activeSavedQuery?.value === preset) {
            navigate({
                search: stringify({
                    filter: JSON.stringify({}),
                }),
            });
        }
        const updatedSavedQueries = validSavedQueries.filter((item: SavedQuery) => item.value !== preset);
        setSavedQueries(updatedSavedQueries);
        handleClose();
        setNewResetKey();
    }

    return (
        <>
            <FormControl>
                {activeSavedQuery && <InputLabel htmlFor="component-outlined">Preset Filters</InputLabel>}
                <OutlinedInput
                    value={activeSavedQuery?.label || ''}
                    label={activeSavedQuery ? "Preset Filters" : undefined}
                    placeholder="Preset Filters"
                    inputRef={inputRef}
                    ref={outlinedInputRef}
                    id="component-outlined"
                    startAdornment={
                        <FilterListIcon sx={{marginRight: 1}}/>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {activeSavedQuery &&
                                <ClearIcon sx={{fontSize: 20, cursor: 'default'}} onClick={handleClearSavedQuery}/>}
                            {isClicked ? <ArrowDropUpIcon sx={{cursor: 'default'}}/> :
                                <ArrowDropDownIcon sx={{cursor: 'default'}}/>}
                        </InputAdornment>
                    }
                    onClick={handleClick}
                    size="small"
                    sx={{
                        'input': {
                            color: 'black !important',
                            '-webkit-text-fill-color': 'black !important',
                            caretColor: 'transparent',
                            cursor: 'default'
                        }
                    }}
                />
            </FormControl>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '.MuiPaper-root': {width: `${inputWidth}px`}
                }}
            >
                <Box
                    display="flex" flexDirection="column"
                    sx={{
                        margin: 1,
                        borderRadius: '5px',
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)"
                    }}
                >
                    {validSavedQueries.map((savedQuery, index) => (
                        <SavedQueryListItem
                            label={savedQuery.label}
                            value={savedQuery.value}
                            handleClose={handleClose}
                            setNewResetKey={setNewResetKey}
                            deletePreset={deletePreset}
                        />
                    ))}
                    {(!activeSavedQuery && hasFilterValues) &&
                        <Box borderTop={`2px solid ${COLORS.LIGHT_GREY}`} display="flex" gap={2} flexDirection="row" alignItems="center">
                            <AddSavedQueryIconButton/>
                            <Typography>
                                Create New Preset
                            </Typography>
                        </Box>
                    }
                </Box>
            </Popover>
        </>
    )

}

const SavedQueryListItem = ({label, value, handleClose, setNewResetKey, deletePreset}: {
    label: string,
    value: any,
    handleClose: () => void,
    setNewResetKey: () => void
    deletePreset: (preset: SavedQuery) => void
}) => {
    const navigate = useNavigate();

    const addFilter = (): void => {
        handleClose();
        navigate({
            search: stringify({
                filter: JSON.stringify(value.filter),
                sort: value.sort.field,
                order: value.sort.order,
                page: 1,
                perPage: value.perPage,
                displayedFilters: JSON.stringify(value.displayedFilters),
            }),
        });
        setNewResetKey();
    };

    const handleDeletePreset = (event: React.MouseEvent) => {
        event.stopPropagation();
        deletePreset(value);
    }

    return (
        <Input
            sx={{
                padding: 2,
                cursor: 'default',
                'input': {
                    color: 'black !important',
                    '-webkit-text-fill-color': 'black !important',
                    caretColor: 'transparent',
                    cursor: 'default'
                },
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: COLORS.LIGHT_GREY
                },
            }}
            value={label}
            onClick={addFilter}
            size={"small"}
            readOnly
            disableUnderline
            endAdornment={
                <Tooltip title="Delete preset" placement="top-start" arrow>
                    <DeleteOutlineOutlinedIcon
                        onClick={handleDeletePreset} sx={{marginLeft: 2, fontSize: 16, color: COLORS.RED}}
                    />
                </Tooltip>

            }
        />
    )
}
