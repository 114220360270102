import {Card, CircularProgress, Typography} from "@mui/material"
import React from "react";
import {useGetUserBalance} from "../../../hooks/useGetUserBalance";
import {COLORS} from "../../../config/constants";

export const BillingBalance = () => {
    const {balance, isLoading: isBalanceLoading} = useGetUserBalance();

    if (isBalanceLoading) return <CircularProgress/>;

    const currentDate = new Date().toLocaleDateString('en-GB');

    return(
        <Card sx={{gap: 2, padding: 5, display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column'}}>
            <Typography
                fontSize={22}
                fontWeight={'600'}
            >
                Current Balance
            </Typography>
            <Typography
                sx={{color: COLORS.GREEN}}
                fontSize={36}
                fontWeight={'600'}
            >
                $ {balance}
            </Typography>
            <Typography
                sx={{color: COLORS.BLUE}}
                fontSize={14}
                fontWeight={'600'}
            >
                {currentDate}
            </Typography>
        </Card>
    )
}