import { Box, Typography } from '@mui/material';
import { EventRecord, Timeline } from '@react-admin/ra-audit-log';
import { useRecordContext, useResourceContext } from 'react-admin';
import { useRecordEvents } from '../../../hooks/useRecordEvents';
import { EventsAndCommentsTimelineItem } from './eventsAndCommentsTimelineItem';
import { EventTimelineGroup } from './eventTimelineGroup';
import { groupByDay } from './groupByDay';
import { splitUpdateEventsByField } from './splitUpdateEventsByField';

export const EventTimeline = ({
  eventResource = 'v1_Events',
  commmentsFilter,
  otherEventsFilter,
  otherEventsClientSideFilter = () => true,
}: {
  eventResource?: string;
  commmentsFilter?: any;
  otherEventsFilter?: any;
  otherEventsClientSideFilter?: (event: EventRecord) => boolean;
}) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const { data: comments, isLoading: isLoadingComments } = useRecordEvents({
    eventResource,
    resource,
    record,
    filter: { action: 'comment', ...commmentsFilter },
  });
  const { data: otherEvents, isLoading: isLoadingOtherEvents } =
    useRecordEvents({
      eventResource,
      resource,
      record,
      filter: { 'action@_neq': 'comment', ...otherEventsFilter },
    });
  const records = [
    ...(comments || []),
    ...(splitUpdateEventsByField(otherEvents) || []).filter(
      otherEventsClientSideFilter
    ),
  ];
  const isLoading = isLoadingComments || isLoadingOtherEvents;
  return (
    <Box sx={{ mt: 4 }}>
      {!isLoading && !records?.length ? (
        <Typography>There are no comments yet</Typography>
      ) : (
        <Timeline
          isLoading={isLoading}
          records={records}
          groupLogs={groupByDay}
        >
          <EventTimelineGroup>
            <EventsAndCommentsTimelineItem />
          </EventTimelineGroup>
        </Timeline>
      )}
    </Box>
  );
};
