import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {useGetIdentity} from 'react-admin';
import {notificationSocketController} from '../services/socket';
import {sendToNotificationApi} from '../services/api';
import {HttpMethod, NOTIFICATION_SOCKET_EVENTS} from '../config/constants';
import {nhost} from "../lib/nhost";

type NotificationContextProperties = {
    children: React.ReactNode;
};
type NotificationContextType = {
    notificationList: Array<NotificationMessage>;
    unreadNotifications: Array<NotificationMessage>;
    unreadNotificationsCounter?: number;
    isNotificationModalOpen: boolean;
    handleToggleNotificationModal: () => void;
    handleCloseNotificationModal: () => void;
    removeNotificationFromList: (notification: NotificationMessage) => void;
    removeUnreadNotificationFromList: (notification: NotificationMessage) => void;
};

export type NotificationMessage = {
    _id: string;
    date: Date;
    message: string;
    user_id: string;
    readByUser: Array<string>;
    link?: {
        resource: string;
        id: number;
    };
};

const NotificationContext = createContext<NotificationContextType>({
    notificationList: [],
    unreadNotifications: [],
    isNotificationModalOpen: false,
    handleToggleNotificationModal: () => {
    },
    handleCloseNotificationModal: () => {
    },
    removeNotificationFromList: () => {
    },
    removeUnreadNotificationFromList: () => {
    },
});

const NotificationContextProvider = (properties: NotificationContextProperties) => {
    const {children} = properties;
    const [notificationList, setNotificationList] = useState<Array<NotificationMessage>>([]);
    const [unreadNotifications, setuUnreadNotifications] = useState<Array<NotificationMessage>>([]);
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const handleToggleNotificationModal = useCallback(() => setIsNotificationModalOpen((value) => !value), []);
    const handleCloseNotificationModal = useCallback(() => setIsNotificationModalOpen(false), []);
    const {data: identity} = useGetIdentity();
    const nhostId = useMemo(() => {
        const nhostUser = nhost.auth.getUser();
        if (nhostUser) {
            return nhostUser.id;
        }
        return null;
    }, [identity]);

    const removeNotificationFromList = (notification: NotificationMessage) => {
        setNotificationList((prev) => prev.filter((n) => n !== notification));
    };

    const removeUnreadNotificationFromList = (notification: NotificationMessage) => {
        setuUnreadNotifications((prev) => prev.filter((n) => n !== notification));
    };

    const setInitialNotifications = async (nhostId: string) => {
        const notificatonsList = await sendToNotificationApi({
            endpoint: nhostId,
            method: HttpMethod.GET,
        });
        setNotificationList(notificatonsList);
    };

    const unreadNotificationsCounter = useMemo(() => {
        if (!unreadNotifications) {
            return 0;
        }
        return unreadNotifications.length;
    }, [unreadNotifications]);

    useEffect(() => {
        if (!notificationSocketController.isConnected() && nhostId) {
            notificationSocketController.connect({});
            notificationSocketController.on(
                NOTIFICATION_SOCKET_EVENTS.NEW_NOTIFICATION,
                (notificationMessage: NotificationMessage) => {
                    setNotificationList((prev) => [notificationMessage, ...prev]);
                }
            );

            void setInitialNotifications(nhostId);
        }
        if (notificationSocketController.isConnected() && !nhostId) {
            notificationSocketController.disconnect();
        }
        return () => {
            notificationSocketController.disconnect();
        };
    }, [nhostId]);

    useEffect(() => {
        if (!nhostId) {
            setuUnreadNotifications([]);
        }
        if (nhostId != null) {
            setuUnreadNotifications(
                notificationList.filter(
                    (notification: NotificationMessage) => !notification.readByUser.includes(nhostId)
                )
            );
        }
    }, [nhostId, notificationList]);

    const value = useMemo(
        () => ({
            notificationList,
            unreadNotificationsCounter,
            unreadNotifications,
            handleCloseNotificationModal,
            handleToggleNotificationModal,
            isNotificationModalOpen,
            removeNotificationFromList,
            removeUnreadNotificationFromList
        }),
        [
            notificationList,
            unreadNotificationsCounter,
            unreadNotifications,
            handleCloseNotificationModal,
            handleToggleNotificationModal,
            isNotificationModalOpen,
        ]
    );
    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export {NotificationContext, NotificationContextProvider};
