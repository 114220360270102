import {MARKETPLACE_STATUS, OFFER_STATUS, PUBLISHER_POST_STATUS} from '../../config/statuses';
import CheckIcon from '@mui/icons-material/Check';
import {COLORS} from "../../config/constants";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export const VARIANT = {
    OUTLINED: 'outlined' as const,
    FILLED: 'filled' as const,
}

export const COLOR = {
    WARNING: 'warning' as const,
    ERROR: 'error' as const,
    INFO: 'info' as const,
    SUCCESS: 'success' as const,
    DEFAULT: 'default' as const,
    PRIMARY: 'primary' as const,
    SECONDARY: 'secondary' as const,
}

export const PUBLISHER_ORDER_STATUS_CHIP_MAP = {
    [PUBLISHER_POST_STATUS.APPROVE_ORDER]: {color: COLORS.DARK_RED, variant: VARIANT.FILLED},
    [PUBLISHER_POST_STATUS.DECLINED_ORDER]: {color: COLORS.RED, variant: VARIANT.FILLED},
    [PUBLISHER_POST_STATUS.CONTENT_CREATION]: {color: COLORS.LIGHT_BLUE, variant: VARIANT.FILLED},
    [PUBLISHER_POST_STATUS.TO_PUBLISH]: {color: COLORS.ORANGE, variant: VARIANT.FILLED},
    [PUBLISHER_POST_STATUS.PUBLISHED]: {color: COLORS.GREEN, variant: VARIANT.FILLED, icon: <CheckIcon style={{color: COLORS.WHITE}}/>},
    [PUBLISHER_POST_STATUS.CANCELLED]: {color: COLORS.RED, variant: VARIANT.FILLED, icon: <CancelRoundedIcon style={{color: COLORS.WHITE}}/>}
};

export const MARKETPLACE_ORDER_STATUS_CHIP_MAP = {
    [MARKETPLACE_STATUS.PROVIDE_DETAILS]: {color: COLORS.RED, variant: VARIANT.FILLED, textColor: COLORS.WHITE, borderColor: COLORS.RED},
    [MARKETPLACE_STATUS.ORDER_PLACED]: {color: COLORS.WHITE, variant: VARIANT.OUTLINED, textColor: COLORS.GREEN, borderColor: COLORS.GREEN},
    [MARKETPLACE_STATUS.CONTENT_CREATION]: {color: COLORS.LIGHT_BLUE, variant: VARIANT.FILLED},
    [MARKETPLACE_STATUS.WAITING_APPROVAL]: {color: COLORS.WHITE, variant: VARIANT.OUTLINED, textColor: COLORS.RED, borderColor: COLORS.RED},
    [MARKETPLACE_STATUS.SENT_TO_PUBLISHER]: {color: COLORS.ORANGE, variant: VARIANT.FILLED},
    [MARKETPLACE_STATUS.PUBLISHED]: {color: COLORS.GREEN, variant: VARIANT.FILLED, icon: <CheckIcon style={{color: COLORS.WHITE}}/>},
    [MARKETPLACE_STATUS.CANCELLED]: {color: COLORS.RED, variant: VARIANT.FILLED, icon: <CancelRoundedIcon style={{color: COLORS.WHITE}}/>}
};

export const OFFER_STATUS_CHIP_MAP = {
    [OFFER_STATUS.DECLINED]: {color: COLORS.RED, variant: VARIANT.FILLED},
    [OFFER_STATUS.PUBLISHED]: {color: COLORS.GREEN, variant: VARIANT.FILLED},
    [OFFER_STATUS.IN_PROGRESS]: {color: COLORS.LIGHT_BLUE, variant: VARIANT.FILLED},
    [OFFER_STATUS.WAITING_APPROVAL]: {color: COLORS.DARK_RED, variant: VARIANT.FILLED},
    [OFFER_STATUS.UNPUBLISHED]: {color: COLORS.DARK_RED, variant: VARIANT.FILLED},
}

export const MARKETPLACE_STATUS_CHIP_MAP = {
    [MARKETPLACE_STATUS.WAITING_APPROVAL]: {color: COLOR.WARNING, variant: VARIANT.OUTLINED},
    [MARKETPLACE_STATUS.PROVIDE_DETAILS]: {color: COLOR.WARNING, variant: VARIANT.FILLED},
}
