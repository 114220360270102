import {
    Datagrid,
    DateField,
    FunctionField,
    ListContextProvider,
    Loading,
    Pagination,
    SortPayload,
    TextField,
    useList
} from "react-admin";
import React from "react";
import {useGetUserBillingList} from "../../../hooks/useGetUserBillingList";
import {Typography} from "@mui/material";
import {COLORS} from "../../../config/constants";

interface BillingListCardProps {
    title: string;
    type: string;
    sort?: SortPayload;
    showBalance?: boolean;
}

export const BilingListCard = ({
                                   title,
                                   type,
                                   showBalance
                               }: BillingListCardProps) => {

    const {list: billingList, isLoading: isBillingListLoading} = useGetUserBillingList(type);

    const listContext = useList({
        data: billingList,
        isLoading: isBillingListLoading,
        sort: {field: "date", order: "DESC"},
        perPage: 10,
    });

    if (isBillingListLoading) return <Loading/>

    return (
        <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false}>
                <DateField source="date" locales="en-GB"/>
                <FunctionField
                    source="type"
                    label="Activity"
                    render={(record: any) => {
                        const isOrder = record?.type === 'Order'
                        return (<Typography
                            sx={{color: isOrder ? COLORS.RED : COLORS.GREEN, fontWeight: 600}}
                        >
                            {record?.type}
                        </Typography>)
                    }
                    }
                />
                <TextField source="method"/>
                <FunctionField
                    source="amount"
                    label="Amount"
                    render={(record: any) => {
                        const isMinus = record?.amount < 0
                        return (<Typography
                            sx={{color: isMinus ? COLORS.RED : COLORS.GREEN, fontWeight: 600}}
                        >
                            {isMinus ? '-' : '+'} $ {Math.abs(record?.amount)}
                        </Typography>)
                    }
                    }
                />
                {showBalance && <FunctionField
                    source="balance"
                    label="Balance"
                    render={(record: any) => <Typography
                        sx={{fontWeight: 'bold'}}
                    >
                        $ {record?.balance}
                    </Typography>
                    }
                />}
                <TextField source="id" label="Transaction ID"/>
            </Datagrid>
            <Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>
        </ListContextProvider>
    );
};
