import {Chip} from "@mui/material";
import {COLORS} from "../../../config/constants";
import {PUBLISHER_POST_STATUS} from "../../../config/statuses";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const STATUSES = {
    [PUBLISHER_POST_STATUS.APPROVE_ORDER]:
        {
            value: "Approve Order", color: COLORS.DARK_RED,
            icon: <WarningAmberRoundedIcon style={{color: COLORS.WHITE}}/>
        },
    [PUBLISHER_POST_STATUS.DECLINED_ORDER]:
        {
            value: "Declined Order", color: COLORS.RED,
            icon: <></>
        },
    [PUBLISHER_POST_STATUS.CONTENT_CREATION]:
        {
            value: "Content Creation", color: COLORS.LIGHT_BLUE,
            icon: <PostAddRoundedIcon style={{color: COLORS.WHITE}}/>
        },
    [PUBLISHER_POST_STATUS.TO_PUBLISH]:
        {
            value: "To Publish", color: COLORS.ORANGE,
            icon: <CloudUploadRoundedIcon style={{color: COLORS.WHITE}}/>
        },
    [PUBLISHER_POST_STATUS.PUBLISHED]:
        {
            value: "Published", color: COLORS.GREEN,
            icon: <DoneRoundedIcon style={{color: COLORS.WHITE}}/>
        },
    [PUBLISHER_POST_STATUS.CANCELLED]:
        {
            value: "Cancelled", color: COLORS.RED,
            icon: <CancelRoundedIcon style={{color: COLORS.WHITE}}/>
        },
};

const timelineSx = {
    minWidth: '100px',
    borderRadius: '16px',
    borderWidth: '2px',
    cursor: 'pointer',
    height: '20px'
}

const editSx = {
    minWidth: "120px",
    borderRadius: "16px",
    borderWidth: "2px",
    padding: "10px",
    fontSize: '16px'
}

export const PostStatusChip = ({statusId, timeline}: { statusId: number, timeline?: boolean }) => {

    const statusSettings = STATUSES[statusId];

    if (!statusId) return null;

    return (
        <Chip
            sx={
                {
                    ...timeline ? timelineSx : editSx,
                    backgroundColor: statusSettings.color,
                    color: COLORS.WHITE,
                }
            }
            label={statusSettings.value}
            variant={"filled"}
            icon={statusSettings.icon}
        />
    );
}