import { useGetIdentity as useRaGetIdentity, UserIdentity } from 'react-admin';
import { QueryObserverResult } from 'react-query';
import { IUserIdentity } from '../models/identity-model';

export const useGetIdentity = (): UseGetIdentityResult => {
  const result = useRaGetIdentity();
  return {
    ...result,
    data: result.data as IUserIdentity | undefined,
  };
};

export type UseGetIdentityResult = {
  isLoading: boolean;
  data?: IUserIdentity;
  error?: Error;
  refetch?: () => Promise<QueryObserverResult<UserIdentity, Error>>;
};
