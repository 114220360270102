import {Card, Typography} from "@mui/material";
import {EventRecord} from "@react-admin/ra-audit-log";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {RoleName} from "../../../models/role-model";
import {CommentForm} from "./commentForm";
import {EventTimeline} from "./eventTimeline";

const getCommentsFilterByRole = (role: RoleName) => {
    switch (role) {
        case "Writer":
            return {
                payload: {
                    notifyWriter: true,
                },
            };
        case "Publisher":
        case "Supplier":
        case "Advertiser":
            return {
                payload: {
                    notifyPublisher: true,
                },
            };
    }
};

const getOtherEventsFilterByRole = (role: RoleName) => {
    switch (role) {
        case "Writer":
            return {
                action: ["PostChangeStatus", "Decline", "Approve"],
            };
        case "Publisher":
        case "Supplier":
            return {
                action: ["offer", "ApproveSM", "PublisherStatusChange"],
            }
        case "Advertiser":
            return {action: ["ApproveSM", "MarketplaceStatusChange"]};
    }
};

const getOtherEventsClientSideFilterByRole = (
    role: RoleName
): ((event: EventRecord) => boolean) => {
    switch (role) {
        case "Writer":
            return () => true;
        case "Publisher":
        case "Supplier":
            return () => true;
        case "Advertiser":
            return () => true;
    }
};

export const EventsAndCommentsTimeline = () => {
    const {data: identity} = useGetIdentity();
    const role = identity?.role;

    if (!role) return null;

    return (
        <Card sx={{padding: 4, width: "25rem"}}>
            <Typography variant="h3">Timeline</Typography>
            <CommentForm
                notifyPublisher={["Publisher", "Supplier", "Advertiser"].includes(role)}
                notifyWriter={role === "Writer"}
                visibilityForm={<></>}
            />
            <EventTimeline
                commmentsFilter={getCommentsFilterByRole(role)}
                otherEventsFilter={getOtherEventsFilterByRole(role)}
                otherEventsClientSideFilter={getOtherEventsClientSideFilterByRole(role)}
            />
        </Card>
    );
};
