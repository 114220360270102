import {DataProvider, localStorageStore, Store} from 'react-admin';
import {nhost} from "../lib/nhost";

const localStore
    = localStorageStore();

interface IEvent {
    created_by: number;
    id: number;
    path: string;
    preference: any[];
}

export let store: Store;

export const initStore = (dataProvider: DataProvider): void => {
    store = {
        ...localStore,
        setItem: (key: string, value: any) => {
            const nhostUser = nhost.auth.getUser();
            if (isSavedSetting(key) && nhostUser) {
                const userId = nhostUser.id;

                dataProvider
                    .getList<IEvent>('v1_PreferencesMarketplace', {
                        filter: {created_by: userId, path: key},
                        pagination: {page: 1, perPage: 10},
                        sort: {field: 'path', order: 'ASC'},
                    })
                    .then((result) => {
                        const {data} = result;
                        if (data.length === 0) {
                            dataProvider.create<IEvent>('v1_PreferencesMarketplace', {
                                data: {
                                    path: key,
                                    created_by: userId,
                                    preference: value,
                                },
                            });
                        } else {
                            dataProvider.update<IEvent>('v1_PreferencesMarketplace', {
                                id: data[0].id,
                                data: {
                                    path: key,
                                    created_by: userId,
                                    preference: value,
                                },
                                previousData: data[0],
                            });
                        }
                    });
            }
            localStore.setItem(key, value);
        },
    };
};

const isSavedColumnsBySavedQuery = (key: string): boolean => /datagrid\..+\.columns/i.test(key);

const isSavedSetting = (key: string): boolean =>
    key.toLowerCase().includes('savedqueries') ||
    key.toLowerCase().includes('datagrid.columns') ||
    isSavedColumnsBySavedQuery(key);
