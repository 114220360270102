import CheckoutTitle from "./checkoutTitle";
import React from "react";
import {EditBase, EditView} from "react-admin";
import {useGetActiveCheckout} from "../../../hooks/useGetActiveCheckout";
import {CircularProgress} from "@mui/material";
import {CheckoutBillingForm} from "./checkoutBillingForm";
import {ReturnToOrderReviewButton} from "./buttons/returnToOrderReviewButton";
import {CHECKOUT_STATUS} from "../../../config/statuses";
import {CheckoutContentDetailForm} from "./checkoutContentDetailForm";
import useRefreshQueryData from "../../../hooks/useRefreshQueryData";

export const CheckoutEdit = () => {
    const {checkout: activeCheckout, isLoading: isActiveCheckoutLoading} = useGetActiveCheckout();

    useRefreshQueryData();

    if (isActiveCheckoutLoading || !activeCheckout) return <CircularProgress/>;

    const CurrentForm = () => {
        switch (activeCheckout.status_id) {
            case CHECKOUT_STATUS.BILLING:
                return <CheckoutBillingForm/>;
            case CHECKOUT_STATUS.CONTENT_DETAILS:
                return <CheckoutContentDetailForm/>;
            default:
                return null;
        }
    }

    return (
        <EditBase
            id={activeCheckout?.id}
            resource="v1_Checkout"
            redirect={false}
        >
            <CheckoutTitle isCreate={false}/>
            <EditView>
                <CurrentForm/>
            </EditView>
            {activeCheckout.status_id === CHECKOUT_STATUS.BILLING
                && <ReturnToOrderReviewButton checkoutId={activeCheckout?.id}/>}
        </EditBase>
    )

}