import {Tooltip, Typography} from '@mui/material';
import type {CardNameProps} from './types';

function getDomain(value: string) {
    const url = new URL(value);
    return url.hostname;
}

export function CardName({url}: CardNameProps) {
    const domainName = getDomain(url);

    return (
        <>
            <Typography variant="body2" color="neutral.main">
                Domain Name
            </Typography>
            <Tooltip
                title={<Typography component="span" variant="body2">
                    {domainName}
                </Typography>} followCursor placement="top-start" sx={{fontSize: 30}}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 600,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {domainName}
                </Typography>
            </Tooltip>
        </>
    );
}
