import Box from '@mui/material/Box';
import {Divider, FormControl, InputAdornment, OutlinedInput, Popover, Typography} from '@mui/material';
import {DateInput, Filter} from 'react-admin';
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {COLORS} from "../../../../config/constants";
import {useListContext} from "ra-core";


type FilterRangeDateProps = {
    label: string;
    source: string;
    labelFrom?: string;
    labelTo?: string;
};

export default function RangeDateFilter(props: FilterRangeDateProps) {

    const {
        label,
        source,
        labelFrom = 'From',
        labelTo = 'To',
    } = props;

    const {filterValues} = useListContext();

    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (filterValues) {
            setFrom(filterValues[`${source}@_gte`]);
            setTo(filterValues[`${source}@_lte`]);
        }
    }, []);

    const formatDate = (date?: string) => {
        if (!date) return;
        const newDate = new Date(date);
        return [String(newDate.getDate()).padStart(2, '0'),
            String(newDate.getMonth() + 1).padStart(2, '0'),
            newDate.getFullYear()].join('/');
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setIsClicked(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsClicked(false);
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const setFieldValue = () => {
        return !from && !to
            ? ''
            : `${formatDate(from) || '...'} - ${formatDate(to) || '...'}`;
    }
    return (
        <>
            <FormControl>
                <OutlinedInput
                    value={setFieldValue()}
                    inputRef={inputRef}
                    id="component-outlined"
                    startAdornment={
                        <Typography marginRight={3} style={{ whiteSpace: 'nowrap', color: COLORS.GREY }}>
                            {label}
                        </Typography>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {isClicked ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        </InputAdornment>
                    }
                    onClick={handleClick}
                    size="small"
                    disabled
                    sx={{
                        maxWidth: '400px',
                        'input': {
                            color: 'black !important',
                            '-webkit-text-fill-color': 'black !important'
                        }
                    }}
                />
            </FormControl>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >

                <Box
                    sx={{
                        margin: 1,
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '5px',
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                        '.RaFilterFormInput-spacer': {display: 'none'},
                    }}
                >
                    <Filter>
                        <DateInput
                            alwaysOn
                            label={labelFrom}
                            source={`${source}@_gte`}
                            sx={{maxWidth: '130px'}}
                            onChange={(event) =>
                                setFrom(event.target.value)
                            }
                        />
                    </Filter>
                    <Divider sx={{marginLeft: '5px'}}/>
                    <Filter>
                        <DateInput
                            alwaysOn
                            label={labelTo}
                            source={`${source}@_lte`}
                            sx={{maxWidth: '130px'}}
                            onChange={(event) =>
                                setTo(event.target.value)
                            }
                        />
                    </Filter>
                </Box>
            </Popover>
        </>
    );
}
