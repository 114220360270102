const StatusOrderedIcon = () => {
    return (
        <svg
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H9C9.55229 18 10 18.4477 10 19C10 19.5523 9.55229 20 9 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V9.38095C16 9.93324 15.5523 10.381 15 10.381C14.4477 10.381 14 9.93324 14 9.38095V4C14 2.89543 13.1046 2 12 2H4Z"
                fill="currentColor"
            />
            <rect x="4" y="14" width="6" height="2" rx="1" fill="currentColor" />
            <rect x="4" y="10" width="8" height="2" rx="1" fill="currentColor" />
            <rect x="4" y="4" width="8" height="4" rx="2" fill="currentColor" />
            <path
                d="M18.71 14.7158L16.29 12.2958C16.1971 12.2021 16.0865 12.1277 15.9646 12.0769C15.8427 12.0261 15.712 12 15.58 12C15.448 12 15.3173 12.0261 15.1954 12.0769C15.0736 12.1277 14.963 12.2021 14.87 12.2958L11.29 15.8758C11.1973 15.9692 11.124 16.0801 11.0742 16.2019C11.0245 16.3237 10.9993 16.4542 11 16.5858V19.0058C11 19.271 11.1054 19.5254 11.2929 19.7129C11.4804 19.9004 11.7348 20.0058 12 20.0058H14.42C14.5516 20.0066 14.6821 19.9813 14.8039 19.9316C14.9258 19.8818 15.0366 19.8085 15.13 19.7158L18.71 16.1358C18.8037 16.0428 18.8781 15.9322 18.9289 15.8104C18.9797 15.6885 19.0058 15.5578 19.0058 15.4258C19.0058 15.2938 18.9797 15.1631 18.9289 15.0412C18.8781 14.9194 18.8037 14.8088 18.71 14.7158ZM14 18.0058H13V17.0058L15.58 14.4258L16.58 15.4258L14 18.0058Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default StatusOrderedIcon;