import {EventRecord} from '@react-admin/ra-audit-log';

const getUpdatedFields = (payload: EventRecord['payload']) => {
    const updatedFields = [];
    if (payload?.previousData) {
        for (const field of Object.keys(payload.previousData)) {
            if (
                payload.data &&
                payload.data[field] !== payload.previousData[field]
            ) {
                updatedFields.push(field);
            }
        }
    }
    return updatedFields;
};

export const splitUpdateEventsByField = (
    events: EventRecord[] | undefined
): EventRecord[] | undefined => {
    if (!events) return undefined;
    return events.reduce((acc, event) => {
        if (event.action === 'update') {
            const updatedFields = getUpdatedFields(event.payload);
            const newEvents = updatedFields.map((field) => ({
                ...event,
                id: `${event.id}-${field}`,
                payload: {
                    ...event.payload,
                    data: {[field]: event.payload.data[field]},
                    previousData: {[field]: event.payload.previousData[field]},
                },
            }));
            return [...acc, ...newEvents];
        }
        return [...acc, event];
    }, [] as EventRecord[]);
};
