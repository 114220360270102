import {useRecordContext, WrapperField} from "react-admin";
import get from "lodash/get";
import React, {Ref} from "react";
import {Box, Tooltip} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography/Typography";
import {TextFieldTruncate} from "./textFieldTruncate";

const stripHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

export const TruncatedTooltipField = (
    props: TypographyProps & TextFieldTruncateProps
) => {
    const {source, textValue, label, limit = 35, sortBy} = props;

    const record = useRecordContext(props);
    const value = textValue ? textValue : get(record, source) || '';

    const TooltipTitle = React.forwardRef(
        (props: any, ref: Ref<HTMLDivElement>) => {
            return (
                <Box
                    ref={ref}
                    component="div"
                    {...props}
                    sx={{whiteSpace: 'nowrap'}}
                >
                    <TextFieldTruncate
                        textValue={textValue}
                        limit={limit}
                        sortBy={sortBy}
                        {...props}
                    />
                </Box>
            );
        }
    );

    return (
        <WrapperField label={label}>
            <Tooltip
                title={
                    // <Typography component="span" variant="body2">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: stripHtml(value),
                            }}
                        />
                    // </Typography>
                }
                placement="top-start"
            >
                <TooltipTitle source={source} />
            </Tooltip>
        </WrapperField>
    );
};

export interface TextFieldTruncateProps {
    source: string;
    textValue?: string;
    label?: string;
    limit?: number;
    sortBy?: string;
}
