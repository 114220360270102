import {Form, Loading, PasswordInput, SaveButton, useNotify, useRedirect, useRefresh} from "react-admin";
import React, {useEffect, useState} from "react";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {nhost} from "../../../lib/nhost";
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";

export const ProfileChangePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openDialog, setOpenDialog] = useState(true);
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (!role) {
            localStorage.setItem('role', nhost.auth.getSession()?.user.defaultRole ?? '');
        }
    }, [identity]);

    if (isIdentityLoading) return <Loading/>

    const handleClose = (_: any, reason: string) => {
        if (reason && reason === 'backdropClick') return;
        setOpenDialog(false);
    };

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            notify('Password and password confirmation do not match!', {type: 'error'})
            return;
        }
        try {
            await nhost.auth.changePassword({newPassword: password});
            redirect('/');
            refresh();
        } catch (error) {
            notify((error as Error).message ?? 'An error occurred', {
                type: 'error',
                autoHideDuration: 4000,
            });
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle fontSize={30}>
                Change Password
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column" gap={4}>
                        <PasswordInput
                            source="password"
                            label="Enter New Password"
                            value={password}
                            required
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                            helperText={false}
                            sx={{marginTop: 3}}
                        />
                        <PasswordInput
                            source="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            required
                            autoComplete="new-password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            helperText={false}
                        />
                        <SaveButton/>
                    </Box>
                </Form>
            </DialogContent>
        </Dialog>

    );
}
