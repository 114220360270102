const WrapperLookAlikeMUI = ({ label }: { label: string }) => {
    return (
        <>
            <span
                style={{
                    color: '#02293B',
                    fontSize: '13px',
                    fontFamily:
                        'Montserrat,Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    fontWeight: 500,
                    lineHeight: '1.4375em',
                    padding: 0,
                    display: 'block',
                    transformOrigin: 'top left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(133% - 24px)',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    WebkitTransform: 'translate(14px, -9px) scale(0.75)',
                    transform: 'translate(14px, -9px) scale(0.75)',
                    WebkitTransition:
                        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                    transition:
                        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                    zIndex: 1,
                    pointerEvents: 'auto',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                }}
            >
                {label}
            </span>
            <fieldset
                aria-hidden="true"
                style={{
                    textAlign: 'left',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    top: '-5px',
                    left: 0,
                    margin: 0,
                    padding: '0 8px',
                    pointerEvents: 'none',
                    borderRadius: 'inherit',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    overflow: 'hidden',
                    minWidth: '0%',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                }}
            >
                <legend
                    style={{
                        float: 'unset',
                        width: 'auto',
                        overflow: 'hidden',
                        display: 'block',
                        padding: 0,
                        paddingRight: '10px',
                        height: '11px',
                        fontSize: '0.75em',
                        visibility: 'hidden',
                        maxWidth: '100%',
                        WebkitTransition:
                            'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
                        transition:
                            'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span>
                        <span>{label}</span>
                    </span>
                </legend>
            </fieldset>
        </>
    );
};

export default WrapperLookAlikeMUI;