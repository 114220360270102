import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {Loading} from "react-admin";
import React from "react";
import {BillingList} from "./billingList";
import {Box, Stack} from "@mui/material";
import PageTitle from "../../../layout/PageTitle";
import {BillingBalance} from "./billingBalance";
import {BillingTopUp} from "./billingTopUp";


export const BillingPage = () => {

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();

    if (isIdentityLoading || !identity) return <Loading/>

    return (
        <Stack gap={3}>
            <PageTitle>Billing</PageTitle>
            <Box display="grid" gridTemplateColumns="repeat(18, 1fr)" gap={4.5}>
                <Box gridColumn="span 15" gridRow="span 3">
                    <BillingList userId={identity.id}/>
                </Box>
                <Box gridColumn="span 3">
                    <BillingBalance/>
                </Box>
                <Box gridColumn="span 3">
                    <BillingTopUp/>
                </Box>
            </Box>
        </Stack>
    )
}