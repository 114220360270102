import {Card, CardContent, Grid} from '@mui/material';
import {CardActions} from './cardActions';
import {StatItem} from './statItem';
import {CardName} from './cardName';
import type {ProjectCardProps} from './types';

export function ProjectCard({project}: ProjectCardProps) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid
                    container
                    justifyContent="space-between"
                    alignContent="flex-start"
                    sx={{mb: '8px'}}
                >
                    <Grid item xs={11}>
                        <CardName url={project.url} />
                    </Grid>
                    <Grid item xs={1}>
                        <CardActions id={project.id} url={project.url} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <StatItem
                            label="Published"
                            counterColor="primary"
                            amount={project?.comp_posts_published ?? 0}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatItem
                            label="In Progress"
                            counterColor="secondary.main"
                            amount={project?.comp_posts_in_progress ?? 0}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatItem
                            label="Spent"
                            counterColor="secondary.main"
                            amount={1111}
                            currency={'$'}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
