import {useState} from 'react';
import {Button, Dialog, Typography} from '@mui/material';
import {ImportCsvDialogContent} from './importCsvDialogContent';
import {ImportCsvSummary} from './importCsvSummary';
import {CsvImportResult} from './types';

export function ImportCsv() {
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
    const [result, setResult] = useState<CsvImportResult | null>(null);
    const toggleDialog = () => {
        setIsImportDialogOpen(!isImportDialogOpen);
    };
    return (
        <>
            <Button
                sx={{
                    lineHeight: 1.5,
                    padding: '4px 5px',
                    boxShadow: 2,
                    gap: 0.5,
                }}
                onClick={toggleDialog}
            >
                <Typography sx={{fontSize: 20, fontWeight: '600'}}>Add in Bulk</Typography>
            </Button>
            <Dialog
                open={isImportDialogOpen}
                onClose={toggleDialog}
                maxWidth="sm"
                fullWidth
            >
                {!result && <ImportCsvDialogContent setResult={setResult} />}
                {result && (
                    <ImportCsvSummary
                        result={result}
                        setResult={setResult}
                        toggleDialog={toggleDialog}
                    />
                )}
            </Dialog>
        </>
    );
}
