import {useEffect, useState} from 'react';
import {useGetIdentity} from "react-admin";
import {sendPostToApi} from "../services/api";

export const useGetUserBillingList = (type: string) => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const [list, setList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (identity) {
            const fetchData = async () => {
                const response = await sendPostToApi('marketplace/transactions/get-list', {
                    user_id: identity?.id,
                    type: type
                });
                setList(response.data);
                setIsLoading(false);
            }
            fetchData();
        }
    }, [identity]);

    return {list, isLoading: isLoading || isIdentityLoading};
};