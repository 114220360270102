import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {sendPostToApi} from "../../../../services/api";
import {Identifier, useNotify, useRefresh} from "react-admin";
import {completePayment} from "../utils/competePayment";

export const PaypalPayment = ({checkoutId, totalPayment, checkoutDetailIds, userId, checkoutPayment}: {
    checkoutId?: Identifier,
    totalPayment: number,
    checkoutDetailIds?: number[],
    userId: number,
    checkoutPayment?: boolean,
}) => {

    const notify = useNotify();
    const refresh = useRefresh();

    const createOrderCallback = async () => {
        try {
            const response = await sendPostToApi('marketplace/paypal/create-order', {
                total_payment: totalPayment,
            });

            const orderData = response['data'];

            if (orderData?.id) {
                return orderData.id;
            } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);

                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error(error);
            return `Could not initiate PayPal Checkout...${error}`;
        }
    }

    const approveOrderCallback = async (data: any, actions: any) => {
        try {
            const response = await sendPostToApi('marketplace/paypal/approve-order', {
                order_id: data.orderID,
                user_id: userId
            });

            const orderData = response['data'];

            const errorDetail = orderData?.details?.[0];

            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
            } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                    `${errorDetail.description} (${orderData.debug_id})`,
                );
            } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                    orderData.purchase_units[0].payments.captures[0];
                console.log(
                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                );
                console.log(
                    "Capture result",
                    orderData,
                    JSON.stringify(orderData, null, 2),
                );

                if (!checkoutPayment) {
                    notify('adzz.resources.v1_Checkout.actions.payment.success', {type: "success"})
                    window.location.reload();
                } else {
                    const isSuccessfulComplete = await completePayment(checkoutId, checkoutDetailIds);

                    if (isSuccessfulComplete) {
                        notify('adzz.resources.v1_Checkout.actions.payment.success', {type: "success"})
                        refresh()
                    } else {
                        notify('adzz.resources.v1_Checkout.actions.payment.error', {type: "warning"})
                    }
                }

            }
        } catch (error) {
            console.error(error);
            notify(`Sorry, your transaction could not be processed...${error}`, {type: "warning"});
        }
    }

    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
        "enable-funding": "card",
        "disable-funding": "paylater,venmo",
        // "data-sdk-integration-source": "integrationbuilder_sc",
    };

    return (
        <div
            className="App" style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
        }}
        >
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{
                        shape: "rect",
                        layout: "vertical",
                    }}
                    className="adzz-paypal-container"
                    createOrder={createOrderCallback}
                    onApprove={async (data, actions) =>
                        await approveOrderCallback(data, actions)}
                />
            </PayPalScriptProvider>
        </div>
    );
};