import { Tooltip, Typography } from "@mui/material";
import { TextFieldProps, useRecordContext } from "react-admin";

export const TextFieldWithTooltip = (props: TextFieldProps) => {
    const { source } = props;
    const record = useRecordContext();
    if (!record || !source) return null;

    return (
        <Tooltip title={record[source]} arrow>
            <Typography
                component="span"
                variant="body2"
                noWrap
                sx={{
                    maxWidth: "200px",
                    display: "inline-block",
                }}
            >
                {record[source]}
            </Typography>
        </Tooltip>
    );
};
