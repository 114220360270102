import {CircularProgress} from "@mui/material";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {ROLE_ADVERTISER, ROLE_SUPPLIER, ROLE_WRITER} from "../../../models/role-model";
import {Lists as WriterPostList} from "../../writer/post/lists"
import {PostList as PublisherPostList} from "../../publisher/post/postList";
import {PostList as AdvertiserPostList} from "../../advertiser/post/postList";

export const PostListRouter = () => {
    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const role = identity?.role;

    if (isIdentityLoading) return <CircularProgress/>;

    function offerEdit() {
        switch (role) {
            case ROLE_WRITER:
                return <WriterPostList identity={identity}/>;
            case ROLE_SUPPLIER:
                return <PublisherPostList/>;
            case ROLE_ADVERTISER:
                return <AdvertiserPostList/>;
            default:
                return <CircularProgress/>;
        }
    }

    return (
        offerEdit()
    );
};
