import {useRecordContext, WrapperField} from 'react-admin';
import get from 'lodash/get';
import {Box, Tooltip} from '@mui/material';
import Link from '@mui/material/Link/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';

export const TooltipLinkField = (props: {
    label: string;
    source: string;
    showPropertyName?: boolean;
    limit?: number;
    justifyContent?: string
}) => {
    const MAX_STRING_LENGTH = 50;

    const record = useRecordContext();

    const {
        label,
        source,
        showPropertyName = true,
        limit = props.limit || MAX_STRING_LENGTH,
        justifyContent = 'flex-start',
    } = props;

    const value = get(record, source) || '';

    if (!value) return null;

    const linkUrl = value.startsWith('http') ? value : `https://${value}`;

    const shortValue = removeHttps(value);
    const shouldTruncate = shortValue.length > limit;
    const truncatedValue = shouldTruncate
        ? truncateString({
            text: shortValue,
            length: limit,
        })
        : shortValue;

    return (
        <WrapperField label={label}>
            <Tooltip title={value} placement="top-start">
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: {justifyContent},
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                            'a': {...shouldTruncate ? truncateStyle : {}},
                        }}
                    >
                        <Link
                            rel="noreferrer"
                            target="_blank"
                            href={linkUrl}
                            onClick={stopPropagation}
                        >
                            {showPropertyName && <>{truncatedValue}</>}
                        </Link>
                    </Box>
                    <OpenInNewIcon
                        sx={{
                            verticalAlign: "bottom",
                            marginLeft: '5px'
                        }}
                        color="info"
                        fontSize="small"
                    />
                </Box>
            </Tooltip>
        </WrapperField>
    );
};

const stopPropagation = (e: any) => e.stopPropagation();

const truncateString = (props: { text: string; length: number }) => {
    const {text, length} = props;
    return text.slice(0, length);
};

const truncateStyle = {
    color: 'transparent',
    WebkitBackgroundClip: 'text',
    backgroundImage: 'linear-gradient(to right, #0288d1 75%, rgba(0,0,0,0))',
    backgroundClip: 'text',
};

const removeHttps = (input: string): string => {
    if (input.startsWith('https://www.')) {
        return input.slice('https://www.'.length);
    }

    if (input.startsWith('https://')) {
        return input.slice('https://'.length);
    }

    return input;
};
