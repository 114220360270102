import Box from '@mui/material/Box';
import {AutocompleteArrayInput, Filter, ReferenceArrayInput} from 'react-admin';
import * as React from "react";
import {Typography} from "@mui/material";
import {COLORS} from "../../../../config/constants";


export default function LanguageFilter() {
    return (
        <Box
            sx={{width: 370, '.RaFilterForm-filterFormInput': {width: '100%'}}}
            position="relative" display="flex"
            alignItems="center"
        >
            <Typography
                sx={{position: 'absolute', left: '10px', top: '8px', whiteSpace: 'nowrap', color: COLORS.GREY}}
            >
                Languages
            </Typography>
            <Filter>
                <ReferenceArrayInput
                    source="LanguageId"
                    reference="v1_new_languagesBase"
                    alwaysOn
                    sort={{field: "new_name", order: "ASC"}}
                    filter={{'new_name@_neq': 'null'}}
                >
                    <AutocompleteArrayInput
                        filterToQuery={(searchText) => ({
                            new_name: searchText,
                        })}
                        optionText="new_name"
                        label={false}
                        helperText={false}
                        fullWidth
                        size="small"
                        sx={{
                            '.MuiFormControl-root': {margin: '0 !important'},
                            '.MuiChip-root': {
                                maxHeight: '18px',
                                '&:first-of-type': {
                                    marginLeft: '85px',
                                },
                            },
                            'legend': {width: 0},
                            '& input': {
                                caretColor: 'transparent',
                            },
                        }}
                    />
                </ReferenceArrayInput>
            </Filter>
        </Box>


    );
}
