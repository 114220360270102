export const roleDefinitions = {
    Writer: [
        { action: '*', resource: 'v1_Posts' },
        { action: '*', resource: 'v1_Balance' },
    ],
    Supplier: [
        { action: '*', resource: 'v1_Offer' },
        { action: '*', resource: 'v1_Posts' },
        { action: '*', resource: 'v1_Balance' },
    ],
    Advertiser: [
        { action: '*', resource: 'v1_Marketplace' },
        { action: ['list'], resource: 'v1_Offer' },
        { action: '*', resource: 'v1_Posts' },
        { action: '*', resource: 'v1_Balance' },
        { action: '*', resource: 'cart' },
    ],
};
