import {mergeTranslations} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {raAuditLogLanguageEnglish} from '@react-admin/ra-audit-log';
import {crmAppLanguageEnglish} from './crmAppLanguageEnglish';

const i18nProvider = polyglotI18nProvider(() =>
  mergeTranslations(
    englishMessages,
    raAuditLogLanguageEnglish,
    crmAppLanguageEnglish
  )
);
export default i18nProvider;
