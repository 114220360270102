import {Box, Typography} from '@mui/material';
import {DateField, NumberField, ReferenceField, TextField, useRecordContext,} from 'react-admin';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import PersonIcon from '@mui/icons-material/Person';

const OrderInformation = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <Box
            display="flex"
            gap={4}
            mb={10}
            sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            <Box display="flex" alignItems="center">
                <DateRangeIcon fontSize="small" sx={{ marginRight: 1 }} />
                <Typography sx={{ marginRight: 1 }}>Ordered On:</Typography>
                <DateField
                    source="ordered_on"
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    sx={{ fontWeight: '600' }}
                    emptyText="No Order Date"
                />
            </Box>

            
            {record.orderedby_id && (
                <Box display="flex" alignItems="center">
                    <PersonIcon fontSize="small" sx={{ marginRight: 1 }} />
                    <Typography sx={{ marginRight: 1 }}>Ordered By:</Typography>
                    <ReferenceField
                        reference="v1_SystemUserBase"    
                        source="orderedby_id"
                        link={false}
                    >
                        <TextField
                            source="FirstName"
                            sx={{ fontWeight: '600' }}
                        />
                    </ReferenceField>
                </Box>
            )}
            
            <Box
                display="flex"
                alignItems="center"
                sx={{ color: 'success.main' }}
            >
                <DateRangeIcon fontSize="small" sx={{ marginRight: 1 }} />
                <Typography sx={{ marginRight: 1 }}>Due Date:</Typography>
                <DateField
                    source="new_Contentduedate"
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    sx={{ fontWeight: '600' }}
                    emptyText="No Due Date"
                />
            </Box>

            <Box display="flex" alignItems="center">
                <LanguageIcon fontSize="small" sx={{ marginRight: 1 }} />
                <Typography sx={{ marginRight: 1 }}>Publisher Site:</Typography>

                {record.website_id ? (
                    <ReferenceField
                        source="website_id"
                        reference="v1_Website"
                        link={false}
                        emptyText="No Site"
                    >
                        <TextField
                            source="new_name"
                            sx={{
                                fontWeight: '600',
                            }}
                        />
                    </ReferenceField>
                ) : (
                    <Typography sx={{ fontWeight: '600' }}>No Site</Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                <LanguageIcon fontSize="small" sx={{ marginRight: 1 }} />
                <Typography sx={{ marginRight: 1 }}>Project:</Typography>

                {record._Project ? (
                    <ReferenceField
                        reference="v1_Project"    
                        source="_Project"
                        link={false}
                        emptyText="No Project"
                    >
                        <TextField
                            source="new_name"
                            sx={{
                                fontWeight: '600',
                            }}
                        />
                    </ReferenceField>
                ) : (
                    <Typography sx={{ fontWeight: '600' }}>No Project</Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                <TranslateIcon fontSize="small" sx={{ marginRight: 1 }} />
                {record.language_id ? (
                    <ReferenceField
                        source="language_id"
                        link="show"
                        reference="v1_new_languagesBase"
                    >
                        <TextField
                            source="new_name"
                            sx={{ fontWeight: '600' }}
                        />
                    </ReferenceField>
                ) : (
                    <Typography sx={{ fontWeight: '600' }}>
                        No default language
                    </Typography>
                )}
            </Box>

            <Box display="flex" alignItems="center">
                <DragHandleIcon fontSize="small" sx={{ marginRight: 1 }} />
                <Typography sx={{ marginRight: 1 }}>Words:</Typography>
                <NumberField
                    source="new_RequestedMinWords"
                    sx={{ fontWeight: '600' }}
                    emptyText="No min words"
                />
            </Box>

            <Box display="flex" alignItems="center">
                <ReferenceField
                    source="currency_id"
                    reference="v1_TransactionCurrencyBase"
                >
                    <TextField
                        source="CurrencySymbol"
                        sx={{marginInlineEnd: 1}}
                    />
                </ReferenceField>
                <NumberField
                    source="new_ContentCost"
                    sx={{ fontWeight: '600' }}
                    emptyText="No price"
                />
            </Box>
        </Box>
    );
};
export default OrderInformation;
