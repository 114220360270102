export const crmAppLanguageEnglish = {
    'ra-audit-log': {
        // TODO: fix error in console when "action" has nested object instead of string
        // move translations from "adzz.timeline" here
        record_event: {
            comment: 'New published comment',
            Decline: '',
            Approve: '',
            PostChangeStatus: '',
            PostLinkCheck: '',
            copyscape: '',
            update: '',
            offer: ''
        },
    },
    ra: {
        action: {
            edit: '',
        },
    },
    adzz: {
        auth: {
            token_expired: 'Token expired. Please log in again.',
        },
        resources: {
            v1_Posts: {
                fields: {
                    new_ContentOrderStatus: 'Content Status',
                    PublisherStatus: 'Publisher Status',
                    marketplacestatus_id: 'Marketplace Status',
                    new_PostStatus: 'Post Status',
                    new_OutreachStatus: 'Outreach Status',
                    new_TextHTMLbody: 'body',
                    writer_id: 'writer',
                    new_name: 'Source URL',
                    approver_id: 'To Be Approved By',
                    requestedfrom_id: 'Requested From',
                    supplier_id: 'Supplier',
                    new_Supplierprice: 'Supplier Price',
                    new_ContentCost: 'Content Cost',
                    new_UploadPrice: 'Upload Price',
                    new_TotalPrice: 'Total Price',
                },
                actions: {
                    updateProject: {
                        success: 'Project has been updated',
                        error: 'Project has not been updated',
                    },
                    approve: {
                        success: 'Post has been approved',
                        error: 'Post has not been approved',
                    },
                    decline: {
                        success: 'Post has been declined',
                        error: 'Post has not been declined',
                    },
                    publish: {
                        success: 'Post has been published',
                        error: 'Post has not been published',
                    }
                }
            },
            v1_Checkout: {
                actions: {
                    create: {
                        success: 'The transition to billing was successful',
                        error: 'An error occurred during the going to billing',
                    },
                    delete: {
                        success: 'The transition to order review was successful',
                        error: 'An error occurred while returning to order review',
                    },
                    payment: {
                        success: 'The payment was successful',
                        error: 'An error occurred while order payment',
                    },
                    complete: {
                        success: 'The checkout complete was successful',
                        error: 'An error occurred while completing checkout',
                    }
                }
            },
            v1_Offer: {
                actions: {
                    changeLike: {
                        error: 'An error occurred while setting like',
                    },
                }
            }
        },
        timeline: {
            Decline: {
                with_comment: 'Declined with comment',
                without_comment: 'Declined'
            },
            Approve: {
                with_comment: 'Approved with comment',
                without_comment: 'Approved'
            },
            PostLinkCheck: 'Post Link Check Succeeded',
            PostChangeStatus: 'Updated',
            update: 'Updated',
            copyscape: {
                success: '%{allpercentmatched}% copied text',
                error: 'Post could not be checked: %{error}',
            },
            offer: 'Offer status updated',
        },
        notification: {
            update: {
                success: '%{name} updated successfully',
                error: 'An error occurred updating %{name}'
            }
        }
    },
};
