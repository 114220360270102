import {useState, useContext} from 'react';
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {DialogContext} from './dialogContext';
import type {CardActionsProps} from './types';

export function CardActions({id, url}: CardActionsProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const clickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const {setEditDialog, setDeleteDialog} = useContext(DialogContext);

    const handleOpenEditDialog = () => {
        closeMenu();
        setEditDialog({isOpen: true, url, id});
    };

    const handleOpenDeleteDialog = () => {
        closeMenu();
        setDeleteDialog({isOpen: true, url, id});
    };

    const menuStyles = {
        '& .MuiPaper-root': {
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px,' +
                'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,' +
                'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,' +
                'rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        },
    };

    return (
        <Box>
            <IconButton
                aria-label="more"
                id={`project-edit-${id}`}
                aria-controls={openMenu ? `project-menu-${id}` : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={clickMenu}
                size="small"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id={`project-menu-${id}`}
                MenuListProps={{
                    'aria-labelledby': `project-edit-${id}`,
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={closeMenu}
                sx={menuStyles}
            >
                <MenuList>
                    <MenuItem onClick={handleOpenEditDialog}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem
                        sx={{color: 'warning.main'}}
                        onClick={handleOpenDeleteDialog}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="warning" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
}
