import {Button, useNotify, useRefresh} from "react-admin";
import React, {useState} from "react";
import {v1_Posts} from "../../../../generated/types";
import {sendPostToApi} from "../../../services/api";
import {useAddRecordEvent} from "../../../hooks/useAddRecordEvent";
import {PUBLISHER_POST_STATUS} from "../../../config/statuses";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField as TextFieldMui
} from "@mui/material";

export const PostDeclineButton = ({post}: { post: v1_Posts }) => {
    const addRecordEvent = useAddRecordEvent();
    const notify = useNotify();
    const refresh = useRefresh();
    const [descriptionDecline, setDescriptionDecline] = useState("");
    const [isOpenDecline, setOpenDecline] = useState(false);

    const handleDecline = async () => {

        const response = await sendPostToApi('marketplace/posts/publisher-decline', {
            post_id: post.id,
        });
        const isSuccessResponse = response.data['success'];

        if (isSuccessResponse) {
            addRecordEvent('PublisherStatusChange', {
                id: post.id,
                PublisherStatus: PUBLISHER_POST_STATUS.DECLINED_ORDER,
                comment: descriptionDecline,
            });
            notify('adzz.resources.v1_Posts.actions.decline.success', {type: "success"})
            refresh()
        } else {
            setOpenDecline(false);
            notify('adzz.resources.v1_Posts.actions.decline.error', {type: "warning"})
        }
    }

    return (
        <>
            <Button
                label="DECLINE"
                sx={{
                    padding: "0px 18px",
                    width: "200px",
                    height: "40px",
                    background: "#9E9E9E",
                    color: "#ffffff",
                    "&:hover": {
                        backgroundColor: "#808080",
                    },
                }}
                onClick={() => setOpenDecline(!isOpenDecline)}
            />
            <Dialog open={isOpenDecline} onClose={() => setOpenDecline(false)}>
                <DialogTitle sx={{color: "#000000", paddingBottom: '2px'}}>
                    {"Decline Order"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{paddingBottom: '15px', fontSize: '10px'}}>
                        {"State your reason for decline order"}
                    </DialogContentText>
                    <TextFieldMui
                        label="Description"
                        onChange={(e) => {
                            setDescriptionDecline(e.target.value);
                        }}
                        multiline
                        rows={3}
                        fullWidth
                        sx={{minWidth: '300px'}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="warning"
                        label="SEND"
                        fullWidth
                        onClick={handleDecline}
                    />
                    <Button
                        variant="outlined"
                        label="CANCEL"
                        sx={{border: 'none', color: '#000000'}}
                        fullWidth
                        onClick={() => setOpenDecline(false)}
                    />
                </DialogActions>
            </Dialog>
        </>

    );
}

