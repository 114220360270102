export const apiKey = process.env.REACT_APP_API_KEY as string;
export const apiProxyBaseUrl = process.env
  .REACT_APP_API_PROXY_BASE_URL as string;
export const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL as string;
// TODO - to be removed or updated
export const azureBlobSAS = process.env.REACT_APP_AZURE_BLOB_SAS as string;
export const azureBlobStorageAccount = process.env
  .REACT_APP_AZURE_BLOB_ACCOUNT as string;
export const azureBlobImageContainer = process.env
  .REACT_APP_AZURE_BLOB_IMAGE_CONTAINER as string;
export const nhostSubdomain = process.env.REACT_APP_NHOST_SUBDOMAIN as string;
export const nhostRegion = process.env.REACT_APP_NHOST_REGION as string;
