import {
    Box
} from '@mui/material';
import { useState } from 'react';
import CartButton from './CartButton';
import CartPopper from './CartPopper';

const Cart = () => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    return (
        <Box>
            <CartButton handleClick={handleClick} />
            <CartPopper open={open} anchorEl={anchorEl} handleClose={handleClose} />
        </Box>
    );
};

export default Cart;
