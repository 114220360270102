import {EditBase, EditView, useNotify} from "react-admin";
import OfferForm from "./offerForm";
import {Box} from "@mui/material";
import OfferEditTitle from "./OfferEditTitle";
import {FormAction} from "../../../type/formActions";
import type {v1_Offer} from "../../../../generated/types";
import {checkAndCreateWebsite} from "./checkAndCreateWebsite";
import RecordTimeLineWrapper from "../../common/recordTimelineWrapper";
import {OfferEditActions} from "./offerEditActions";
import {OFFER_STATUS} from "../../../config/statuses";
import {useAddRecordEvent} from "../../../hooks/useAddRecordEvent";

export const OfferEdit = () => {
    const notify = useNotify();
    const addRecordEvent = useAddRecordEvent();

    const transformData = async (data: v1_Offer, previousData: any) => {
        const newStatus = {Status: OFFER_STATUS.WAITING_APPROVAL}
        
        if (previousData.previousData.name === data.name) {
            addRecordEvent('offer', {
                data: {
                    id: data.id,
                    ...newStatus,
                }
            });
            return {...data, ...newStatus};
        }
        const websiteId = await checkAndCreateWebsite(data?.name);
        if (!websiteId) {
            notify('Failed to create Website', {type: "warning"});
            return;
        }

        addRecordEvent('offer', {
            data: {
                id: data.id,
                ...newStatus,
            }
        });
        return {
            ...data,
            website_id: websiteId,
            ...newStatus,
        }
    };

    return (
        <EditBase
            transform={transformData}
            mutationMode="optimistic"
            mutationOptions={{
                onError: () => {
                },
            }}
            redirect={false}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{flexDirection: {xs: "column", md: "row"}}}
            >
                <OfferEditTitle/>
                <OfferEditActions/>
            </Box>
            <EditView aside={<RecordTimeLineWrapper/>}>
                <OfferForm action={FormAction.Edit}/>
            </EditView>
        </EditBase>
    );
};
