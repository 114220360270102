import {Button, useRecordContext} from "react-admin";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField as TextFieldMui
} from "@mui/material";
import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useAddComment} from "../../../hooks/useAddComment";
import {v1_Posts} from "../../../../generated/types";

export const PostFormReportAnIssue = () => {
    const [descriptionReport, setDescriptionReport] = useState("");
    const [isOpenReport, setOpenReport] = useState(false);

    const post = useRecordContext<v1_Posts>();
    const queryClient = useQueryClient();

    const addComment = useAddComment({
        record: post,
        resource: 'v1_Posts',
        mutationOptions: {
            onSuccess: () => {
                void queryClient.invalidateQueries(['v1_Events', 'getList']);
            },
            returnPromise: true,
        },
    });

    const handleReport = () => {
        addComment({
            body: `Reported about issue - ${descriptionReport}`,
            notifyWriter: false,
            notifyPublisher: true
        }).then(
            () => {
                setDescriptionReport('');
                setOpenReport(false);
            }
        );
    }

    return (
        <Grid display="flex" justifyContent="end">
            <Button
                label="Report An Issue"
                sx={{color: "#000000"}}
                onClick={() => setOpenReport(!isOpenReport)}
            />
            <Dialog open={isOpenReport} onClose={() => setOpenReport(false)}>
                <DialogTitle sx={{color: "#000000", paddingBottom: "2px"}}>
                    {"Report An Issue"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{paddingBottom: "15px", fontSize: "10px"}}>
                        {"State your reasoning"}
                    </DialogContentText>
                    <TextFieldMui
                        label="Description"
                        onChange={(e) => {
                            setDescriptionReport(e.target.value);
                        }}
                        multiline
                        rows={3}
                        fullWidth
                        sx={{minWidth: "300px"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="warning"
                        label="REPORT"
                        fullWidth
                        onClick={handleReport}
                    />
                </DialogActions>
            </Dialog>
        </Grid>
    )
}