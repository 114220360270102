import {ChipProps} from '@mui/material';
import {useRecordContext, useGetOne} from 'react-admin';
import {StatusChip} from '../statusChip';

const getChipProps = (status: number): Partial<Omit<ChipProps, 'label'>> => {
    switch (status) {
        case 100000003:
            return {variant: 'filled', color: 'success'};
        default:
            return {variant: 'filled', color: 'warning'};
    }
};

export const PostContentStatusField = () => {
    const post = useRecordContext();
    const {data} = useGetOne(
        'v1_PostsContentStatus',
        {id: post?.new_ContentOrderStatus},
        {enabled: !!post?.new_ContentOrderStatus}
    );
    const label = data?.value;
    if (!label) return null;
    return (
        <StatusChip
            {...getChipProps(post?.new_ContentOrderStatus)}
            label={label}
            sx={{height: '20px'}}
        />
    );
};
