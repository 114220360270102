import {Datagrid, DateField, ReferenceField, TextField, WrapperField} from 'react-admin';
import React from 'react';
import {OFFER_STATUS_CHIP_MAP} from '../../../general/constants';
import {StatusField} from '../../../general/statusField';
import {AmountField} from '../../../common/amountField';
import {LightTooltip, LightToolTipWrapper} from '../../../common/lightToolTipWrapper';
import {TruncatedTooltipField} from "../../../common/truncatedTooltipField";

export const ListDatagrid = () => (
    <Datagrid rowClick="edit" bulkActionButtons={false}>
        <ReferenceField
            label="Status"
            source="Status"
            reference="v1_OfferStatus"
            link={false}
        >
            <StatusField chipMap={OFFER_STATUS_CHIP_MAP} source="value"/>
        </ReferenceField>
        <TextField source="id" label="Offer ID"/>
        <TruncatedTooltipField source="name" label="Site/section"/>
        <DateField source="created_on" label="Created on" locales="en-GB"/>
        <ReferenceField
            label="Duration"
            source="postduration_id"
            reference="v1_PostDuration"
            link="show"
        >
            <TextField source="value"/>
        </ReferenceField>
        <WrapperField label="Restricted Niches">
            <LightTooltip
                title={
                    <LightToolTipWrapper
                        perPage={25}
                        reference="v1_PostNiche"
                        through="v1_RestrictedNichesToOffer"
                        label="Restricted Niches"
                        source="name"
                        using="offer_id,niche_id"
                    />
                }
                placement="top-end"
            >
                <LightToolTipWrapper
                    perPage={1}
                    reference="v1_PostNiche"
                    through="v1_RestrictedNichesToOffer"
                    label="Restricted Niches"
                    source="name"
                    using="offer_id,niche_id"
                />
            </LightTooltip>
        </WrapperField>
        <AmountField
            source="link_price"
            sortBy="link_price"
            label="Offer Price"
            applyColor={false}
            strong={false}
            displaySign={false}
        />
        <ReferenceField
            label="Language"
            source="LanguageId"
            reference="v1_new_languagesBase"
            link="show"
            sortBy="Language.new_name"
        >
            <TextField source="new_name"/>
        </ReferenceField>
        <WrapperField label="Categories">
            <LightTooltip
                title={
                    <LightToolTipWrapper
                        perPage={25}
                        reference="v1_MarketPlaceCategories"
                        through="v1_MarketPlaceCategories_Offers"
                        label="Categories"
                        source="CategoryName"
                        using="offer_id,marketplace_category_id"
                    />
                }
                placement="top-end"
            >
                <LightToolTipWrapper
                    perPage={1}
                    reference="v1_MarketPlaceCategories"
                    through="v1_MarketPlaceCategories_Offers"
                    label="Categories"
                    source="CategoryName"
                    using="offer_id,marketplace_category_id"
                />
            </LightTooltip>
        </WrapperField>
    </Datagrid>
);
