import {Chip} from '@mui/material';
import {useGetOne} from 'react-admin';
import {OFFER_STATUS_CHIP_MAP} from '../../general/constants';
import {COLORS} from '../../../config/constants';
import type {v1_OfferStatus} from '../../../../generated/types';

interface OfferStatusChipProps {
    statusId: number;
}

export const OfferStatusChip = ({statusId}: OfferStatusChipProps) => {
    const {data} = useGetOne<v1_OfferStatus>('v1_OfferStatus', {id: statusId});
    const label = data?.value;

    if (!label) return null;

    return (
        <Chip
            label={label}
            variant={OFFER_STATUS_CHIP_MAP[statusId].variant}
            sx={{
                minWidth: '130px',
                borderRadius: '16px',
                borderWidth: '2px',
                cursor: 'pointer',
                backgroundColor: OFFER_STATUS_CHIP_MAP[statusId].color,
                color: COLORS.WHITE,
            }}
        />
    );
};
