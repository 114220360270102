import {List, Pagination} from "react-admin";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {OfferDatagrid} from "./offerDatagrid";
import {Card, CardContent, CircularProgress, Stack, Tab, Tabs, Typography,} from "@mui/material";
import * as React from "react";
import {ROLE_ADVERTISER, ROLE_PUBLISHER, ROLE_SUPPLIER, ROLE_WRITER,} from "../../../models/role-model";
import {MarketplaceList} from "../../advertiser/marketplace/marketplaceList";

export const OfferList = () => {
    const { data: identity } = useGetIdentity();
    const role = identity?.role;
    let writerId;
    let supplierId;
    let filterForRole;
    let advertiserId;
    switch (role) {
        case ROLE_WRITER:
            writerId = identity?.id;
            filterForRole = { writer_id: writerId };
            break;
        case ROLE_PUBLISHER:
        case ROLE_SUPPLIER:
            supplierId = identity?.id;
            filterForRole = { supplier_id: supplierId };
            break;
        case ROLE_ADVERTISER:
            advertiserId = identity?.id;
            filterForRole = { AdvertiserId: advertiserId };
            break;
        default:
            return <CircularProgress />;
    }

    if (identity?.role === ROLE_SUPPLIER) {
        return (
            <Card>
                <CardContent>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h3">Offers</Typography>
                            <Tabs textColor="inherit">
                                <Tab value="" label="All Offers" />
                                <Tab value="top_up" label="Top Ups" />
                                <Tab value="payout" label="Payouts" />
                            </Tabs>
                        </Stack>
                        <List
                            title="My Offers"
                            resource="v1_Offer"
                            filter={filterForRole}
                            sort={{ field: "Status", order: "DESC" }}
                            pagination={
                                <Pagination rowsPerPageOptions={[10, 15]} />
                            }
                            perPage={10}
                        >
                            <Typography variant="h3">All Offers</Typography>
                            <OfferDatagrid />
                        </List>
                    </Stack>
                </CardContent>
            </Card>
        );
    } else if (identity?.role === ROLE_ADVERTISER) {
        return <MarketplaceList />;
    } else {
        return null;
    }
};
